import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { RfpSetupService } from '../../_services';
import { StripeService, Elements, Element as StripeElement, ElementsOptions } from "ngx-stripe";

@Component({
  selector: 'app-stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.scss']
})
export class StripePaymentComponent implements OnInit {
  elements: Elements;
  card: StripeElement;
  doPaymentError = "";
  rfxAmt = 0;
  @Input('RFpID') rfpID: string;
  @Input('paymentAmount') paymentAmount: any;
  @Output() notifyPaymentSuccess = new EventEmitter<boolean>();
  // optional parameters
  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  stripePayment: FormGroup;
  constructor(
    private fb: FormBuilder,
    private stripeService: StripeService,
    private rfpSetupService: RfpSetupService
  ) { }

  ngOnInit() {
    this.doPaymentError = "";
    if (!this.paymentAmount) {
      this.paymentAmount = 0;
    }
    //setup payment form.
    this.stripePayment = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      state: ['', [Validators.required]],
      postal_code: ['', [Validators.required]],
      country: ['US', [Validators.required]],
    });

    //register stripe key and setup card.
    this.rfpSetupService.getStripeSetting().subscribe((res: any) => {
      if (res.data.key) {
        this.stripeService.setKey(res.data.key);
        this.stripeService.elements(this.elementsOptions)
          .subscribe(elements => {
            this.elements = elements;
            // Only mount the element the first time
            if (!this.card) {
              this.card = this.elements.create('card', {});
              this.card.mount('#card-element');
              this.card.on('change', event => {
                const displayError = document.getElementById('card-errors');
                if (event.error) {
                  displayError.textContent = event.error.message;
                } else {
                  displayError.textContent = '';
                }
              });
            }
          });
      }
      else {
        this.doPaymentError = "Stripe not configured yet.";
      }
    }, err => {
      this.doPaymentError = err;
    });

    //get price from setting and show on the stripe checkout page.
    // this.rfpSetupService.getSettings().subscribe((result: any) => {
    //   if (result.code == 200) {
    //     let isRfpAmtSetFlag = false;
    //     result.data[0].forEach(ele => {
    //       if (ele.key == "issuer_fee") {
    //         this.rfxAmt = ele.value;
    //         isRfpAmtSetFlag = true;
    //       }
    //     });
    //   }
    // });
  }

  //make payment
  pay() {
    if (this.rfpID) {
      const name = this.stripePayment.get('name').value;
      this.stripeService
        .createToken(this.card, { name })
        .subscribe(result => {
          if (result.token) {
            this.rfpSetupService.makePayment(this.rfpID, result.token, this.stripePayment.value).subscribe((res: any) => {
              if (res.code == 200) {
                this.notifyPaymentSuccess.emit(true);
              } else {
                this.doPaymentError = res.message;
              }
            }, err => {
              this.doPaymentError = err;
            });
          } else if (result.error) { }
        });
    }
    else {
      this.doPaymentError = "Something went wrong. Please contact site administrator.";
    }
  }
}
