import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  AfterViewInit,
} from "@angular/core";
import {
  CommonService,
  UserService,
  AuthenticationService,
} from "../_services";
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from "@angular/forms";
import { VenderService } from "../_services/vender.service";

import { ApiResponse } from "../_models/response";
import { DatePicker } from "../const";
import { Router } from "@angular/router";
import {
  MdbTableDirective,
  MDBModalService,
  MdbTablePaginationComponent,
} from "ng-uikit-pro-standard";
import { ConfirmComponent } from "../_shared/confirm/confirm.component";
import { environment } from "../../environments/environment";
import Swal from "sweetalert2";
import * as jexcel from "jexcel-pro";
import { debounceTime } from "rxjs/operators";
import { merge } from "rxjs";

@Component({
  selector: "app-vendor-group",
  templateUrl: "./vendor-group.component.html",
  styleUrls: ["./vendor-group.component.scss"],
})
export class VendorGroupComponent implements OnInit, AfterViewInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true })
  mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("supplierSpreadsheet", { static: true }) supplierSpreadsheet: any;
  @ViewChild("BrowseXlsFileSupplier", { static: true })
  browseSupplierXlsFileModel: any;
  @ViewChild("supplierXlsBrowse", { static: true }) supplierXlsBrowse: any;
  dataSource: any = [];
  serverError = "";
  searchText: string = "";
  previous: string;
  DatePicker = DatePicker;
  deleteErrorMsg = "";
  isbackuploadError = true;
  supplierUploadFormGroup: FormGroup;
  saveUploadSupplierFormGroup: FormGroup;
  public apiPath = environment.apiUrl;
  public samplePath = this.apiPath + "/downloadsample/";

  // search
  states = [];
  state = new FormControl();
  city = new FormControl();
  zip = new FormControl();
  tags = [];
  categories = [];

  tagGetterFn: Function;
  categoryGetterFn: Function;

  constructor(
    private userservice: UserService,
    private venderservice: VenderService,
    private modalService: MDBModalService,
    private auth: AuthenticationService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    public commonservice: CommonService,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.tagGetterFn = this.venderservice.getVendorTags.bind(
      this.commonservice
    );
    this.categoryGetterFn = this.venderservice.getVendorCategories.bind(
      this.commonservice
    );

    merge(
      this.state.valueChanges,
      this.city.valueChanges.pipe(debounceTime(300)),
      this.zip.valueChanges.pipe(debounceTime(300))
    ).subscribe(() => {
      this.showAllVendorGroup();
    });

    this.commonservice.getStates().subscribe(
      (res: any) => {
        this.states = res.states.map((state) => ({
          value: state.id,
          label: state.state,
        }));
        this.states = [{ value: null, label: "All states" }, ...this.states];
      },
      (err) => {
        console.log(err);
      }
    );

    this.showAllVendorGroup();

    this.supplierUploadFormGroup = new FormGroup({
      supplier_file: new FormControl("", [Validators.required]),
    });
    this.saveUploadSupplierFormGroup = new FormGroup({
      supplier_filename: new FormControl("", [Validators.required]),
    });
  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }

  showAllVendorGroup() {
    const tags = this.tags.filter((tag) => tag.id).map((tag) => tag.id);
    const categories = this.categories
      .filter((category) => category.id)
      .map((category) => category.id);
    this.venderservice
      .getVendors(
        this.state.value,
        this.city.value,
        this.zip.value,
        tags,
        categories
      )
      .subscribe(
        (u: ApiResponse) => {
          if (u.code === 200) {
            this.dataSource = [];
            u.data.rfps.forEach((d, i) => {
              this.dataSource.push(d);
            });
            this.mdbTable.setDataSource(this.dataSource);
            this.dataSource = this.mdbTable.getDataSource();
            this.previous = this.mdbTable.getDataSource();
          } else {
            this.serverError = u.message;
          }
        },
        (err) => {
          this.serverError = err;
        }
      );
  }

  onAddTag(tag: any) {
    this.tags = [tag, ...this.tags];
    this.showAllVendorGroup();
  }

  onRemoveTag(tag: any) {
    this.tags = this.tags.filter(
      (t) => !(t.id === tag.id && t.name === tag.name)
    );
    this.showAllVendorGroup();
  }

  onAddCategory(category: any) {
    this.categories = [category, ...this.categories];
    this.showAllVendorGroup();
  }

  onRemoveCategory(category: any) {
    this.categories = this.categories.filter(
      (c) => !(c.id === category.id && c.name === category.name)
    );
    this.showAllVendorGroup();
  }

  deletegroup(du, i) {
    this.deleteErrorMsg = "";
    this.modalService
      .show(ConfirmComponent)
      .content.action.subscribe((result: boolean) => {
        if (result) {
          //Yes
          this.venderservice.deletegroup(du.id).subscribe(
            (b: ApiResponse) => {
              if (b.code == 200) {
                this.dataSource.splice(i, 1);
                this.mdbTable.setDataSource(this.mdbTable.getDataSource());
                Swal.fire("", "Supplier Deleted Successfully.", "success");
              } else {
                this.deleteErrorMsg = b.message;
              }
            },
            (err) => {
              this.deleteErrorMsg = err;
              var t = setTimeout(function () {
                this.deleteErrorMsg = undefined;

                document.getElementById("deleteErrorMsg").style.display =
                  "none";
              }, 4000);
            }
          );
        }
      });
  }

  ShowBrowseXlsModal(m) {
    this.supplierXlsBrowse.nativeElement.innerText = "";
    this.supplierUploadFormGroup.get("supplier_file").setValue(null);
    m.show();
  }
  hideBrowseXlsFile(m) {
    m.hide();
  }

  previewXlsSupplier(m) {
    // this.validateuploadBackgroundError = "";
    if (this.supplierUploadFormGroup.valid) {
      this.venderservice
        .postSupplierUpload(
          this.commonservice.GetFormValue(
            this.supplierUploadFormGroup,
            "supplier_file"
          )
        )
        .subscribe(
          (s: ApiResponse) => {
            if (s.code == 200) {
              let file_name = s.data.file_name;
              this.isbackuploadError = false;
              console.log(file_name);
              let newdata = [];
              newdata.push(s.data);
              console.log(s.data);
              this.saveUploadSupplierFormGroup
                .get("supplier_filename")
                .setValue(file_name);

              let secNo = 2;
              let totalSheets = this.supplierSpreadsheet.nativeElement.length;

              if (totalSheets != undefined) {
                secNo = totalSheets + 1;
              }
              let sheetName = "Section " + secNo;
              jexcel.destroy(this.supplierSpreadsheet.nativeElement);
              this.makeJExcelTabs(sheetName, this.supplierSpreadsheet, newdata);
              m.show();
            } else {
              // this.sharedMethodService.setError(s.message);
              // this.validateuploadBackgroundError = s.message;
            }
          },
          (err) => {
            // this.sharedMethodService.setError(err);
            // this.validateuploadBackgroundError = err;
          }
        );
    }
  }
  onSupplierFileSelect(event, id: string) {
    document.getElementById(id).innerText = "";
    if (event.target.files.length > 0) {
      const suppfile = event.target.files[0];
      this.supplierUploadFormGroup.get("supplier_file").setValue(suppfile);
      document.getElementById(id).innerText = event.target.files[0].name;
      event.target.value = "";
    }
  }
  hidePreviewSuppXlsFile(m) {
    jexcel.destroy(this.supplierSpreadsheet.nativeElement);
    this.browseSupplierXlsFileModel.hide();
    m.hide();
  }
  makeJExcelTabs(sheets, spreadSheet: ElementRef, newdata) {
    console.log(newdata);
    let sheetName = sheets;
    let sheet = [];
    if (newdata.length > 0) {
      for (let i = 0; i < newdata.length; i++) {
        for (let key of Object.keys(newdata[i])) {
          if (newdata[i][key].hasOwnProperty("worksheetName")) {
            console.log("dcgfdfd");
            sheet.push({
              worksheetName: newdata[i][key].worksheetName,
              data: newdata[i][key].data,
              columns: newdata[i][key].columns,
              minDimensions: [10, 10],
              style: newdata[i][key].errors,
              comments: newdata[i][key].comments,
            });
          }
        }
      }
    } else {
      sheet = [
        {
          worksheetName: sheetName,
          data: [[]],
          columns: [[]],
          minDimensions: [6, 10],
        },
      ];
    }
    jexcel(spreadSheet.nativeElement, sheet);
  }

  supplierSaveXLS(m) {
    // this.saveUploadBackgroundError = "";

    if (this.saveUploadSupplierFormGroup.valid) {
      this.venderservice
        .saveSupplierXLS(
          this.commonservice.GetFormValue(
            this.saveUploadSupplierFormGroup,
            "supplier_filename"
          )
        )
        .subscribe(
          (k: ApiResponse) => {
            if (k.code == 200) {
              this.showAllVendorGroup();
              this.hidePreviewSuppXlsFile(m);
            } else {
              // this.sharedMethodService.setError(k.message);
              // this.saveUploadBackgroundError = k.message;
            }
          },
          (err) => {
            // this.sharedMethodService.setError(err);
            // this.saveUploadBackgroundError = err;
          }
        );
    }
  }

  getState(stateId) {
    if (!this.states || !this.states.length || !stateId) return "";
    const state = this.states.find((s) => s.value === stateId);
    return state ? state.label : "";
  }
}
