import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { CropperComponent } from 'angular-cropperjs';
@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {
  action: Subject<any> = new Subject();
  imageUrl;
  heading : string;
  content: any;
  imageSrc: string|ArrayBuffer;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  config = {

    // aspectRatio : 4/4,
    dragMode : 'move',
    background : true,
    movable: true,
    rotatable : true,
    scalable: true,
    zoomable: true,
    viewMode: 1,
    checkImageOrigin : true,
    cropmove:this.cropMoved.bind(this),
    checkCrossOrigin: true
  };
  // angularCropper
  @ViewChild('angularCropper', {static: false}) public angularCropper: CropperComponent;


  constructor(public modalRef: MDBModalRef, private changeDetectorRef:ChangeDetectorRef) { }
    cropMoved(data){
      this.croppedImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    }

  ngOnInit() {
    console.log(this.content);
    this.readFile(this.content.fileEvent);
  }

  readFile(fileEvent: any) {
    var _this = this;
    console.log(fileEvent)
    if (fileEvent) {
      var FR= new FileReader();

      FR.addEventListener("load", function(e:any) {
        if(e.target ){
          _this.imageUrl = e.target.result;
        }
      });
      FR.readAsDataURL( fileEvent.target.files[0] );
      this.changeDetectorRef.detectChanges();
    }
  }
  ngAfterViewInit(){
    console.log(this.imageChangedEvent)
  }
  cancelled(){
    this.modalRef.hide();
    this.action.next(null);
  }
  crop(){
    console.log(this.angularCropper.cropper.getCroppedCanvas());
    this.croppedImage = this.angularCropper.cropper.getCroppedCanvas().toDataURL();
    console.log(this.croppedImage);
    this.modalRef.hide();
    this.action.next(this.croppedImage);
  }
  select(){
    this.croppedImage = '';
    this.croppedImage = this.imageUrl;
    this.modalRef.hide();
    this.action.next(this.croppedImage);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }
  imageLoaded(image: HTMLImageElement) {
    // show cropper
  }
  cropperReady() {
    // cropper ready
  }
  loadImageFailed() {
    // show message
  }
}
