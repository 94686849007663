import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '../../environments/environment';
import { AuthenticationService, CommonService } from '../_services';
import { finalize } from "rxjs/operators";
import { CustomHttpParams } from './httpparams';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private commonService: CommonService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to api url

    const currentUser = this.authenticationService.currentUserValue;
    const isLoggedIn = currentUser && currentUser.apiToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);

  if(request.url.indexOf("impersonate")===-1){
    if (request.params instanceof CustomHttpParams && request.params.param1) {
      this.commonService.ShowSaving = request.params.param2?true: false;
      this.commonService.ShowLoader = false;
    }else{
      this.commonService.ShowLoader = true;
      this.commonService.ShowSaving = false;
    }
  }
  else{
    this.commonService.ShowLoader = false;
    this.commonService.ShowSaving = false;
  }

    if (isLoggedIn && isApiUrl) {

      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentUser.apiToken}`
        }
      });
    }

    // this.commonService.getLoaderNeedStatus().subscribe((res:boolean)=>{
    //   this.commonService.ShowLoader = res;
    // });

    return next.handle(request);
  }
}
