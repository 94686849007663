import { Component, OnInit } from '@angular/core';
import { UserService, CommonService, AuthenticationService } from '../../_services';
import { ApiResponse } from '../../_models/response';

@Component({
    selector: 'app-verify',
    templateUrl: './verify.component.html',
    styleUrls: ['./verify.component.scss']
})
export class VerifyComponent implements OnInit {
    id = '';
    token = '';
    msg = false;
    Rperror = "";
    constructor(private auth: AuthenticationService, private commonService: CommonService) { }

    ngOnInit() {
        debugger;
        this.id = this.commonService.GetQueryStringValue("id")
        this.token = this.commonService.GetQueryStringValue('token');
        this.auth.verifyAccount((this.id), (this.token)).subscribe((x: ApiResponse) => {
            if (x.code == 200) {
                this.logout();
                this.msg = true;
            } else {
                this.Rperror = x.message;
            }
        }, err => {
            this.Rperror = err;
        });
    }
     logout() {
        this.auth.logout();
    }
}
