import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, ChangeDetectorRef } from '@angular/core';
import { ApiResponse } from '../_models/response';
import {MdbTableDirective, MDBModalService, ModalDirective, MdbTablePaginationComponent} from 'ng-uikit-pro-standard';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { VenderService } from '../_services/vender.service';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';
import { RfpSetupService, UserService, AuthenticationService, CommonService } from '../_services';
import { environment } from '../../environments/environment';
import { DatePicker } from '../const';
import { colors } from "../_helpers/colors";
import 'rxjs/add/observable/fromEvent';
import Swal from 'sweetalert2';
import { SharedMethodService } from '../rfp-add-edit/shared-method.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'ng-uikit-pro-standard';


declare var stripe: any;
declare var elements: any;
@Component({
  selector: 'app-rfp-detail',
  templateUrl: './rfp-detail.component.html',
  styleUrls: ['./rfp-detail.component.scss']
})
export class RfpDetailComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChildren("SrchForEmail1") SrchForEmail: QueryList<ElementRef>;
  @ViewChild("RFxConfirmContacts", { static: true }) RFxConfirmContacts: any;

  @ViewChild("SupplierConfirmModal", { static: true }) ConfirmSupplierModel: any;
  @ViewChild("paymentModal", { static: true }) PaymentModal: any;
  dataSource: any = [];
  isValidEmail: boolean;
  public DatePicker = DatePicker;
  serverError = "";
  selectedCompanies: number[] = [];
  deleteInvitedMemberErrorMsg = "";
  publishError = "";
  downloadError = "";
  previous: string;
  searchText: string = '';
  searchItem: string = '';
  paymentAmount: any = 0;
  inviteSendError = "";
  rfpId = 0;
  download_link: any;
  rfp_id: string;
  itemList: any = [];
  selectedItems = [];

  settings = {};
  projectSetup: any = {};
  vendorInvite: any = {};
  calculateData: Array<any> = [];
  pocUser: any = {};
  loading = false;
  currentUser: any = {};
  AllowVendorInvite = false;
  chartDataPie = [0, 0];
  chartDataBarArray = [0];
  paymentTermsMessage: any = '';
  chartDataBar = [0, 0];
  payInfo: any;
  public chartTypePie: string = 'pie';
  public chartDatasetsPie: Array<any> = [
    { data: this.chartDataPie, label: '' }
  ];
  public chartLabelsPie: Array<any> = ['Scored', 'Not Scored'];
  public chartColorsPie: Array<any> = [
    {
      backgroundColor: ['#816BF1', '#CACFD3'],
      hoverBackgroundColor: ['#816Ba1', '#CACF93'],
      borderWidth: 2,
    }
  ];
  public chartOptionsPie: any = {
    responsive: true
  };
  public chartClickedPie(e: any): void { }
  public chartHoveredPie(e: any): void { }

  public chartType: string = 'bar';
  public chartDatasets: Array<any> = [
    { data: this.chartDataBarArray, label: 'My First dataset', barThickness: 1, maxBarThickness: 2 }
  ];

  public chartLabels: Array<any> = ['', ''];
  public chartColors: Array<any> = [
    {
      backgroundColor: ['#816BF1', '#CACFD3', '#816BF1', '#CACFD3', '#816BF1', '#CACFD3', '#816BF1', '#CACFD3', '#816BF1', '#CACFD3', '#816BF1', '#CACFD3'],
      hoverBackgroundColor: ['#816Ba1', '#CACF93', '#816Ba1', '#CACF93', '#816Ba1', '#CACF93', '#816Ba1', '#CACF93', '#816Ba1', '#CACF93', '#816Ba1', '#CACF93'],
      borderWidth: 2,
    }
  ];

  public chartOptions: any = {};
  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  public chartData: any;

  getrefunduser = [];
  isCheckedResponder = false;
  isCheckedSecureQuestion: number;
  payer: string;
  is_secured_questions: boolean;
  ConfirmPayFromGroup: FormGroup;
  customStripeFormGroup: FormGroup;
  confirmPayError = '';
  card: any;
  doPaymentError = '';
  Excelurl: string = '';
  public hasFile = "false";
  excel_download_link: any;

  constructor(
    private router: Router,
    public vendorService: VenderService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private rfpSetupService: RfpSetupService,
    private modalService: MDBModalService,
    private authenticationService: AuthenticationService,
    private cdRef: ChangeDetectorRef,
    private sharedMethodService: SharedMethodService,
    private toast: ToastService,
    public commonservice: CommonService,
  ) {

  }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);
    this.cdRef.detectChanges();
  }

  ngOnInit() {

    this.currentUser = this.authenticationService.currentUserValue;
    this.activatedRoute.params.subscribe((param: any) => {
      if (param.Id) {
        this.rfpId = param.Id;
        //All init function shoud be inside page laod
        this.pageLoad();
        console.log(this.AllowVendorInvite);
      }
    });

    //https://cuppalabs.github.io/angular2-multiselect-dropdown/#/lazyloadingRemoteData
    this.settings = {
      text: "Select Emails",
      primaryKey: "id",
      labelKey: "email",
      // addNewItemOnFilter: true,
      singleSelection: false,
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      noDataLabel: "",
      enableSearchFilter: true,
    };
    this.getChartDetailRfp();
    this.getscoreonRfx();
    this.getpaymentterm();
    this.ConfirmPayFromGroup = new FormGroup({
      payer: new FormControl('', [Validators.required]),
      is_secured_questions: new FormControl('', []),
    });

    this.customStripeFormGroup = new FormGroup({
      name: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      postal_code: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required]),
    });
  }
  getpaymentterm() {
    this.commonservice.getPaymentInfo(this.rfpId).subscribe((res: any) => {
      this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "payer", '');
      this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "is_secured_questions", '');
      if (res) {
        this.payInfo = res.data[0];
        if (this.payInfo) {
          if (this.payInfo.payer == 'vn') {
            this.isCheckedResponder = true;
            this.paymentAmount = this.payInfo.vendorRfxamount;
          } else {
            this.isCheckedResponder = false;
            this.paymentAmount = this.payInfo.issuerRfxamount;
          }
          this.isCheckedSecureQuestion = this.payInfo.is_secured_questions;
          this.is_secured_questions = this.payInfo.is_secured_questions;
          this.payer = this.payInfo.payer;
          this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "payer", this.payer);
          this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "is_secured_questions", this.is_secured_questions);
        }
      }
    });
    // this.sharedMethodService.getSupplierDataFromSetupSubject().subscribe((res: any) => {
    //   let v = res;

    //   setTimeout(() => {

    //     this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "payer", '');
    //     this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "is_secured_questions", '');
    //     if (res) {

    //       this.isCheckedResponder = res.isCheckedResponder;
    //       this.isCheckedSecureQuestion = res.isCheckedSecureQuestion;
    //       this.payer = res.payer;
    //       this.is_secured_questions = res.is_secured_questions;
    //       if (this.payer == null) {
    //         this.payer = 'is';
    //       }
    //       this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "payer", this.payer);
    //       this.commonservice.SetFormValue(this.ConfirmPayFromGroup, "is_secured_questions", this.is_secured_questions);
    //       //get paymentterms
    //       // this.rfpSetupService.confirmPayment(this.rfpId,this.ConfirmPayFromGroup.value).subscribe((res:any)=>{
    //       //   if (res.code == 200) {
    //       //     this.paymentAmount = res.data.rfxamount;
    //       //   }
    //       // });

    //     }
    //   }, 200)
    // });
  }
  getChartDetailRfp() {
    this.rfpSetupService.getChartDetailRfp(this.rfpId).subscribe((r: ApiResponse) => {
      if (r.code == 200) {
        this.chartData = r.data[0];
        console.log(this.chartData);
        this.chartDataPie = [this.chartData.scoredPercentage, this.chartData.notScoredPercentage];
        this.chartDatasetsPie = [
          { data: this.chartDataPie, label: 'My First dataset' }
        ];
        this.chartDataBar = [];
        this.chartLabels = [];
        this.chartData.vendorWisePercentage.forEach(element => {
          this.chartDataBar.push(parseInt(element.achive_points));

        });

        this.chartData.vendorWisePercentage.forEach(element => {
          this.chartLabels.push(element.company_name);
        });
        this.chartDatasets = this.chartData.barChart;
        const totalColors = this.chartData.vendorWisePercentage.length;
        const backgroundColors: any[] = [];
        const borderColors: any[] = [];
        // for(let x = 0; x<totalColors;x+2){
        //   backgroundColors.push(`${colors[x]}`);
        //   borderColors.push(`${colors[x]}`);
        //   // const hue = Math.floor(Math.random() * 30) * 12;
        //   // backgroundColors.push(`hsla(${hue}, 90%, 60% , 0.2 )`);
        //   // borderColors.push(`hsla(${hue}, 90%, 60% , 0.5 )`);
        // }
        this.chartColors = [
          {
            borderWidth: 1
          },

        ];
        this.chartOptions = {
          legend: {
            display: true
          },
          responsive: true,
          scaleShowValues: true,
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              }
            }],
            xAxes: [{
              display: true,
              stacked: true,

              ticks: {
                //autoSkip: false,
                // max: this.chartData.vendorWisePercentage[0].totalpointsSum,
                // min: 0,
                // max:100,
                maxTicksLimit: 100
              }
            }]
          },
          title: {
            display: true,
            text: name
          },
          tooltips: {
            mode: 'index',
            intersect: true,
          },
          hover: {
            mode: 'index',
            intersect: true
          },

        };
      }
    }, err => {
      this.serverError = err.message;

    });
  }

  pageLoad() {
    this.bindAllInvitedVendors();
    this.bindSetupData();
    let rfpid = btoa('' + this.rfpId);
    //(btoa(rfpid));
    this.download_link = `${environment.apiUrl}/download-data-rfp/${rfpid}`;

  }
  bindSetupData() {
    this.rfpSetupService.getSetup(this.rfpId).subscribe((r: ApiResponse) => {
      if (r.code == 200 && r.data.rfp != null) {
        this.projectSetup = r.data.rfp;
        this.paymentTermsMessage = r.data.payment_message;
        this.AllowVendorInvite = true;
        if (this.projectSetup.deadlineForQuestions) {
          this.AllowVendorInvite = !DatePicker.DeadlineOver(this.projectSetup.deadlineForQuestions);
        }
        if (this.currentUser.role.name == 'contributor') {
          this.AllowVendorInvite = false;
        }

        this.userService.getUser(this.projectSetup.poc_user_id).subscribe((u: ApiResponse) => {
          if (u.code == 200) {
            this.pocUser = u.data[0];
          } else {
            this.serverError = u.message;
          }
        }, err => {
          this.serverError = err;
        })
      } else {
        this.serverError = r.message;
      }
    }, err => {
      this.serverError = err;
    });
  }

  bindAllInvitedVendors() {

    this.vendorService.getAllInvitedVendors(this.rfpId).subscribe((m: ApiResponse) => {
      if (m.code == 200) {
        this.vendorInvite = m.data[0];
        this.AllowVendorInvite = true;

        if (this.vendorInvite.length > 0) {
          if (this.vendorInvite[0].isPaid == 1) {
            this.AllowVendorInvite = false;
          }
        }
        this.dataSource = [];
        this.vendorInvite.forEach((d, i) => {
          this.dataSource.push(d);
        })
        this.mdbTable.setDataSource(this.dataSource);
        this.dataSource = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      } else {
        this.serverError = m.message;
      }
    }, err => {
      this.serverError = err;
    })
  }

  onAddItem() {
    this.selectedItems.push({ "id": 0, "email": this.searchItem });
    this.searchItem = "";
  }

  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.dataSource = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.dataSource = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  showIviteModal(m) {
    this.selectedItems = [];
    this.searchItem = '';
    this.loading = false;
    this.itemList = [];
    this.inviteSendError = '';
    m.show();
  }

  deleteInvitedMember(id, i) {
    this.deleteInvitedMemberErrorMsg = "";
    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
      if (result) {
        //Yes
        this.vendorService.deleteInvitedVendor(this.rfpId, id).subscribe((b: ApiResponse) => {
          if (b.code == 200) {
            this.bindAllInvitedVendors();

          } else {
            this.deleteInvitedMemberErrorMsg = b.message;
          }
        }, err => {
          this.deleteInvitedMemberErrorMsg = err;
        })
      }
    });
  }

  restoreDeletedInvitee(id, i) {
    this.deleteInvitedMemberErrorMsg = "";
    this.vendorService.restoreDeletedInvitedVendor(id).subscribe((b: ApiResponse) => {
      if (b.code == 200) {
        this.bindAllInvitedVendors();
      } else {
        this.deleteInvitedMemberErrorMsg = b.message;
      }
    }, err => {
      this.deleteInvitedMemberErrorMsg = err;
    })
  }

  publishRfp() {
    this.publishError = '';
    this.rfpSetupService.changeRfpStatus(this.rfpId, 'Published').subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        this.pageLoad();
      } else {
        this.publishError = x.message;
      }
    }, err => {
      this.publishError = err;
    });
  }

  closeRFx(id) {
    this.getrefunduser = [];
    this.rfpId = id;
    this.getrefunduser = this.checkInvitedMemberIsPaid(id);
    if (this.getrefunduser.length > 0) {
      this.RFxConfirmContacts.show();
    } else {
      const areYouSure = confirm("Are you sure you want to close RFx?");
      if (areYouSure) {

        this.publishError = '';

        this.rfpSetupService.closeRFxStatus(this.rfpId, 'Closed').subscribe((x: ApiResponse) => {
          if (x.code == 200) {
            this.pageLoad();
          } else {
            this.publishError = x.message;
          }
        }, err => {
          this.publishError = err;
        });
      }
    }

  }

  scoreOnRfp: any;
  mappedAnswers: any;
  vendors: any[];
  sections: any[];
  secScores: any[] = [];
  getscoreonRfx() {
    this.rfpSetupService.getScoreOnRfx(this.rfpId).subscribe((s: ApiResponse) => {
      if (s.code == 200) {
        this.scoreOnRfp = s.data[0];
        // this.answers = Object.values(this.scoreOnRfp.answers);
        this.vendors = this.scoreOnRfp.points.vendor;
        this.sections = this.scoreOnRfp.points.sections;
        console.log(this.scoreOnRfp);
        // console.log(this.vendors);
        this.mappedAnswers = Object.keys(this.scoreOnRfp.answers).map(key => ({ type: key, value: this.scoreOnRfp.answers[key] }));
        // this.scoresections = this.scoreOnRfp.points;
        // console.log(this.mappedAnswers);

        this.mappedAnswers.forEach((ele: any) => {
          this.secScores.push(Object.keys(ele.value).map(key => ({ type: key, value: ele.value[key] })));
        })
        console.log(this.secScores);
      } else {
        this.serverError = s.message;
      }
    }, err => {
      this.serverError = err;
    });
  }

  getSectionTotalPoint() {
    this.rfpSetupService.getSectionTotalPoints(this.rfpId).subscribe((p: ApiResponse) => {
      if (p.code == 200) {
      } else {
        this.serverError = p.message;
      }
    }, err => {
      this.serverError = err;
    })
  }
  onSubmit(form) {
    this.vendorService.awardRFP(this.rfpId, this.selectedCompanies).subscribe((res: any) => {
      this.router.navigate(['/submissions-rfp/' + this.rfpId]);
    });
  }
  selectComps(compId: number, $event) {
    if ($event.checked) {
      this.selectedCompanies.push(compId);
    } else {
      this.selectedCompanies.forEach((item, index) => {
        if (item == compId) this.selectedCompanies.splice(index, 1);
      })
    }
    // console.log(this.selectedCompanies);
  }
  checkInvitedMemberIsPaid(id) {
    let invited = [];
    this.getrefunduser = [];
    invited = this.vendorInvite;
    console.log(invited);
    if (invited.length > 0) {
      invited.forEach((v, i) => {

        if (v.issuer_vendor.length > 0) {
          v.issuer_vendor.forEach((val, j) => {

            if (val.invite != null) {
              if (val.invite.status == 'accepted' && val.invite.isPaid == 1) {
                this.getrefunduser.push({ "groupname": v.group_name, "contactname": val.vendor_fname + ' ' + val.vendor_lname });
              }
            }
          })
        }
      })
    }
    return this.getrefunduser;
  }

  CancelRFxContactConfirm(m) {
    m.hide();
  }
  RefundRFxContactConfirm(m) {
    this.publishError = '';
    this.rfpSetupService.closeRFxStatus(this.rfpId, 'Closed').subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        m.hide();
        this.pageLoad();
      } else {
        this.publishError = x.message;
      }
    }, err => {
      this.publishError = err;
    });
  }
  changePayConfirm(event: any, type) {
    if (type == 'issuer_pay') {
      this.isCheckedResponder = false;
      this.paymentAmount = this.payInfo.issuerRfxamount;
      this.ConfirmPayFromGroup.get('is_secured_questions').clearValidators();
    } else if (type == 'responder_pay') {
      this.isCheckedResponder = true;
      this.paymentAmount = this.payInfo.vendorRfxamount;
      this.ConfirmPayFromGroup.get('is_secured_questions').setValidators(Validators.required);
    }
  }
  hideSupplierConfirm(m) {
    m.hide();
  }
  changepaymentTerms(rfpid) {
    this.rfpId = rfpid;
    let paidContact = [];
    this.publishError = "";
    paidContact = this.checkInvitedMemberIsPaid(rfpid);
    if (paidContact.length == 0) {
      if (this.projectSetup) {
        this.ConfirmSupplierModel.show();

        // if(this.projectSetup.payer == 'vn'){

        // }
      }
    } else {
      this.publishError = "One of the invited supplier has paid for bid already. Payment terms can't be changed.";
    }
  }
  gotoPay() {
    this.confirmPayError = "";
    // console.log(this.payConfirmFromGroup);
    if (this.ConfirmPayFromGroup.get('payer').value == this.projectSetup.payer) {
      this.confirmPayError = "You have already seleted this payment oi  during RFx Published,Please select other";
      return false;
    } else {
      if (this.ConfirmPayFromGroup.get('payer').value == 'is') {
        //change payer type to issuer and do payment
        this.ConfirmPayFromGroup.patchValue({
          is_secured_questions: ''
        });
        if (this.ConfirmPayFromGroup.valid) {
          this.ConfirmSupplierModel.hide();
          this.PaymentModal.show();
        }
      } else if (this.ConfirmPayFromGroup.get('payer').value == 'vn') {
        //refund to issuer and change payer to vendor
        this.rfpSetupService.updatePaymenttermAndRefundToIssuer(this.rfpId, this.ConfirmPayFromGroup.value).subscribe((res: any) => {
          if (res.code == 200) {
            this.ConfirmSupplierModel.hide();
            this.getpaymentterm();
            location.reload();
          } else {
            this.confirmPayError = res.message;
          }
        }, err => {
          this.confirmPayError = err;
        });
      }
    }
  }

  notifyPaymentSuccess(isSuccess: boolean) {
    if (isSuccess) {
      this.PaymentModal.hide();
      this.getpaymentterm();
      location.reload();
    }
  }

  getData(id) {
    this.hasFile = 'in_process';
    this.commonservice.generateExcelForRFx(id).then((res: any) => {
      this.Excelurl = res.apprStatus;
      this.excel_download_link = `${environment.serverUrl}${this.Excelurl}`;

      setTimeout(() => {
        this.hasFile = "download";

      }, 1000);
    }, (err: any) => {
      console.log(err);
    })
  }


  downloadFile() {
    let downloadTab = window.open(this.excel_download_link, '_blank');
    downloadTab.focus();
    downloadTab.addEventListener('load', function () { downloadTab.close(); }, false);
  }
  deleteExcelAfterSubmit(id) {
    this.commonservice.generateExcelForRFxAfterPublish(id).then((res: any) => {
      this.Excelurl = res.apprStatus;
      this.excel_download_link = `${environment.serverUrl}${this.Excelurl}`;
      this.hasFile = "download";
      this.downloadFile();
    }, (err: any) => {
      console.log(err);
    })
  }

  downloadExcel($event) {
    console.log($event);
    $event.preventDefault();

    // let url = `${environment.imageUrl}${this.Excelurl}`;
    // window.location.href = encodeURI(this.Excelurl);
  }

  showCalculationModal(m: ModalDirective) {
    this.rfpSetupService.getCalculations(this.rfpId).subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        this.calculateData = [];
        this.calculateData = x.data[0];

      } else {

      }
    }, err => {

    });
    setTimeout(() => {
      m.show();
    }, 1000);

  }

  showPieChart() {
    return this.chartData && this.chartData.scoredPercentage !== 100;
  }

  resendInvite(vendorId, j, rfxId){
    this.rfpSetupService.resendVendorInvites(rfxId,vendorId).subscribe((res:any)=>{
      this.toast.success(res.message,'Email sent successfully!');
    });
  }
}
