import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {CommonService} from '../_services';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-rfp-questions',
  templateUrl: './rfp-questions.component.html',
  styleUrls: ['./rfp-questions.component.scss']
})
export class RfpQuestionsComponent implements OnInit, OnDestroy {

  rfpId: string;

  loading: boolean;

  questionsPage = 1;
  questionsLastPage: number;
  questionsTotalRows: number;
  questionsFrom: number;
  questionsTo: number;

  qas: any[];

  subs: Subscription[] = [];

  constructor(private activatedRoute: ActivatedRoute, private commonService: CommonService) { }

  ngOnInit() {
    const routeSub = this.activatedRoute.params.subscribe((param: any) => {
      if (param.id) {
        this.rfpId = param.id;
        this.getQuestions();
      }
    });

    this.subs.push(routeSub);

    const newQuestionSub = this.commonService.newQuestionSubject.subscribe(() => {
      this.getQuestions();
    });

    this.subs.push(newQuestionSub);
  }

  getQuestions() {
    this.loading = true;
    const questionsSub = this.commonService.getQuestions(this.rfpId, this.questionsPage)
      .subscribe((res: any) => {
        this.qas = res.questions.data;
        this.questionsFrom = res.questions.from;
        this.questionsTo = res.questions.to;
        this.questionsTotalRows = res.questions.total;
        this.questionsLastPage = res.questions.last_page;
        this.loading = false;

        const questionsIds = this.qas.map(qa => qa.id);
        this.commonService.questionsViewed.next(questionsIds);
      }, (err: any) => {
        console.log(err);
        this.loading = false;
      });

    this.subs.push(questionsSub);
  }

  respond(qa: any, isPrivate: boolean) {
    const respondSub = this.commonService.answerQuestion(qa.id, qa.answer, isPrivate)
      .subscribe((res: any) => {
        const newAnswer = res.data;
        this.qas = this.qas.map(qa => {
          if (qa.id === newAnswer.question_id) {
            qa.answers.push(newAnswer);
          }
          return qa;
        });
      }, (err) => {
        console.log(err);
      });
    this.subs.push(respondSub);
    return respondSub;
  }


  goToFirstPage() {
    this.questionsPage = 1;
    this.getQuestions();
  }

  goToLastPage() {
    this.questionsPage = this.questionsLastPage;
    this.getQuestions();
  }

  goToNextPage() {
    this.questionsPage++;
    this.getQuestions();
  }

  goToPreviousPage() {
    this.questionsPage--;
    this.getQuestions();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}
