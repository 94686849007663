import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService, UserService, AuthenticationService } from '../_services';

import { ApiResponse } from '../_models/response';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-criteria-add-edit',
  templateUrl: './criteria-add-edit.component.html',
  styleUrls: ['./criteria-add-edit.component.scss']
})
export class CriteriaAddEditComponent implements OnInit {
	 private _isModal = false;

    @Input()
    set isModal(val: boolean) {
        this._isModal = val;
    }

    get isModal(): boolean { return this._isModal; }

    @Output() onScuccess = new EventEmitter<string>();

    IsEdit = false;
    UserId = 0;
    IsCloned = false;
  constructor(
  	  public commonservice: CommonService,
        private userService: UserService,
   
        private activatedRoute: ActivatedRoute,
        private auth: AuthenticationService,
        private router: Router
  ) { }
    AddCriteria: FormGroup;
  
    criteriaFormError = "";

  ngOnInit() {
  	this.AddCriteria = new FormGroup({
            title: new FormControl('', [Validators.required]),
            description: new FormControl('', [Validators.required]),
           
        });
  }
  savecriteria(){
    
  }

}
