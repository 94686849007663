import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, CommonService } from '../../_services';
import { ApiResponse } from '../../_models/response';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  fpForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  linkSent = false;
  linkSentMsg = '';
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private router: Router
  ) {

  }
  ngOnInit() {
    this.fpForm = this.formBuilder.group({
      email: ['', [Validators.required]]
    });
  }
  onSubmit() {
    this.error = "";
    if (this.fpForm.valid) {
      this.loading = true;
      this.auth.forgotPassword(this.commonService.GetFormValue(this.fpForm, 'email')).subscribe((x: ApiResponse) => {
        if (x.code == 200) {
          //this.linkSent = true;
          //this.linkSentMsg = x.message;
          this.commonService.forgotPassSent = true;
          this.router.navigate(['/login']);
        } else {
          this.error = x.message;
        }
        this.loading = false;
      }, err => {
        this.error = err;
        this.loading = false;
      })
    }
    else {
      this.commonService.MarkAllAllCtrlDirty(this.fpForm);
    }
  }

  goBack() {
    this.router.navigate(['/login']);
  }
}
