import { Component, OnInit, ElementRef, HostListener, AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { VenderService} from '../_services/vender.service';
import { CommonService} from '../_services/';
import { DatePicker } from '../const';
import { ApiResponse } from '../_models/response';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-rfp-invite',
  templateUrl: './rfp-invite.component.html',
  styleUrls: ['./rfp-invite.component.scss']
})
export class RfpInviteComponent  implements OnInit{
  inviteVendorsList: any[];
  rfpId:string;
  msg: string;
  addVendorForm: FormGroup;
  company: any[];
  selectedItems = [];
  inviteSendError: string;
  submittedAddVendor = false;
  constructor(private route: ActivatedRoute,public router:Router,  private venderService: VenderService){

  }
  ngOnInit(){
    this.route.paramMap.subscribe(res=>{
      if(res.has("rfpId")){
        this.createAddVendorForm();
        this.rfpId = res.get("rfpId");
        this.getInviteVendorData();
        this.getVendorCompanies();
      }else{
        return;
      }
    })
  }
  get vendorFormControls() { return this.addVendorForm.controls; }
  // get vendorFormControls() { return this.addVendorForm.controls; }
  createAddVendorForm(){
    this.addVendorForm = new FormGroup({
            firstname: new FormControl('', [Validators.required]),
            lastname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            phoneno: new FormControl(''),
            companyName: new FormControl('',[Validators.required])
    });
  }

  selectVendor(id, email, $event){
    if($event.checked){
      this.selectedItems.push({ "id": id, "email": email });
      console.log(this.selectedItems);
    }else{
      console.log("Removing")
      this.selectedItems = this.selectedItems.filter(e=>{
      return  e.id != id
      })
      console.log(this.selectedItems);
    }
  }
  getInviteVendorData(){
    this.venderService.getAllVendorGroups(this.rfpId).subscribe((res:any)=>{
      if(res.code==200){
        this.inviteVendorsList = res.data[0];
      }
    });
  }

  getVendorCompanies(){
    this.venderService.getAllVendorGroupCompany().subscribe((r: any) => {
      let p = [];
      r.data[0].forEach((obj) => {
          p.push({ value: obj.id, label: (obj.group_name).toUpperCase() });
      });
      this.company = p;
    });
  }

  savevendor(){
    this.submittedAddVendor = true;
    if (this.addVendorForm.valid) {
          this.venderService.postvendorforgroup(this.addVendorForm.value,this.vendorFormControls.companyName.value).subscribe((u: ApiResponse) => {
              if (u.code == 200) {
                this.showMsg("Vendor Added Successfully");
                this.getInviteVendorData();
                this.getVendorCompanies();
              } else {
                  this.inviteSendError = u.message;
              }
          }, err => {
            this.inviteSendError = err;
          });
  }
  }
  sendInvite() {
    if(this.selectedItems.length>0){
      this.venderService.sendVendorsInvite(this.rfpId, this.selectedItems).subscribe((x: ApiResponse) => {
        if (x.code == 200) {
          this.showMsg("Successfully Invited");
          this.selectedItems = [];
          // this.getInviteVendorData();
          this.router.navigate(['detail-rfp', this.rfpId]);
        } else {
          this.showError(x.message);
          this.inviteSendError = x.message;
        }
      }, er => {


        this.showError(er)
      })
    }
  }
  sleep(ms) {
      return new Promise(resolve => setTimeout(resolve, ms));
    }

    async  showError(err) {
      this.inviteSendError = err;
      await this.sleep(5000);
      this.inviteSendError = undefined;
    }
    async  showMsg(msg) {
      this.msg = msg;
      await this.sleep(5000);
      this.msg = undefined;
    }
}
