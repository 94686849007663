import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptInjectorService {

  constructor() { }

  injectScript(script: string): void {
    const scriptElement = document.createElement('script');
    scriptElement.type = 'text/javascript';
    scriptElement.innerHTML = script;
    document.body.appendChild(scriptElement);
  }
}