import { NgModule } from '@angular/core';
import { Routes, RouterModule, ExtraOptions } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './public/login/login.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { RFPAddEditComponent } from './rfp-add-edit/rfp-add-edit.component';
import { RfpListComponent } from './rfp-list/rfp-list.component';
import { RfpDetailComponent } from './rfp-detail/rfp-detail.component';
import { RfpInviteComponent } from './rfp-invite/rfp-invite.component';
import { AuthGuard } from './_guards';
import { RegisterComponent } from './public/register/register.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { RfpResultComponent } from './rfp-result/rfp-result.component';

import { RfpSubmissionsComponent } from './rfp-submissions/rfp-submissions.component';
//Users
import { UserListComponent } from './user-list/user-list.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { AuthGuardAdmin } from './_guards/auth.guard-admin';

//List/MCQ
import { McqListComponent } from './mcq-list/mcq-list.component';
import { McqAddEditComponent } from './mcq-add-edit/mcq-add-edit.component';

//Tasks
import { MyTasksComponent } from './my-tasks/my-tasks.component';
import { ReassignTaskComponent } from './reassign-task/reassign-task.component';

//companies
import { CompaniesComponent } from './companies/companies.component';
import { CompanyOverviewComponent } from './company-overview/company-overview.component';

//profile
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyProfileEditComponent } from './my-profile-edit/my-profile-edit.component';
import { MyCompanyEditComponent } from './my-company-edit/my-company-edit.component';
import { VerifiedComponent } from './public/verified/verified.component';
import { VerifyComponent } from './public/verify/verify.component';
// vendor group
import { VendorGroupComponent } from './vendor-group/vendor-group.component';
import { AddEditVendorgroupComponent } from './vendor-group/add-edit-vendorgroup/add-edit-vendorgroup.component';
//vendors
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { VendorListComponent } from './vendor-list/vendor-list.component';
import { VendorCompanyOverviewComponent } from './vendor-company-overview/vendor-company-overview.component';
import { EvaluationCreteriaComponent } from './evaluation-creteria/evaluation-creteria.component';
import { WorksheetPreviewComponent } from './worksheet-preview/worksheet-preview.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { ImpersonateComponent } from './public/impersonate/impersonate.component';
import {RfpQuestionsComponent} from './rfp-questions/rfp-questions.component';

const routerOptions: ExtraOptions = {
  scrollPositionRestoration: 'enabled',
  anchorScrolling: 'enabled',
  relativeLinkResolution: 'legacy'
};

const routes: Routes = [
  { path: '', component: RfpListComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'impersonate', component: ImpersonateComponent },
  // { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verified', component: VerifiedComponent },
  { path: 'verify', component: VerifyComponent },
  { path: 'complete_registration', component: VerifiedComponent },
  { path: 'reset/password', component: ResetPasswordComponent },//ResetPassword
  {
    path: 'create-rfp',
    loadChildren: () => import('./rfp-add-edit/rfp-add-edit.module').then(m => m.RFPAddEditModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-rfp',
    loadChildren: () => import('./rfp-add-edit/rfp-add-edit.module').then(m => m.RFPAddEditModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'published-rfp',
    loadChildren: () => import('./rfp-view-published/rfp-view-published.module').then(m => m.RFPViewPublishedModule),
    canActivate: [AuthGuard]
  },
  { path: 'detail-rfp/:Id', component: RfpDetailComponent, canActivate: [AuthGuard] },
  { path: 'rfp/:id/questions', component: RfpQuestionsComponent, canActivate: [AuthGuard] },
  {
    path: 'submissions-rfp',
    loadChildren: () => import('./rfp-submissions/rfp-submissions.module').then(m => m.RfpSubmissionsModule),
    canActivate: [AuthGuard]
  },
  { path: 'result-rfp/:Id', component: RfpResultComponent, canActivate: [AuthGuard] },
  { path: 'reports', component: ReportsComponent, canActivate: [AuthGuard] },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'change-password', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'all-users', component: UserListComponent, canActivate: [AuthGuard] },
  { path: 'add-user', component: UserAddEditComponent, canActivate: [AuthGuardAdmin] },
  { path: 'edit-user/:Id', component: UserAddEditComponent, canActivate: [AuthGuardAdmin] },
  { path: 'all-supplier-company', component: VendorGroupComponent, canActivate: [AuthGuard] },
  { path: 'add-supplier-company', component: AddEditVendorgroupComponent, canActivate: [AuthGuardAdmin] },
  { path: 'edit-vendor-company/:Id', component: AddEditVendorgroupComponent, canActivate: [AuthGuardAdmin] },

  { path: 'company/:Id/add-supplier', component: AddVendorComponent, canActivate: [AuthGuardAdmin] },
  { path: 'all-suppliers/:Id', component: VendorListComponent, canActivate: [AuthGuard] },
  { path: 'company/edit-vendor/:rowId', component: AddVendorComponent, canActivate: [AuthGuardAdmin] },

  { path: 'vendors/companies/overview/:rowId', component: VendorCompanyOverviewComponent, canActivate: [AuthGuard] },
  { path: 'all-mcq', component: McqListComponent, canActivate: [AuthGuard] },
  { path: 'add-mcq', component: McqAddEditComponent, canActivate: [AuthGuardAdmin] },
  { path: 'edit-mcq/:Id', component: McqAddEditComponent, canActivate: [AuthGuardAdmin] },

  { path: 'my-tasks', component: MyTasksComponent, canActivate: [AuthGuard] },
  { path: 'companies', component: CompaniesComponent, canActivate: [AuthGuard] },
  { path: 'company-overview/:Id', component: CompanyOverviewComponent, canActivate: [AuthGuard] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGuard] },
  { path: 'edit-my-profile', component: MyProfileEditComponent, canActivate: [AuthGuard] },
  { path: 'edit-my-company', component: MyCompanyEditComponent, canActivate: [AuthGuard] },
  { path: 'reassigntasks/:uid', component: ReassignTaskComponent, canActivate: [AuthGuard] },
  { path: 'invite/:rfpId', component: RfpInviteComponent, canActivate: [AuthGuard] },

  { path: 'evaluation-creteria', component: EvaluationCreteriaComponent, canActivate: [AuthGuard] },
  { path: 'worksheet_preview/:rfpId', component: WorksheetPreviewComponent, canActivate: [AuthGuard] },
  // otherwise redirect to home
  { path: 'not-found', component: NotFoundComponent },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [CommonModule, RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
