import { Component, Input, OnInit, ElementRef, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { from, of, Subscription, zip } from 'rxjs';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as jexcel from "jexcel-pro";
import { ApiResponse } from '../_models/response';
import { VendorExcelDocHandler } from './vendorExcelData';
import { WorksheetService } from './worksheet.service';
import { RfpSetupService } from '../_services';
type sheetConfig = {
  sectionId: number,
  sheetName?: string,
  sheetDataLoaded?: boolean
};
@Component({
  selector: 'app-worksheet-preview',
  templateUrl: './worksheet-preview.component.html',
  styleUrls: ['./worksheet-preview.component.scss']
})
export class WorksheetPreviewComponent implements OnInit {
  worksheetHandler: VendorExcelDocHandler;
  @ViewChild('spreadSheet', { static: true }) worksheet: ElementRef;
  RfpId: string = "0";

  subs = new Subscription();
  sheetsConfig: sheetConfig[] = [];
  activeSheetIndex: number = 0;
  constructor(private sharedMethodService: WorksheetService, private rfpService: RfpSetupService, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.activatedRoute.params.subscribe(res => {
      if (res) {
        this.RfpId = res.rfpId
      }
    });
  }

  ngAfterViewInit() {
    this.subs.add(
      // gets selected tab index from worksheet
      this.sharedMethodService.getWorksheetSelectedTabIndex().pipe(
        filter((res1) => {
          this.activeSheetIndex = res1;
          let activeSectionId = this.sheetsConfig[res1].sectionId;

          return !this.sheetsConfig[res1].sheetDataLoaded && activeSectionId > 0;
        }
        ),
        switchMap((res1: number) => {
          // change Observable response on subscribe to response of SheetData
          return this.rfpService.getsheetDataBySheetId(this.RfpId, this.sheetsConfig[res1].sectionId)
        }
        )).subscribe((newSheetData: ApiResponse) => {
          if (newSheetData.code == 200) {
            // show New tab's data on worksheet
            this.updateSheetByTabIndex(newSheetData.data[0], this.activeSheetIndex);
          }
        }));
    this.worksheet.nativeElement.jexcel = null;
    this.getWorksheetTabs();
  }

  getWorksheetTabs() {
    this.rfpService.getWorksheetTabsbyRfpId(this.RfpId).pipe(
      // Initialize empty sheetTabs; returns true in Order to call getSheetDataBySheetId() if dataExist.
      filter((x: ApiResponse) => {
        if (x.code == 200 && x.data.length > 0) {
          let lastTab = x.data[x.data.length - 1];
          this.activeSheetIndex = 0;
          // Initializing Empty Tabs
          var i = 0;
          x.data.forEach(tab => {
            this.sheetsConfig[i] = { sectionId: tab.id, sheetName: tab.name };
            this.generateEmptySheet({ sectionId: tab.id, sheetName: tab.name }, tab.columns);
            i++;
          });
          // Sync DocHandler with new worksheet
          this.getWorksheetHandler().syncHandler(this.worksheet.nativeElement);
          if (lastTab.id > 0) {
            return true;
          }
        }

        return false;
      }),
      // switchMap will work only if filter returned true; there was atleast one existing sheet
      switchMap((x: ApiResponse) => {

        let lastTabIndex = x.data.length - 1;
        // zipping sheetData of last Sheet, coz, need to load data of active tab. other will be loaded on tabClick
        return zip(this.rfpService.getsheetDataBySheetId(this.RfpId, x.data[lastTabIndex].id), of(lastTabIndex));
      })
    ).subscribe((res: any[]) => {
      // LastSheetData
      let response: ApiResponse = res[0];

      // LastSheet index
      let lastTabIndex: number = res[1];

      if (response.code == 200) {
        // Loads data into the sheet[tabIndex]
        this.updateSheetByTabIndex(response.data[0], lastTabIndex);
      }
    });
  }

  getWorksheetHandler(): VendorExcelDocHandler {
    // if worksheetHandler does not exist Create one
    if (!(this.worksheetHandler instanceof VendorExcelDocHandler)) {

      this.worksheetHandler = new VendorExcelDocHandler(true);

      // Add notifierService to handler
      this.worksheetHandler.addCommonServices(this.sharedMethodService, this.rfpService);
    }
    return this.worksheetHandler;
  }

  generateEmptySheet(tabConfig: sheetConfig, data: any) {
    // Get Singleton Doc Instance
    let worksheetHandler = this.getWorksheetHandler();
    // Generate Sheet Options for new Sheet
    let sheetOptions = worksheetHandler.genSheetOptions(tabConfig.sheetName, data, true, 2, this.sheetsConfig.length);

    // Add new sheet in view
    let sheet = jexcel(this.worksheet.nativeElement, sheetOptions);

    let totalSheets = this.worksheet.nativeElement.jexcel.length;

    if (totalSheets == 1) {
      this.worksheet.nativeElement.jexcel[0].getConfig().worksheetId = tabConfig.sectionId;
    } else {
      setTimeout(() => {
        sheet.getConfig().worksheetId = tabConfig.sectionId;
        // Push tabconfig to sheetsConfig Array
        this.sheetsConfig.push(tabConfig);
      }, 1000);
    }
  }

  updateSheetByTabIndex(data: any, tabIndex: number) {
    let sheet: any;

    if (this.worksheet.nativeElement.jexcel[tabIndex]) {
      sheet = this.worksheet.nativeElement.jexcel[tabIndex];
    } else {
      sheet = this.worksheet.nativeElement.jexcel;
    }

    // updates the data
    sheet.setData(data.data);
    let i = 0;
    for (let col of data.columns) {
      sheet.setHeader(i, col.title);

      //set column hidden
      if (col.hidden) {
        sheet.hideColumn(i);
      }
      i++;
    }
    let k = 0;
    for (let i of data.rowId) {
      sheet.setRowId(k, i)
      k++;
    }

    this.sheetsConfig[tabIndex].sheetDataLoaded = true;
    this.getWorksheetHandler().syncHandler(this.worksheet.nativeElement);
    setTimeout(() => {
      sheet.getConfig().worksheetId = this.sheetsConfig[tabIndex].sectionId;
    }, 500);
  }
}
