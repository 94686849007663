import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CompaniesService } from '../_services/companies.service';
import { FormGroup } from '@angular/forms';
import { ApiResponse } from '../_models/response';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { DatePicker } from '../const';
import { CommonService } from '../_services';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  dataSource: any = [];
  serverError = "";
  searchText: string = '';
  deleteErrorMsg = "";
  previous: string;
  DatePicker = DatePicker;

  constructor(
    private companiesService: CompaniesService,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef
  ) { }
  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    this.companiesService.getAllComaniesList().subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        u.data[0].forEach((d, i) => {
          this.dataSource.push(d);
        })
        this.mdbTable.setDataSource(this.dataSource);
        this.dataSource = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      } else {
        this.serverError = u.message;
      }
    }, err => {
      this.serverError = err;
    })
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.dataSource = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.dataSource = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
}
