import { Injectable } from '@angular/core';
import { RfpSetupService, CommonService, UserService } from '../_services';
import { CriteriaService } from '../_services/criteria.service';
import { ApiResponse } from '../_models/response';
import { DatePicker, RFPWizardStepUrlSegment } from '../const';
import { Subject, BehaviorSubject, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Location } from '@angular/common'
import { AuthenticationService } from '../_services/authentication.service';
import { takeWhile } from 'rxjs/operators';
export enum StepperState {
  NEXT = -1,
  PREVIOUS = -2
}
@Injectable({
  providedIn: 'root'
})
export class SharedMethodService {
  deleteWorksheetTab(sheetIndex = 0, sheetId = 0) {
    this.deleteTab.next({ "sheetIndex": sheetIndex, "sheetId": sheetId });
  }
  public deleteTab = new Subject();
  private projectSetupSubject = new BehaviorSubject<any>({});
  private errorSubject = new BehaviorSubject<string>("");
  private warningSubject = new BehaviorSubject<string>("");
  private messageSubject = new BehaviorSubject<string>("");
  private stepperSubject = new Subject<number>();
  private bindBGInfoSubject = new Subject<any>();
  // private bindQuestionSubject = new Subject<any>();
  private selectedCriteriaSubject = new BehaviorSubject<any>({});
  private allCriteriaByCompanySubject = new BehaviorSubject<Array<any>>([]);
  private isEditSubject = new BehaviorSubject<boolean>(false);

  private ttlCriteriaWeight = new BehaviorSubject<any>("");
  private selectedRFpCriteriOp = new BehaviorSubject<Array<any>>([]);
  private SelectedContributer = new BehaviorSubject<any[]>([]);
  private worksheetSelectedTabIndex = new BehaviorSubject<number>(0);
  private paramSubject = new BehaviorSubject<any>({});
  private allowVendorInvite = new BehaviorSubject<boolean>(true);
  private rfpIdSubject = new BehaviorSubject<number>(0);
  private supplierDataFromSetupSubject = new BehaviorSubject<any>({});

  public apiPath = environment.apiUrl;
  private writerApproverOptionsSubject = new Subject<any>();
  private rfpname = new Subject<any>();
  constructor(
    private rfpSetupService: RfpSetupService,
    private commonservice: CommonService,
    public location: Location,
    private criteriaservice: CriteriaService,
    private authenticationService: AuthenticationService
  ) { }

  getSelectedCriteriaSubject() {
    return this.selectedCriteriaSubject.asObservable();
  }

  getWorksheetSelectedTabIndex() {
    return this.worksheetSelectedTabIndex.asObservable();
  }

  setWorksheetSelectedTabIndex(index) {
    return this.worksheetSelectedTabIndex.next(index);
  }
  setRfpname(name: string) {
    this.rfpname.next(name);
  }
  getRfpname() {
    return this.rfpname.asObservable();
  }
  setSelectedCriteriaSubject(data: any) {
    this.selectedCriteriaSubject.next(data);
  }

  getAllowVendorInvite() {
    return this.allowVendorInvite.asObservable();
  }

  setAllowVendorInvite(data: boolean) {
    this.allowVendorInvite.next(data);
  }

  getrfpIdSubject() {
    return this.rfpIdSubject.asObservable();
  }

  setrfpIdSubject(data: any) {
    this.rfpIdSubject.next(data);
  }

  getIsEditSubject() {
    return this.isEditSubject.asObservable();
  }

  setIsEditSubject(data: boolean) {
    this.isEditSubject.next(data);
  }

  getSupplierDataFromSetupSubject() {
    return this.supplierDataFromSetupSubject.asObservable();
  }

  setSupplierDataFromSetupSubject(data: any) {
    this.supplierDataFromSetupSubject.next(data);
  }

  setLocation(path: string) {
    this.location.go(path);
  }

  setSelectedContributer(data) {
    this.SelectedContributer.next(data);
  }

  getSelectedContributer() {
    return this.SelectedContributer.asObservable();
  }

  getParamSubject() {
    return this.paramSubject.asObservable();
  }

  setParamSubject(param: any) {

    this.paramSubject.next(param);
  }

  setAllSelectedRfpCriteriasOptions(options: Array<any>) {
    this.selectedRFpCriteriOp.next(options);
  }

  getAllSelectedRfpCriteriasOptions() {
    return this.selectedRFpCriteriOp.asObservable();
  }

  setError(msg: string) {
    this.errorSubject.next(msg);
  }

  getError() {
    return this.errorSubject.asObservable();
  }

  setWarning(data) {
    return this.warningSubject.next(data);
  }

  getWarning() {
    return this.warningSubject.asObservable();
  }

  setMessage(msg: string) {
    if (msg != "") {
      this.messageSubject.next(msg);
    }
  }

  getMessage() {
    return this.messageSubject.asObservable();
  }

  setTtlCriteriaWeightSubject(ttlWeight: number) {
    this.ttlCriteriaWeight.next(ttlWeight);
  }

  getTtlCriteriaWeightSubject() {
    return this.ttlCriteriaWeight.asObservable();
  }

  setStepperToNext() {
    this.stepperSubject.next(StepperState.NEXT);
  }

  setStepperToPrevious() {
    this.stepperSubject.next(StepperState.PREVIOUS);
  }

  setStepperTo(step) {
    this.stepperSubject.next(step);
  }

  bindBGInfo() {
    this.bindBGInfoSubject.next();
  }

  getBGInfoBindChanges() {
    return this.bindBGInfoSubject.asObservable();
  }
  // setRfpQuestions(data){
  //   this.bindQuestionSubject.next(data);
  // }
  // getRfpQuestions(){
  //   return this.bindQuestionSubject.asObservable();
  // }

  getStepperState() {
    return this.stepperSubject.asObservable();
  }

  setWriterApproverOptionState(data: any) {
    this.writerApproverOptionsSubject.next({ "data": data });
  }

  getWriterApproverOptionState(data: any) {
    return this.writerApproverOptionsSubject.asObservable();
  }

  getCurrentUserInfo() {
    return new Promise((resolve, reject) => {
      this.authenticationService.currentUser.subscribe(res => {
        resolve(res);
      }, err => {
        reject(err);
      });
    });
  }

  setAllCriteriaByCompanySubject(data: Array<any>) {
    this.allCriteriaByCompanySubject.next(data);
  }

  getAllCriteriaByCompanySubject() {
    return this.allCriteriaByCompanySubject.asObservable();
  }

  bindProjectSetup(RfpId, param = null) {
    let returnData: any = {};
    let projectSetupData: any = {};
    let supplierData: any = {};
    // this.firstFormError = "";
    this.rfpSetupService.getSetup(RfpId).subscribe((r: ApiResponse) => {

      if (r.code == 200) {
        // setting All Crierias

        let d = r.data.rfp;
        if(d.hasNoWorksheet == 1){
          localStorage.has_no_worksheet = true;
        }else{
          localStorage.has_no_worksheet = false;
        }
        projectSetupData.setupStatus = d.status;
        supplierData.setupStatus = d.status;
        if (d) {
          supplierData.AllowVendorInvite = true;

          if (d.deadlineForQuestions) {
            supplierData.AllowVendorInvite = !DatePicker.DeadlineOver(d.deadlineForQuestions);
          }

          this.getCurrentUserInfo().then((res: any) => {

            if (res.role.name == 'contributor') {
              supplierData.AllowVendorInvite = false;

            }
            supplierData.userRoleName = res.role.name;
            projectSetupData.userRoleName = res.role.name;
          }, (error) => { });
          if (d.deadlineForQuestions) {
            if (DatePicker.DeadlineOver(d.deadlineForQuestions)) {
              //  Work on this
              // this.route.navigate(['/']);
              // return;
            }
          }
          if (d.payer == 'vn') {
            supplierData.isCheckedResponder = true;

          } else {
            supplierData.isCheckedResponder = false;
          }
          supplierData.isCheckedSecureQuestion = d.is_secured_questions;
          projectSetupData.IsEdit = true;
          this.setIsEditSubject(true);
          projectSetupData.setupMeta = d.meta;
          let evalc = [];
          if (d.clonedRfpId) {
            projectSetupData.IsCloned = true;
            // this.rfpSetupService.getSetup(RfpId).subscribe((clone: ApiResponse) => {
            // if (clone.code == 200) {
            let d1 = d;
            projectSetupData.setupStatus = d1.status;
            if (d1) {
              projectSetupData.CloneFromName = d1.name;
              //newadded
              if (d1.deadlineForQuestions) {
                supplierData.AllowVendorInvite = !DatePicker.DeadlineOver(d1.deadlineForQuestions);
                console.log(supplierData.AllowVendorInvite);
              }
              this.getCurrentUserInfo().then((res: any) => {

                if (res.role.name == 'contributor') {
                  supplierData.AllowVendorInvite = false;
                }
                supplierData.userRoleName = res.role.name;
                projectSetupData.userRoleName = res.role.name;
              }, (error) => { });
              //endadded

              if (d.evalc && d1.evalc.length > 0) {
                evalc = d1.evalc;
              }

              if (d1.rfp_tags.length > 0) {
                this.setuptags(d1.rfp_tags);
              }

              if (d1.payer == 'vn') {
                supplierData.isCheckedResponder = true;
              } else {
                supplierData.isCheckedResponder = false;
              }

              supplierData.isCheckedSecureQuestion = d1.is_secured_questions;
              supplierData.payer = d1.payer;
              supplierData.is_secured_questions = d1.is_secured_questions;
            }
            // } else {
            //   console.error(clone.message)
            // }
            // }, er => { console.error(er) })
          } else {
            if (d.evalc && d.evalc.length > 0) {
              evalc = d.evalc;
            }
          }

          // get All Criteria from API
          this.criteriaservice.getCreteriaByUser().subscribe((ctr: any) => {
            let cr = [];
            ctr.data[0].forEach((obj) => {
              cr.push({ value: obj.id, label: (obj.title).toUpperCase(), weight: '', is_admin: obj.is_admin });
            });
            // set All Criteria
            this.setAllCriteriaByCompanySubject(cr);

            projectSetupData.ttlCriteriaWeight = this.setupEvaluationCriteria(evalc);
          });


          if (d.rfp_tags && d.rfp_tags.length > 0) {
            projectSetupData.tags = this.setuptags(d.rfp_tags);
          }
          projectSetupData.ProjectName = d.name;
          projectSetupData.PointOfContact = d.poc_user_id;
          projectSetupData.ResultAnnounced = DatePicker.ApiToUiFormat(d.results).Date;
          projectSetupData.DeadlineForQuestions = DatePicker.ApiToUiFormat(d.deadlineForQuestions).Date;

          if (d.contributors.length > 0) {
            this.setSelectedContributer(d.contributors.map(x => x.id));
          }
          projectSetupData.evalc = evalc;
          projectSetupData.FinalistDemonstartions = DatePicker.ApiToUiFormat(d.finalistDemo).Date;
          projectSetupData.QAWebinarSession = DatePicker.ApiToUiFormat(d.qaWebinarSession).Date;
          projectSetupData.FollowUpToQuestions = DatePicker.ApiToUiFormat(d.followUpQuestions).Date;
          projectSetupData.Time = DatePicker.ApiToUiFormat(d.finalistDemo).Time;
          projectSetupData.ProjectDescription = d.description;
          projectSetupData.TimeZon = d.timezone_id;
          projectSetupData.ESTIMATEDBUDGET = d.budget;
          projectSetupData.needHelp = d.need_help;
          projectSetupData.BUGDETFORRESPONDERS = (d.showBudget == 1 ? 1 : 0);
          projectSetupData.IsPrivate = (d.isPrivate + "");
          supplierData.payer = d.payer;
          supplierData.is_secured_questions = d.is_secured_questions;
        } else {
          projectSetupData.IsEdit = false;
          this.setIsEditSubject(false);
          projectSetupData.RfpId = 0;
          projectSetupData.firstFormError = r.message;
        }
        if (param != null) {
          setTimeout(() => {
            if (param.segment) {
              if (param.segment == RFPWizardStepUrlSegment.Template) {
                // this.stepper.setNewActiveStep(0);
                this.stepperSubject.next(0);

              }
              else if (param.segment == RFPWizardStepUrlSegment.ProjectSetup) {
                this.stepperSubject.next(1);

                // this.stepper.setNewActiveStep(this._getStepIndex(1));
              }
              else if (param.segment == RFPWizardStepUrlSegment.BGInfo) {

                this.stepperSubject.next(2);

                // this.stepper.setNewActiveStep(this._getStepIndex(2));
              }
              // else if (param.segment == RFPWizardStepUrlSegment.Appedix) {
              //     this.stepper.setNewActiveStep(this._getStepIndex(3));
              // }
              else if (param.segment == RFPWizardStepUrlSegment.Questions) {

                this.stepperSubject.next(3);
                // this.stepper.setNewActiveStep(this._getStepIndex(3));
                if (param.sectionId) {
                  projectSetupData.urlSelectedSectionId = param.sectionId;
                }
              }
              else if (param.segment == RFPWizardStepUrlSegment.Worksheet) {
                this.stepperSubject.next(4);
                // this.stepper.setNewActiveStep(this._getStepIndex(4));
              }
              else if (param.segment == RFPWizardStepUrlSegment.Supplier) {
                this.setSupplierDataFromSetupSubject(supplierData);
                this.stepperSubject.next(5);
                // this.stepper.setNewActiveStep(this._getStepIndex(5));
              }
            } else {
              this.stepperSubject.next(1);

              // this.stepper.setNewActiveStep(this._getStepIndex(1));
            }
          }, 200)
        }
      }
      else {
        projectSetupData.firstFormError = r.message;
        // this.firstFormError = r.message;
      }
    }, err => {
      projectSetupData.firstFormError = err;
      // this.firstFormError = err;
    }, () => {
      this.projectSetupSubject.next(projectSetupData);
      // this.supplierDataFromSetupSubject.next(supplierData);
      this.setSupplierDataFromSetupSubject(supplierData);

    })
  }

  getProjectSetupSubject() {
    return this.projectSetupSubject.asObservable();
  }

  setupEvaluationCriteria(evalcriterias: any) {

    var sumweight = 0;
    var evalc1 = [];
    var selectedCriteria: any[] = [];
    let isAlive = true;

    this.getAllCriteriaByCompanySubject().pipe(
      // using takeWhile to prevent loop
      takeWhile(() => isAlive)
    ).subscribe((res: any) => {
      isAlive = false;
      if (res) {
        for (let evalc of evalcriterias) {
          for (let evalCriteria of res) {
            if (evalCriteria.value == evalc.id) {
              selectedCriteria.push({ "value": evalc.id, "label": evalc.title, "weight": evalc.evaluation_weight });
              evalCriteria["checked"] = true;
              evalCriteria["weight"] = evalc.evaluation_weight;
              sumweight += evalc.evaluation_weight;
            }
          }
        }
      }

      // need selected Criteria to in different array to show in Worksheet.
      this.setSelectedCriteriaSubject(selectedCriteria);
      this.setAllCriteriaByCompanySubject(res);
      this.setTtlCriteriaWeightSubject(sumweight);

    }, (err) => { }, () => {
    })
  }

  setuptags(setuptags: any) {
    let setupTag = [];
    for (let settag of setuptags) {
      setupTag.push({
        id: settag.id,
        name: settag.name
      });
    }
    return setupTag;
  }

  getModules() {
    return {

      toolbar: [
        ['bold', 'italic', 'underline'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }],
        ['clean']
      ]
    };
  }

  getImgPath() {
    return this.apiPath + "/rfp/attachment/";
  }

  getSamplePath() {
    return this.apiPath + "/downloadsample/";
  }

  getSamplePathVision() {
    return this.apiPath + "/downloadsamplevision/";
  }

}
