import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePicker } from '../const';
import { environment } from '../../environments/environment';
import { operation } from "../_models/excelSheet";
import { CustomHttpParams } from '../_helpers/httpparams';
import { Observable } from 'rxjs';
@Injectable({ providedIn: 'root' })
export class RfpSetupService {
    constructor(private http: HttpClient) { }

    postSetup(frm, meta) {
        let setupObj = {
            name: frm.ProjectName,
            poc_user_id: frm.PointOfContact,
            results: DatePicker.ApiFormat(frm.ResultAnnounced),
            deadlineForQuestions: DatePicker.ApiFormat(frm.DeadlineForQuestions),
            finalistDemoDate: DatePicker.ApiFormat(frm.FinalistDemonstartions),
            qaWebinarSession: DatePicker.ApiFormat(frm.QAWebinarSession),
            followUpQuestions: DatePicker.ApiFormat(frm.FollowUpToQuestions),
            budget: parseFloat(frm.ESTIMATEDBUDGET),
            //  paymentTerms: frm.PAYMENTTERMS, //Confirm
            showBudget: frm.BUGDETFORRESPONDERS ? 1 : 0,
            description: frm.ProjectDescription,
            isPrivate: frm.IsPrivate == "0" ? 0 : 1,
            finalistDemoTime: (frm.Time),
            timezone_id: frm.TimeZon,
            contributor: frm.Contributors,
            tag: frm.Tags,
            evaluation_criteria: frm.evaluation_criteria,
            meta: meta,
            need_help: (frm.needHelp == true) ? 1 : 0
        }
        return this.http.post(`${environment.apiUrl}/rfp/setup`, setupObj);

    }
    updateSetup(frm, meta, Id) {

        let setupObj = {
            name: frm.ProjectName,
            poc_user_id: frm.PointOfContact,
            results: DatePicker.ApiFormat(frm.ResultAnnounced),
            deadlineForQuestions: DatePicker.ApiFormat(frm.DeadlineForQuestions),
            finalistDemoDate: DatePicker.ApiFormat(frm.FinalistDemonstartions),
            qaWebinarSession: DatePicker.ApiFormat(frm.QAWebinarSession),
            followUpQuestions: DatePicker.ApiFormat(frm.FollowUpToQuestions),
            budget: parseFloat(frm.ESTIMATEDBUDGET),
            // paymentTerms: frm.PAYMENTTERMS, //Confirm
            showBudget: frm.BUGDETFORRESPONDERS ? 1 : 0,
            description: frm.ProjectDescription,
            isPrivate: frm.IsPrivate == "0" ? 0 : 1,
            finalistDemoTime: (frm.Time),
            timezone_id: frm.TimeZon,
            contributor: frm.Contributors,
            evaluation_criteria: frm.evaluation_criteria,
            tag: frm.Tags,
            meta: meta,
            need_help: (frm.needHelp == true) ? 1 : 0
        };
        if(localStorage.has_no_worksheet == 'false'){
            setupObj["hasNoWorksheet"]  = 0;
        }else{
            setupObj["hasNoWorksheet"]  = 1;
        }
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(Id)}/setup`, setupObj);

    }
    getSetup(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/setup`);
    }

    getAllRpf(page, author, searchText = null, searchTags = null, ) {
      const body = {
        createdBy: author
      };
      if (searchTags && searchTags.length) {
        Object.assign(body, { searchTags });
      }
      if (searchText || searchText === '') {
        Object.assign(body, { searchText });
      }

      return this.http.post(`${environment.apiUrl}/rfps?page=${page}`, body);

    }
    cloneSetup(count, getter_id, setterid = null) {
        // if(setterid != null){
        let d = {
            count: count,
            setterid: setterid
        };
        return this.http.post(`${environment.apiUrl}/import/template/${btoa(getter_id)}`, d);
        //
    }
    //background

    getAllBackgroundList(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/backgroundinfo`);
    }

    getCalculations(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/calculations`);
    }

    getSetupFiles(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/attachment`);
    }

    getQuestionAttachments(rfpId,sectionId,questionId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(sectionId)}/question/${btoa(questionId)}/attachment`);
    }

    deleteSetupFileByID(rfpId, attachment_id) {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfpId)}/attachment/${btoa(attachment_id)}`);
    }

    deleteQuestionAttachment(rfpId, sectionId, questionId, attachmentId){
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(sectionId)}/question/${btoa(questionId)}/attachment/${btoa(attachmentId)}`);
    }

    postBackground(title, description, parentId, rfpId) {
        let d = {
            title: title,
            description: description
        };
        if (parentId) {
            d["parent_id"] = parentId;
        }
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/backgroundinfo`, d);
    }
    updateBackground(title, description, id, parentId, rfpId) {
        let d = {
            title: title,
            description: description
        };
        if (parentId) {
            d["parent_id"] = parentId;
        }
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/backgroundinfo/${btoa(id)}`, d);
    }
    deleteBackground(rfpId, id) {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfpId)}/backgroundinfo/${btoa(id)}`);
    }
    sortBackgroundInfo(rfpId, data) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/sortbackgrounds`, { background: data });
    }

    getAllSectionsList(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section`);
    }
    getSection(rfpId, section_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}`);
    }
    postSection(title, description, /*points,*/ isRequired: boolean,

        contributors: any, rfpId) {
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section`, {
            title: title,
            description: description,
            // writer_id: writer_id,
            // approver_id: approver_id,
            //  points: points,
            isRequired: isRequired ? "1" : "0",

            contributor: contributors,
            // criteria: criteria
        });
    }

    updateSection(title, description, /*points,*/ isRequired: boolean, section_id, rfpId, contributors: any) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/details`, {
            title: title,
            description: description,
            // writer_id: writer_id,
            // approver_id: approver_id,
            //points: points,
            isRequired: isRequired ? "1" : "0",

            contributor: contributors,
            // criteria: criteria
        });
    }
    updateSectionTitle(title, description, rfpId, section_id) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/title`, {
            title: title,
            description: description
        });
    }

    getChartDetailRfp(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/scoringdetails`);
    }

    deleteSection(rfpId, section_id) {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}`);
    }
    sortSection(rfpId, data) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/sortsections`, { section: data });
    }
    /**
      * status can be -
      * Pending
      * Approved
      * Rejected
     */
    updateSectionStatus(status, section_id, rfpId) {
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/approver/status`, {
            status: status
        });
    }

    //questions

    getAllQuestionsList(rfpId, section_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/question`);
    }

    getQuestion(rfpId, section_id, question_id) {
        //{{apiurl}}/rfp/{{new_rfp_id}}/section/{{new_section_id}}/question/{{new_question_id}}
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/question/${btoa(question_id)}`);
    }
    postQuestion(title, type, points, mcq_id, listType, options, scores, criteria,/*vendorHelpText, scoreHelpText, */section_id, rfpId) {
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/question`, {
            title: title,
            type: type,
            points: points,
            mcq_id: mcq_id,
            listType: listType,
            option: options,
            score: scores,
            criteria: criteria
            /*,
      vendorHelpText: vendorHelpText,
      scoreHelpText: scoreHelpText*/
        });
    }
    updateQuestion(title, type, points, mcq_id, listType, options, scores, criteria,/* vendorHelpText, scoreHelpText,*/ section_id, question_id, rfpId) {

        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/question/${btoa(question_id)}`, {
            title: title,
            type: type,
            points: points,
            mcq_id: mcq_id,
            listType: listType,
            option: options,
            score: scores,
            criteria: criteria
            /*,

      vendorHelpText: vendorHelpText,
      scoreHelpText: scoreHelpText*/
        });
    }
    deleteQuestion(rfpId, section_id, question_id) {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/question/${btoa(question_id)}`);
    }

    sortQuestion(rfpId, section_id, data) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/sortquestions`, { question: data });
    }

    approveSection(rfpId, section_id) {

        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/update/status`, {});
    }
    approveRejectSection(rfpId, section_id, status) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/updateapproverstatus`, { 'status': status });
    }

    checkWriterApprover(rfpId, section_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/writer/approver/section/${btoa(section_id)}/limitation`);
    }

    completeSectionButton(rfpId, section_id) {

        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/section/${btoa(section_id)}/writer/status`, {});
    }

    /**
     * status can be  Draft
        Published
        Completed
        Canceled
     * @param rfpId
     * @param staus
     */
    changeRfpStatus(rfpId, staus) {
        //changestatus
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/changestatus`, { status: staus });
    }

    awardRFP(rfpId, compId) {
        // rfp/{rfp_id}/awarded/company/{company_id}
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}//awarded/company/${btoa(compId)}`, {});
    }
    getSelectedContributors(section_id, rfp_id) {
        return this.http.get(`${environment.apiUrl}/assigned/task/to/section/${btoa(section_id)}/rfp/${btoa(rfp_id)}`, {});
    }

    getInviteVendorData() {
        return this.http.get(`${environment.apiUrl}/issuer/vendor/group`, {});
    }

    closeRFxStatus(rfpId, stat) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfpId)}/closerfp`, { status: stat });
    }

    sendHelpEmail(rfp_id) {
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/sendHelpEmail`, {});
    }

    // getallsectionpoints(rfp_id){
    //   return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/getallsectionpoints`);
    // }
    getSectionTotalPoints(rfp_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/getallsectionpoints`);
    }

    getScoreOnRfx(rfp_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/vendorsubmittedanswer`);
    }


    submitWorksheet(param) {
        return this.http.post(`${environment.apiUrl}/worksheet`, param);
    }

    getWorkSheet(rfp_id) {
        return this.http.get(`${environment.apiUrl}/worksheet/${rfp_id}`);
    }

    getWorksheetTabsbyRfpId(rfp_id, sheetTypeValue = null, generateNew = 0) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tabs?sheetType=${sheetTypeValue}&generateNew=${generateNew}`);
    }

    getVendorWorksheetTabsbyRfpId(rfp_id, tab_id) {
        let res = this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tab/${btoa(tab_id)}/vendor/columns`);
        return res;
    }

    getVendorRowsbyRfpId(rfp_id, tab_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tab/${btoa(tab_id)}/submission`);
    }

    // getsheetDataBySheetId()GET /rfp/{rfp_id}/worksheet/tab/{tab_id}
    getsheetDataBySheetId(rfp_id, tab_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tab/${btoa(tab_id)}`);
    }

    // updateSheet()PUT /rfp/{rfp_id}/worksheet/tab/{tab_id}/data
    updateSheet(rfp_id, tab_id, param) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tab/${tab_id}`, param);
    }

    addNewSheet(rfp_id, param) {
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tab `, param);
    }

    //background post upload data
    postBackgroundUpload(file) {
        let frm = new FormData();
        frm.append("file", file);

        return this.http.post(`${environment.apiUrl}/validatexls/background`, frm);
    }

    saveBackgroundXLS(filename, rfpId) {
        let frm = new FormData();
        frm.append("file_name", filename);
        frm.append("rfp_id", rfpId);
        return this.http.post(`${environment.apiUrl}/savexls/background`, frm);
    }

    //section post upload data
    postSectionUpload(file, rfpId) {
        let frm = new FormData();
        frm.append("file", file);
        frm.append("rfp_id", btoa(rfpId));
        return this.http.post(`${environment.apiUrl}/validatexls/section`, frm);
    }

    saveSectionXLS(filename, rfpId, changesMade = null) {
        let frm = new FormData();
        frm.append("file_name", filename);
        frm.append("rfp_id", rfpId);
        if(changesMade !== null){
            frm.append('changes',JSON.stringify(changesMade));
        }
        return this.http.post(`${environment.apiUrl}/savexls/section`, frm);
    }

    deleteWorksheetTab(rfp_id, tab_id): Observable<any> {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/worksheet/tab/${tab_id}`);
    }

    makePayment(rfp_id, token, frm) {
        let payObj = {
            name: frm.name,
            email: frm.email,
            address: frm.address,
            city: frm.city,
            state: frm.state,
            postal_code: frm.postal_code,
            country: frm.country,
            last_four: token.card.last4

        }
        let tokenid = token.id;
        return this.http.post(`${environment.apiUrl}/rfp/charge/${btoa(rfp_id)}/${tokenid}`, payObj);
    }
    getStripeSetting() {
        return this.http.get(`${environment.apiUrl}/stripe/setting`);
    }

    getSettings() {
        return this.http.get(`${environment.apiUrl}/settings`);
    }

    changePaymentPayer(rfp_id, token, payfrm, confForm) {
        let payObj = {
            name: payfrm.name,
            email: payfrm.email,
            address: payfrm.address,
            city: payfrm.city,
            state: payfrm.state,
            postal_code: payfrm.postal_code,
            country: payfrm.country,
            last_four: token.card.last4,
            payer: confForm.payer,
            is_secured_questions: confForm.is_secured_questions
        }
        let tokenid = token.id;
        return this.http.post(`${environment.apiUrl}/rfp/changePaymentPayer/${btoa(rfp_id)}/${tokenid}`, payObj);
    }
    updatePaymenttermAndRefundToIssuer(rfpId, frm) {
        let confirmObj = {
            payer: frm.payer,
            is_secured_questions: frm.is_secured_questions
        }
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/refundWithPaymentTermsUpdate`, confirmObj);
    }

    confirmPayment(rfpId, frm) {
        let confirmObj = {
            payer: frm.payer,
            is_secured_questions: frm.is_secured_questions
        }
        return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/paymentterms`, confirmObj);
    }

    checkSectionApprovedStatus(rfpId) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/checksectionapproved`);
    }

    storeSheetOpFlags(payload: operation[], section_id) {
        let apiUrl = "";
        apiUrl = `${environment.apiUrl}/rfp/worksheet/tab/${btoa(section_id)}`;
        return this.http.post(apiUrl, payload, { params: new CustomHttpParams(true, true) });
    }

    setNewSheet(payload: operation[], rfpId: string) {
        let apiUrl = `${environment.apiUrl}/rfp/${btoa(rfpId + "")}/worksheet/tab`;
        return this.http.post(apiUrl, payload);
    }
    deleteRfx(rfpId) {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfpId)}/delete`);
    }

    resendVendorInvites(rfxId, vendorId){
        return this.http.post(`${environment.apiUrl}/rfp/`+btoa(rfxId)+`/resend/invite/`+btoa(vendorId),{});   
    }

    downloadZipFile(rfpId,vendorId, sectionId, questionId, answerId, index){
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfpId)}/vendor/${btoa(vendorId)}/section/${btoa(sectionId)}/question/${btoa(questionId)}/answer/${btoa(answerId)}/attachment-zip/${index}`);
    }
}
