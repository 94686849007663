import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private http: HttpClient) { }

    getAllUsers() {
        return this.http.get(`${environment.apiUrl}/users`);
    }
    allusersget(rfpId) {
        return this.http.get(`${environment.apiUrl}/allusersget/${btoa(rfpId)}`);
    }

    getallContributor() {
        return this.http.get(`${environment.apiUrl}/contributors`);
    }
    getContributorsForDeleteUser(uid){
        return this.http.get(`${environment.apiUrl}/rfp/contributors/${btoa(uid)}`);
    }

    getAssignedTasks(uid){
        return this.http.get(`${environment.apiUrl}/assigned/task/to/section/user/${btoa(uid)}`);
    }
    getUser(userId) {
        return this.http.get(`${environment.apiUrl}/user/${btoa(userId)}`);
    }
    uploadDpImage(data): Observable<any>{
      return this.http.post(`${environment.apiUrl}/saveuserimage`, data);
    }
    uploadLogoImage(base64Image):Observable<any>{
      return this.http.post(`${environment.apiUrl}/update/companylogo`, {comp_logo: base64Image});
    }
    getUserImage():Observable<any>{
      return this.http.get(`${environment.apiUrl}/contributors`);
    }
    handleError(err){

    }
    getPublicUser(userId) {
        return this.http.get(`${environment.apiUrl}/publicuser/${btoa(userId)}`);
    }
    deleteuser(userId) {
        return this.http.delete(`${environment.apiUrl}/user/${btoa(userId)}`);
    }
    updateCurrentProfile(frm) {
        let setupObj = {
            fname: frm.firstName,
            lname: frm.lastName,
            phone: frm.phoneno
        }
        return this.http.put(`${environment.apiUrl}/profiledetails/update`, setupObj);
    }
    postuser(frm) {
        let setupObj = {
            fname: frm.firstName,
            lname: frm.lastName,
            email: frm.emailid,
            role_id: frm.roles,
            phone: frm.phoneno,
            invitation_message: frm.invitationmessage
        }
        return this.http.post(`${environment.apiUrl}/create/user`, setupObj);
    }

    updateuser(frm, usrId) {
        let setupObj = {
            fname: frm.firstName,
            lname: frm.lastName,
            email: frm.emailid,
            role_id: frm.roles,
            phone: frm.phoneno
        }
        return this.http.put(`${environment.apiUrl}/update/user/${btoa(usrId)}`, setupObj);
    }

    reassigntasks(frm: any, old_assignee){
      let dt1 = {
        dt: frm.row
      }
      return this.http.put(`${environment.apiUrl}/reassigntasks/${old_assignee}`, dt1);
    }
    resendEmailToInviteUser(userid){
        return this.http.post(`${environment.apiUrl}/invite/resendemail/user/${btoa(userid)}`, {});
    }
}
