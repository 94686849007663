import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators ,FormBuilder,FormArray} from '@angular/forms';
import { CommonService, UserService, AuthenticationService } from '../_services';
import { RoleService } from '../_services/role.service';
import { ApiResponse } from '../_models/response';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-reassign-task',
  templateUrl: './reassign-task.component.html',
  styleUrls: ['./reassign-task.component.scss']
})
export class ReassignTaskComponent implements OnInit {

    private _isModal = false;

    @Input()
    set isModal(val: boolean) {
        this._isModal = val;
    }

    get isModal(): boolean { return this._isModal; }

    @Output() onScuccess = new EventEmitter<string>();

    IsEdit = false;
    UserId = 0;
    selectedSectionConts: any[] = [];
    tasksAssigned: any[] = [];
    IsCloned = false;
    allContributors: any[] = [];
    SelectedSections: any[] = [];
    AddUser: FormGroup =this.fb.group({});
    formRowsArray: FormArray;

    constructor(
        public commonservice: CommonService,
        private userService: UserService,
        public roleService: RoleService,
        private activatedRoute: ActivatedRoute,
        private auth: AuthenticationService,
        private fb: FormBuilder,
        private router: Router,

    ) {
    }

  getForm() {
    this.AddUser = this.createGroup();
  }

 createGroup() {
   const formArray = new FormArray([
    //   this.fb.group({
    //    rfp: [''],
    //    section:[''],
    //    contributorList:['']
    //  })
   ]);
    const group = this.fb.group({
      row: formArray
    });

    // let i = 0;
    // for(let ele of this.taskassign){
    //     console.log("Hi")
        // const g1 = this.fb.group({
        //   rfp: [''],
        //   section:[''],
        //   contributorList:['']
        // });
    //     g1.addControl("rfp_id_"+ele.rfp_id, this.fb.control(''));
    //     g1.addControl("section_id_"+ele.rfp_id, this.fb.control(''));
    //     g1.addControl("contributors"+ele.rfp_id, this.fb.control(''));
    //     // formArray.add('group_'+i, g1);
    //   formArray.controls.push(g1);
    // }
    return group;
  }

    taskassign: Array<any>;
    userFormError ="";
    items: FormArray;
    ngOnInit() {
      this.getForm();
      this.formRowsArray = (<FormArray>this.AddUser.get('row'));
     
        this.activatedRoute.params.subscribe((param: any) => {
            if (param.uid) {
                this.UserId = param.uid;

                this.userFormError = "";
                this.userService.getContributorsForDeleteUser(this.UserId).subscribe((t: any) => {
                  let p = [];
                  t.data[0].forEach((obj) => {
                      p.push({ value: obj.id, label: (obj.fName + " " + obj.lName).toUpperCase() });
                  });
                  this.allContributors = p;
              });

              this.userService.getAssignedTasks(this.UserId).subscribe((t: any) => {
                let p = [];
                this.taskassign = t.data[0];
                for(let ele of this.taskassign){
                    const g1 = this.fb.group({
                      rfp_name: ele.rfp_name,
                      section_title: ele.section_title,
                      rfp_id: [ele.rfp_id],
                      section_id:[ele.section_id],
                      new_user_id:['', [Validators.required]]
                    });
                    (<FormArray>this.AddUser.get('row')).push(g1);
                  }
                  console.log((<FormArray>this.AddUser.get('row')));
                this.SelectedSections = [];

                });

            }
            else {
                this.router.navigate(['/all-users']);
            }
        });
    }

    taskassigntouser() {
    
      console.log(this.AddUser.value)
      let arr = this.AddUser.value.row;
        if (this.AddUser.valid) {

                this.userService.reassigntasks(this.AddUser.value, this.UserId).subscribe((u: ApiResponse) => {
                    if (u.code == 200) {
                        this.router.navigate(['/all-users']);
                    } else {
                        this.userFormError = u.message;
                    }
                }, err => {
                    this.userFormError = err;
                });

        } else {
            this.commonservice.MarkAllAllCtrlDirty(this.AddUser);
        }
    }
}
