import { Component, OnInit ,ViewChild } from '@angular/core';
import { MdbTableDirective } from 'ng-uikit-pro-standard';
import { DatePicker } from '../const';
import { VenderService } from '../_services/vender.service';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse } from '../_models/response';
import { debug } from 'util';

@Component({
  selector: 'app-vendor-company-overview',
  templateUrl: './vendor-company-overview.component.html',
  styleUrls: ['./vendor-company-overview.component.scss']
})
export class VendorCompanyOverviewComponent implements OnInit {
	
	@ViewChild("tablePending", { static: true }) mdbTablePending: MdbTableDirective;
 	@ViewChild("tableRejected", { static: true }) mdbTableRejected: MdbTableDirective;
  	@ViewChild("tableInProgress", { static: true }) mdbTableInProgress: MdbTableDirective;

  	rowId = 0
	vendor: any = {};
	companyServerError = "";
	contactDataSource: any = [];
	contactServerError = "";
	contactSearchText: string = '';
	contactPrevious: any;

	pendingDataSource: any = [];
	pendingServerError = "";
	pendingSearchText: string = '';
	pendingPrevious: any;

  	rejectedDataSource: any = [];
  	rejectedServerError = "";
  	rejectedSearchText: string = '';
  	rejectedPrevious: any;


  	inProgressDataSource: any = [];
  	inProgressServerError = "";
  	inProgressSearchText: string = '';
  	inProgressPrevious: any;

  	DatePicker = DatePicker;
  	constructor(private venderService: VenderService,
    private activatedRoute: ActivatedRoute) { }

  	ngOnInit() {
  		this.activatedRoute.params.subscribe((param: any) => {
	      if (param.rowId) {
	        this.rowId = param.rowId;
	        this.venderService.getGroupsVendorDetails(this.rowId).subscribe((c: ApiResponse) => {
	          if (c.code == 200) {
	            this.vendor = c.data[0];
	          } else {
	            this.companyServerError = c.message;
	          }
	        }, err => {
	          this.companyServerError = err;
	        })
	     
	        this.bindPendingList();
	       	this.bindRejectedList();
	        this.bindInProgressList();
	      }
	    });
  	}

  	bindPendingList() {
  	
	    this.venderService.getInvitedVendorByStatus(this.rowId, 'pending').subscribe((c: ApiResponse) => {
	      if (c.code == 200) {
	        c.data[0].forEach((d, i) => {
	          this.pendingDataSource.push(d);
	        })
	        this.mdbTablePending.setDataSource(this.pendingDataSource);
	        this.pendingDataSource = this.mdbTablePending.getDataSource();
	        this.pendingPrevious = this.mdbTablePending.getDataSource();
	      } else {
	        this.pendingServerError = c.message;
	      }
	    }, err => {
	      this.pendingServerError = err;
	    })
  	}
  	bindRejectedList() {
	    this.venderService.getInvitedVendorByStatus(this.rowId, 'rejected').subscribe((c: ApiResponse) => {
	      if (c.code == 200) {
	        c.data[0].forEach((d, i) => {
	          this.rejectedDataSource.push(d);
	        })
	        this.mdbTableRejected.setDataSource(this.rejectedDataSource);
	        this.rejectedDataSource = this.mdbTableRejected.getDataSource();
	        this.rejectedPrevious = this.mdbTableRejected.getDataSource();
	      } else {
	        this.rejectedServerError = c.message;
	      }
	    }, err => {
	      this.rejectedServerError = err;
	    })
  	}

  	bindInProgressList() {
	    this.venderService.getInvitedVendorByStatus(this.rowId, 'accepted').subscribe((c: ApiResponse) => {
	      if (c.code == 200) {
	        c.data[0].forEach((d, i) => {
	          this.inProgressDataSource.push(d);
	        })
	        this.mdbTableInProgress.setDataSource(this.inProgressDataSource);
	        this.inProgressDataSource = this.mdbTableInProgress.getDataSource();
	        this.inProgressPrevious = this.mdbTableInProgress.getDataSource();
	      } else {
	        this.inProgressServerError = c.message;
	      }
	    }, err => {
	      this.inProgressServerError = err;
	    })
  	}

  	pendingSearchItems() {
	    const prev = this.mdbTablePending.getDataSource();

	    if (!this.pendingSearchText) {
	      this.mdbTablePending.setDataSource(this.pendingPrevious);
	      this.pendingDataSource = this.mdbTablePending.getDataSource();
	    }

	    if (this.pendingSearchText) {
	      this.pendingDataSource = this.mdbTablePending.searchLocalDataBy(this.pendingSearchText);
	      this.mdbTablePending.setDataSource(prev);
	    }
	}

	rejectedSearchItems() {
	    const prev = this.mdbTableRejected.getDataSource();

	    if (!this.rejectedSearchText) {
	      this.mdbTableRejected.setDataSource(this.rejectedPrevious);
	      this.rejectedDataSource = this.mdbTableRejected.getDataSource();
	    }

	    if (this.rejectedSearchText) {
	      this.rejectedDataSource = this.mdbTableRejected.searchLocalDataBy(this.rejectedSearchText);
	      this.mdbTableRejected.setDataSource(prev);
	    }
  	}

  	inProgressSearchItems() {
	    const prev = this.mdbTableInProgress.getDataSource();

	    if (!this.inProgressSearchText) {
	      this.mdbTableInProgress.setDataSource(this.inProgressPrevious);
	      this.inProgressDataSource = this.mdbTableInProgress.getDataSource();
	    }

	    if (this.inProgressSearchText) {
	      this.inProgressDataSource = this.mdbTableInProgress.searchLocalDataBy(this.inProgressSearchText);
	      this.mdbTableInProgress.setDataSource(prev);
	    }
	}

}
