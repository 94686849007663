import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class McqService {
  constructor(private http: HttpClient) { }

  getAllMcq() {
    return this.http.get(`${environment.apiUrl}/mcq`);
  }

  getMcq(mcq_id) {
    return this.http.get(`${environment.apiUrl}/mcq/${btoa(mcq_id)}`);
  }
  postMcq(name, scores, options,evalcr) {
    let setupObj = {
      name: name,
      // description: description,
      option: options,
      score: scores,
      evalCriteria: evalcr
    }
    return this.http.post(`${environment.apiUrl}/mcq`, setupObj);
  }
  updateMcq(name, options, scores,evalcr, mcq_id) {
    let setupObj = {
      name: name,
      // description: description,
      option: options,
      score: scores,
      evalCriteria: evalcr
    }
    return this.http.put(`${environment.apiUrl}/mcq/${btoa(mcq_id)}`, setupObj);
  }
  deleteMcq(mcq_id) {
    return this.http.delete(`${environment.apiUrl}/mcq/${btoa(mcq_id)}`);
  }
  //mcq post upload data
  postMCQUpload(file){
    let frm = new FormData();
    frm.append("file", file);
    return this.http.post(`${environment.apiUrl}/validatexls/mcq`, frm);
  }
  
  saveMCQXLS(filename){
    let frm = new FormData();
    frm.append("file_name", filename);
    return this.http.post(`${environment.apiUrl}/savexls/mcq`, frm);
  }
}
