import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(email: string, password: string) {
        return new Promise((resolve, reject) => {

            this.http.post(`${environment.apiUrl}/api-login`, { email: email, password: password, type: "is" }).subscribe((user: any) => {
                if (user && user.data) {

                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    localStorage.setItem('currentUser', JSON.stringify(user.data));
                    this.currentUserSubject.next(user.data);
                }
                resolve(user);
            }, err => {
                console.log(err);
                reject(err);
            });
        });
    }
    impersonatelogin(type:string,token:string,id){
    return new Promise((resolve, reject) => {
        this.http.post(`${environment.apiUrl}/impersonate`, { type: type, token: token,id:id }).subscribe((user: any) => {

            if (user && user.data) {
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user.data));
                this.currentUserSubject.next(user.data);
            }
            resolve(user);
        }, err => {
            console.log(err);
            reject(err);
        });
    });
    }
    register(user) {
        return this.http.post(`${environment.apiUrl}/api-register`, user);

        //if (user.type == "vn") {
        //  //vendor
        //  return this.http.post(`${environment.apiUrl}/vendor/api-register`, user);
        //} else {
        //  return this.http.post(`${environment.apiUrl}/api-register`, user);
        //}
    }
    forgotPassword(email) {
        return this.http.post(`${environment.apiUrl}/forgot/password`, { email: email,type:'is' });
    }
    //change/user/password
    changePassword(oldpassword, newpassword, confirmpassword) {
        return this.http.put(`${environment.apiUrl}/change/user/password`, { oldpassword: oldpassword, newpassword: newpassword, confirmpassword: confirmpassword });
    }
    resetPassword(newpassword, confirmpassword, id, token) {
        return this.http.put(`${environment.apiUrl}/reset/password/${id}/${token}`, { newpassword: newpassword, confirmpassword: confirmpassword });
    }
    getresetPassword(id, token) {
        return this.http.get(`${environment.apiUrl}/resetpassword/${id}/${token}`, {});
    }

    verifyAccount(id, token) {
        return this.http.put(`${environment.apiUrl}/user/verify/${id}/${token}`, {});
    }

    logout() {
        // remove user from local storage to log user out
        localStorage.removeItem('currentUser');
        localStorage.removeItem("setupObject");
        this.currentUserSubject.next(null);
    }
    getPaymentTerms(){
        return this.http.get(`${environment.apiUrl}/settings/terms`);
    }
}
