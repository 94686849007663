import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MDBModalRef } from 'ng-uikit-pro-standard';

@Component({
    selector: 'app-add-user-modal',
    templateUrl: './add-user-modal.component.html',
    styleUrls: ['./add-user-modal.component.scss']
})
export class AddUserModalComponent {
    action: Subject<any> = new Subject();
    constructor(public modalRef: MDBModalRef) {

    }

    onCancelClick() {
        this.modalRef.hide();
    }
    onScuccess(ev) {

        // debugger;

        this.modalRef.hide();
        this.action.next(ev);
    }
}
