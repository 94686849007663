import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class TaskService {
  constructor(private http: HttpClient) { }

  getAllTasks(type) {
    return this.http.get(`${environment.apiUrl}/tasks?type=${type}`);
  }
}
