import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-mcq-confirm',
  templateUrl: './mcq-confirm.component.html',
  styleUrls: ['./mcq-confirm.component.scss']
})
export class McqConfirmComponent {
	  action: Subject<any> = new Subject();
  	heading: string;
  	description: string;
   constructor(public modalRef: MDBModalRef) {
    this.heading = this.heading || "Override Question Name";
    this.description = this.description || "Are you sure you override question name with MCQ name ?";
  }

  YesClick() {
    this.modalRef.hide();
    this.action.next(true);
  }

  NoClick() {
    this.modalRef.hide();
    this.action.next(false);
  }
}






