import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiResponse } from '../_models/response';
import { RfpSetupService } from '../_services';
import { VenderService } from '../_services/vender.service';
import { SubstringPipe } from '../_shared/pipes/substring.pipe'
@Component({
  selector: 'app-rfp-result',
  templateUrl: './rfp-result.component.html',
  styleUrls: ['./rfp-result.component.scss']
})
export class RfpResultComponent implements OnInit {
  RfpId: any;
  AllVendors = [];
  award: any;
  rfp: any;
  selectedCompanies: number[]=[];
  constructor(private router:Router, private activatedRoute: ActivatedRoute, private rfpSetupService: RfpSetupService, private venderService: VenderService) { }
  ngOnInit() {
    this.activatedRoute.params.subscribe((param: any) => {
      if (param.Id) {
          this.RfpId = param.Id;
      }
  });
  this.getResultData();
  this.getRfpById();
}
getRfpById(){
      this.rfpSetupService.getSetup(this.RfpId).subscribe((r: ApiResponse) => {
        if (r.code == 200) {
            this.rfp = r.data.rfp;

        } else {
            // this.serverError = r.message;
        }
    }, err => {
        // this.serverError = err;
    });

  }
  getResultData(){

    this.venderService.getResultsByRFPId(this.RfpId).subscribe((res:any)=>{
      this.AllVendors = res.data[0];
    }, err=>{

    });
  }
  onSubmit(form){
    this.venderService.awardRFP(this.RfpId, this.selectedCompanies).subscribe((res:any)=>{
      this.router.navigate(['/submissions-rfp/'+this.RfpId]);
    });
  }
  selectComps(compId: number, $event){
    if($event.checked){
      this.selectedCompanies.push(compId);
    }else{
      this.selectedCompanies.forEach((item,index)=>{
        if(item==compId) this.selectedCompanies.splice(index, 1);
      })
    }
    // console.log(this.selectedCompanies);
  }
}
