import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService, UserService, AuthenticationService } from '../_services';
import { RoleService } from '../_services/role.service';
import { ApiResponse } from '../_models/response';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
    selector: 'app-user-add-edit',
    templateUrl: './user-add-edit.component.html',
    styleUrls: ['./user-add-edit.component.scss']
})
export class UserAddEditComponent implements OnInit {

    private _isModal = false;
    public hasModal = false;
    @Input()
    set isModal(val: boolean) {
        this._isModal = val;
        this.hasModal =  this._isModal;
    }

    get isModal(): boolean { return this._isModal; }

    @Output() onScuccess = new EventEmitter<string>();

    IsEdit = false;
    UserId = 0;
    IsCloned = false;
    isVerified = false;
    constructor(
        public commonservice: CommonService,
        private userService: UserService,
        public roleService: RoleService,
        private activatedRoute: ActivatedRoute,
        private auth: AuthenticationService,
        private router: Router
    ) {
    }

    AddUser: FormGroup;
    Roles: Array<any>;
    userFormError = "";

    ngOnInit() {
        this.AddUser = new FormGroup({
            firstName: new FormControl('', [Validators.required]),
            lastName: new FormControl('', [Validators.required]),
            emailid: new FormControl('', [Validators.required]),
            roles: new FormControl(''),
            phoneno: new FormControl(''),
            invitationmessage: new FormControl('')
        });

        this.roleService.getAllRoles().subscribe((r: any) => {
            let p = [];
            r.data[0].forEach((obj) => {
                p.push({ value: obj.id, label: (obj.name).toUpperCase() });
            });
            this.Roles = p;
        }, (err)=>{
            this.userFormError = err;
        });
        this.activatedRoute.params.subscribe((param: any) => {
            if (param.Id) {
                this.UserId = param.Id;

                this.userFormError = "";
                this.userService.getUser(this.UserId).subscribe((r: ApiResponse) => {
                    
                    if (r.code == 200) {
                        let d = r.data[0];
                        
                        if (d) {
                            this.IsEdit = true;
                            if(d.isConfirmed == 1){
                                this.isVerified = true;
                            }
                            this.commonservice.SetFormValue(this.AddUser, "firstName", d.fName);
                            this.commonservice.SetFormValue(this.AddUser, "lastName", d.lName);
                            this.commonservice.SetFormValue(this.AddUser, "emailid", d.email);

                            this.commonservice.SetFormValue(this.AddUser, "roles", d.role_id);
                            // this.commonservice.SetFormValue(this.AddUser, "password", d.password);
                            this.commonservice.SetFormValue(this.AddUser, "phoneno", d.phone);

                        } else {
                            this.IsEdit = false;
                            this.UserId = 0;
                            this.userFormError = r.message;
                        }
                    }
                    else {
                        this.userFormError = r.message;
                    }
                }, err => {

                    this.userFormError = err;
                })
            }
            else {

                this.userService.getUser(this.auth.currentUserValue.userid).subscribe((u: ApiResponse) => {
                    let u1 = u.data[0];
                    let name = u1.fName;
                    if (u1.lName) {
                        name += " " + u1.lName;
                    }
                    let msg = `You are invited by ${name}`;
                    if (u1.company) {
                        if (u1.company.name) {
                            msg += ` to be a part of ${u1.company.name}`;
                        }
                    }

                    this.commonservice.SetFormValue(this.AddUser, "invitationmessage", msg);
                })
            }
        });

    }

    saveuser() {

        if (this.AddUser.valid) {
            this.userFormError = "";
            if (this.IsEdit) {
                //Update
                this.userService.updateuser(this.AddUser.value, this.UserId).subscribe((u: ApiResponse) => {
                    if (u.code == 200) {
                        this.router.navigate(['/all-users']);
                    } else {
                        this.userFormError = u.message;
                    }
                }, err => {
                    this.userFormError = err;
                });
            } else {
                //Save
                this.userService.postuser(this.AddUser.value).subscribe((u: ApiResponse) => {
                    if (u.code == 200) {
                        if (this.isModal) {
                            this.onScuccess.emit(u.message);
                        } else {
                            this.router.navigate(['/all-users']);
                        }
                    } else {
                        this.userFormError = u.message;
                    }
                }, err => {
                    this.userFormError = err;

                });
            }

        } else {
            this.commonservice.MarkAllAllCtrlDirty(this.AddUser);
        }
    }
    cancelContributerModal(){
        this.onScuccess.emit();
    }
    resendInvite(){
        if (this.IsEdit) {
             //Save
            this.userService.resendEmailToInviteUser(this.UserId).subscribe((u: ApiResponse) => {
                if (u.code == 200) {
                    this.router.navigate(['/all-users']);
                }
            }, err => {
                this.userFormError = err;
            });
        }
    }

}
