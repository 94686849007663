import {Injectable, NgZone} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { CustomHttpParams } from '../_helpers/httpparams';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client';
import {AuthenticationService} from './authentication.service';

@Injectable({ providedIn: 'root' })

export class CommonService {

  public ShowLoader = false;
  public ShowSaving = false;
  public changeLogo: any = new Subject;
  public forgotPassSent = false;

  newQuestionSubject = new Subject<any>();
  questionsViewed = new Subject<any>();

  constructor(private http: HttpClient, private zone: NgZone, private auth: AuthenticationService) {
    this.auth.currentUser.subscribe((user) => {
      if (user) {
        // this.initSocket();
      }
    });
  }

  initSocket() {
    window['io'] = io;
    const currentUser = this.auth.currentUserValue;
    window['Echo'] = new Echo({
      broadcaster: 'socket.io',
      host: environment.serverUrl,
      // host: "localhost:6001",
      auth: {
        headers: {
          Authorization: `Bearer ${currentUser.apiToken}`
        }
      },
      secure: true
    });

    const channel = `question-asked.${this.auth.currentUserValue.userid}`;

    window['Echo'].join(channel)
      .listen('.NewVendorQuestion', (res) => {
        this.zone.run(() => {
          this.newQuestionSubject.next(res.question_info);
        });
      });
  }

  GetAllTimeZones() {
    return this.http.get(`${environment.apiUrl}/timezones`);
  }
  GetAllPaymentTerms() {
    return this.http.get(`${environment.apiUrl}/rfp/paymentterms`);
  }
  GetAllContributors() {
    return this.http.get(`${environment.apiUrl}/contributors`);
  }
  GetAllRfpProjects() {
    return this.http.get(`${environment.apiUrl}/rfp/projects`);
  }
  GetAllPOC() {
    return this.http.get(`${environment.apiUrl}/issuers`);
  }
  GetAllMCQ() {
    return this.http.get(`${environment.apiUrl}/mcq`);
  }
  MarkAllAllCtrlDirty(frm: FormGroup) {
    Object.values(frm.controls).forEach(control => {
      control.markAsDirty();
      control.markAsTouched();
    });

  }

  SetFormValue(frm: FormGroup, name: string, value: any) {
    frm.controls[name].setValue(value);
  }
  GetFormValue(frm: FormGroup, name: string) {
    return frm.controls[name].value;
  }

  GetQueryStringValue(key) {
    return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
  }
  GetAllTags(search = null) {
    return this.http.get(`${environment.apiUrl}/getalltags/${search}`,
      {
        params: new CustomHttpParams(true)
      }
    );
  }
  generateExcelForRFx(RFxId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/rfp/generate/excel/for/rfx/${RFxId}`).subscribe((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      });
    });
  }
  generateExcelForRFxAfterPublish(RFxId) {
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/rfp/generate/post/excel/for/rfx/${RFxId}`).subscribe((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      });
    });
  }
  getListFromTagName(data) {
    let encryptedData = btoa(JSON.stringify(data));
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.apiUrl}/rfp/get/filtered/list/${encryptedData}`).subscribe((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      });
    });
  }
  getPaymentInfo(rfpId: any) {
    return this.http.get(`${environment.apiUrl}/rfp/paymentterms/${btoa(rfpId)}`);
  }
  getStates() {
    return this.http.get(`${environment.apiUrl}/states`);
  }
  getQuestions(rfpId, page = 1) {
    return this.http.get(`${environment.apiUrl}/issuer/rfp/${btoa(rfpId)}/vendors-questions?page=${page}`);
  }
  answerQuestion(questionId, answer, isPrivate) {
    return this.http.post(`${environment.apiUrl}/issuer/answer-vendor-question/${btoa(questionId)}`, { answer, is_private: isPrivate });
  }
}
