import { IMyOptions } from 'ng-uikit-pro-standard';
import { parse } from 'url';

export const DatePicker: DatePicker = {
    Options: {
        dateFormat: "mm-dd-yyyy",
        closeBtnTxt:  "ok",

    },
    Placeholder: "mm-dd-yyyy",
    ApiFormat: (dt: string) => {
        //mm-dd-yyyy to mm/dd/yyyy
        if (!dt) {
            return "";
        }
        let ar = dt.split("-");

        return ar[0] + "/" + ar[1] + "/" + ar[2];
    },
    ApiToUiFormat: (dt: string) => {
        //yyyy-mm-dd to mm-dd-yyy
        let d = new DateAndTime();
        if (!dt) {
            return d;
        }
        var dtArray = dt.split(" ")[0].split("-");
        var time = "";
        if (dt.split(" ").length == 2) {
            time = dt.split(" ")[1];
        }
        if (time) {
            d.Time = tConvert(time);
        }
        d.Date = dtArray[1] + "-" + dtArray[2] + "-" + dtArray[0];
        return d;
    },
    DeadlineOver: function (deadlineForQuestions) {
      if(deadlineForQuestions!=null){
        let dt = deadlineForQuestions.split('-')
        let d = new Date(parseInt(dt[0]), (parseInt(dt[1])-1), parseInt(dt[2]));
        let v = new Date();
        if (d > new Date()) {
          return false;
        }
        return true;
      }
    }
};

interface DatePicker {
    Options: IMyOptions;
    Placeholder: string;
    ApiFormat(dt: string): string;
    ApiToUiFormat(dt: string): DateAndTime;
    DeadlineOver(deadlineForQuestions: string): boolean
}

class DateAndTime {
    Date: string;
    Time: string;
}

function tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
        time = time.slice(1);  // Remove full string match value
        time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
        time[0] = +time[0] % 12 || 12; // Adjust hours
        if (time[0] < 9) {
            time[0] = "0" + time[0];
        }
        time[3] = "";//Remove Seconds
    }
    return time.join(''); // return adjusted time or original string
}

export const RFPWizardStepUrlSegment = {
    ProjectSetup: "setup",
    Template: "template",
    BGInfo: "bginfo",
    Appedix: "appendix",
    Questions: "questions",
    Worksheet: "worksheet",
    Supplier: "supplier"
}
