import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';

@Injectable({ providedIn: 'root' })
export class CompaniesService {
  constructor(private http: HttpClient) { }

  getCompany(company_id) {
    return this.http.get(`${environment.apiUrl}/company/${btoa(company_id)}/contact`);
  }
  getAllComaniesList() {
    return this.http.get(`${environment.apiUrl}/companies`);
  }
  getAllInvitationList() {
    return this.http.get(`${environment.apiUrl}/companies/invitations`);
  }
  getAllContactList(company_id) {
    return this.http.get(`${environment.apiUrl}/company/${btoa(company_id)}/contact`);
    //  return this.http.get(`${environment.apiUrl}/companies/${btoa(company_id)}/contacts`);
  }
  updateCompanyProfile(frm) {
    let setupObj = {
      name : frm.companyname,
      description: frm.description,
      address: frm.address,
      pincode: frm.pincode,
      phone: frm.phoneno,
      website: frm.websites,
      companySize: frm.companysize
    }
    return this.http.put(`${environment.apiUrl}/companydetails/update`, setupObj);
  }
  /**
   * Status can be - pending | accepted | rejected
   * @param company_id
   * @param status
   */
  getInvitedVendorByStatus(company_id, status) {
    return this.http.get(`${environment.apiUrl}/companies/rfp/vendor/status/${btoa(company_id)}?status=${status}`)
  }

}
