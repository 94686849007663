import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UserService, CommonService, AuthenticationService } from '../_services';
import { ApiResponse } from '../_models/response';
import { CriteriaService } from '../_services/criteria.service';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { DatePicker } from '../const';
import {Router} from '@angular/router';
import { MdbTableDirective, MDBModalService, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-evaluation-creteria',
  templateUrl: './evaluation-creteria.component.html',
  styleUrls: ['./evaluation-creteria.component.scss']
})
export class EvaluationCreteriaComponent implements OnInit {
	@ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
 	@ViewChild("EvaluationCriteriaReassignModal", {static: true}) EvaluationCriteriaReassignModal: any;
   dataSource: any = [];
	serverError = "";
  	deleteErrorMsg = "";
  	AddCriteriaMsg = "";
    DatePicker = DatePicker;
    criteriaId = 0;
    getCriteriaForReassign: Array<any>;
    countcriteria = [];
    public delIndex = 0;
  constructor(
  	private userservice: UserService,
    private criteriaservice: CriteriaService,
    private modalService: MDBModalService,
    private auth: AuthenticationService,
    public commonservice: CommonService,
    private router: Router,
    private cdRef: ChangeDetectorRef,
  ) { }
  modules = {

      toolbar: [
        ['bold', 'italic', 'underline'],
        ['blockquote', 'code-block'],
        [{ 'header': 1 }, { 'header': 2 }],               // custom button values
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
        [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
        [{ 'direction': 'rtl' }],                         // text direction
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }],
        ['clean']
      ]
    };
    AddCriteria: FormGroup;
    editCriteriaFormGroup: FormGroup;
    previous: string;
    criteriaEditFormError = "";
     criteriaAddFormError = "";
     reassignCriteriaFormGroup: FormGroup ;

    ngAfterViewInit() {
      this.mdbTablePagination.setMaxVisibleItemsNumberTo(5);

      this.mdbTablePagination.calculateFirstItemIndex();
      this.mdbTablePagination.calculateLastItemIndex();
      this.cdRef.detectChanges();
    }
  ngOnInit() {
    this.criteriaservice.getCreteriaByUser( 1 ).subscribe((u: ApiResponse) => {
      if (u.code == 200) {

        u.data[0].forEach((d, i) => {
          this.dataSource.push(d);
        })
        this.mdbTable.setDataSource(this.dataSource);
        this.dataSource = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      } else {
        this.serverError = u.message;
      }
    }, err => {
      this.serverError = err;
    })
    this.AddCriteria = new FormGroup({
      title: new FormControl('', [Validators.required]),
      description: new FormControl('', [Validators.required]),

    });
    this.editCriteriaFormGroup = new FormGroup({
          title: new FormControl('', [Validators.required]),
          description: new FormControl('', [Validators.required]),
    });
    this.reassignCriteriaFormGroup = new FormGroup({
          new_criteria_id: new FormControl('', [Validators.required]),

    });
    }


    getallCriteriasByUser(){
      this.criteriaservice.getCreteriaByUser( 1 ).subscribe((u: ApiResponse) => {
        if (u.code == 200) {
          this.dataSource = [];
          u.data[0].forEach((d, i) => {
            this.dataSource.push(d);
          })
          this.mdbTable.setDataSource(this.dataSource);
          this.dataSource = this.mdbTable.getDataSource();
          this.previous = this.mdbTable.getDataSource();
        } else {
          this.serverError = u.message;
        }
      }, err => {
        this.serverError = err;
      })
    }


  	addCriteria(m) {
        setTimeout(() => {
            this.commonservice.SetFormValue(this.AddCriteria, "title", "");
            this.commonservice.SetFormValue(this.AddCriteria, "description", "");

        }, 200)

        m.show();
    }

    CancelCriteria(m){
        m.hide();
    }

    savecriteria(m) {

        this.criteriaAddFormError = "";
        if (this.AddCriteria.valid) {

            this.criteriaservice.postCriteria(
                this.commonservice.GetFormValue(this.AddCriteria, "title"),
                this.commonservice.GetFormValue(this.AddCriteria, "description"),

            ).subscribe((s: ApiResponse) => {
                if (s.code == 200) {
                    this.getallCriteriasByUser();
                    m.hide();
                }
                else {
                    this.criteriaAddFormError = s.message;
                }
            }, err => {
                this.criteriaAddFormError = err;
            })
        } else {

            this.commonservice.MarkAllAllCtrlDirty(this.AddCriteria);
        }
    }

    deletecriteria(du, i) {
      this.deleteErrorMsg = "";
      this.delIndex = 0;
      this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
        if (result) {
          //Yes
          this.criteriaservice.deletecriteria(du.id).subscribe((b: ApiResponse) => {
            if (b.code == 200) {
              let res = b.data;
              if(res.mcqExist || res.rfpuseCr || res.quesuseCr){
                this.countcriteria = [];
                this.countcriteria =res;
                this.criteriaId = du.id;
                this.delIndex = i;
                this.getCriteriaReassign(du.id);
                this.EvaluationCriteriaReassignModal.show();
              }else{
                this.dataSource.splice(i, 1);
                this.mdbTable.setDataSource(this.mdbTable.getDataSource());
                // alert("Criteria Deleted Successfully");
                Swal.fire('', 'Criteria Deleted Successfully.', 'success');
              }
            } else {
              this.deleteErrorMsg = b.message;
            }
          }, err => {
            this.deleteErrorMsg = err;
             var t = setTimeout(function (){
                this.deleteErrorMsg = undefined;

                document.getElementById("deleteErrorMsg").style.display = "none";
              }, 4000);
          })
        }
      });
    }

    selectedCriteria: any;


    editCriteria(i, m){

      setTimeout(() => {

            this.commonservice.SetFormValue(this.editCriteriaFormGroup, "title", this.dataSource[i].title);
            this.commonservice.SetFormValue(this.editCriteriaFormGroup, "description", this.dataSource[i].description);

        }, 200)
        this.criteriaId = this.dataSource[i].id;
        m.show();
    }

    updateCriteria(id,m) {

        this.criteriaEditFormError = "";
        if (this.editCriteriaFormGroup.valid) {
            this.criteriaservice.updateCriteria(
                this.commonservice.GetFormValue(this.editCriteriaFormGroup, "title"),
                this.commonservice.GetFormValue(this.editCriteriaFormGroup, "description"),
                id,

            ).subscribe((s: ApiResponse) => {
                if (s.code == 200) {
                    this.getallCriteriasByUser();
                    m.hide();
                }
                else {
                    this.criteriaEditFormError = s.message;
                }
            }, err => {
                this.criteriaEditFormError = err;
            })
        } else {
            this.commonservice.MarkAllAllCtrlDirty(this.editCriteriaFormGroup);
        }

    }
    getCriteriaReassign(critId){
      this.criteriaservice.getAllCriteriaWithoutCriteriaId(critId).subscribe((t: ApiResponse) => {
        if(t.code == 200){
          let p = [];
          t.data[0].forEach((obj) => {
              p.push({ value: obj.id, label: (obj.title).toUpperCase() });
          });
          this.getCriteriaForReassign = p;
        }
      })
    }

    reassignCriteria(oldcriteria,m){
        this.deleteErrorMsg = "";
      if(this.reassignCriteriaFormGroup.valid){
        let newcriteriaId = this.commonservice.GetFormValue(this.reassignCriteriaFormGroup, "new_criteria_id");
        this.criteriaservice.deletecriteria(oldcriteria,newcriteriaId).subscribe((b: ApiResponse) => {
           if (b.code == 200) {
               m.hide();
               this.dataSource.splice(this.delIndex, 1);
              this.mdbTable.setDataSource(this.mdbTable.getDataSource());

              Swal.fire('', 'Criteria Deleted Successfully.', 'success');
            } else {
              this.deleteErrorMsg = b.message;
            }
          }, err => {
            this.deleteErrorMsg = err;
             var t = setTimeout(function (){
                this.deleteErrorMsg = undefined;

                document.getElementById("deleteErrorMsg").style.display = "none";
              }, 4000);
        })
      }
    }
    CancelReassignCriteria(m){
        m.hide();
    }
}
