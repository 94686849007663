import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatChipInputEvent} from '@angular/material/chips';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {debounceTime, map, startWith, switchMap, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';

@Component({
  selector: 'app-chips-input',
  templateUrl: './chips-input.component.html',
  styleUrls: ['./chips-input.component.scss']
})
export class ChipsInputComponent implements OnInit, OnChanges {

  @Input() values: any[];
  @Input() placeholder: string;
  @Input() getterFn: Function;
  @Input() allowNew: boolean;

  @Output() add = new EventEmitter<any>();
  @Output() remove = new EventEmitter<string>();

  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
  @ViewChild('chipInput', { static: true }) chipInput: ElementRef<HTMLInputElement>;

  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  chipCtrl = new FormControl();
  filteredChips: Observable<any[]>;
  selectedChips = [];
  allChipsResult;
  request: string;

  constructor() { }

  ngOnInit() {
    this.filteredChips = this.chipCtrl.valueChanges.pipe(
      debounceTime(300),
      startWith(null),
      tap((value) => { this.request = value; }),
      switchMap(value => {
        if (value && value.name) return of(this.allChipsResult ? this.allChipsResult : []);
        if (!value && this.allChipsResult) {
          return of(this.allChipsResult);
        } else {
          return this.getterFn(value);
        }
      }),
      tap((res: any) => {
        if (!this.request && res.data) {
          this.allChipsResult = res;
        }
      }),
      map((res: any) => res.data.map(t => ({ id: t.id, name: t.name })))
    );
  }

  ngOnChanges() {
    this.selectedChips = this.values.map(v => ({ id: v.id, name: v.name}));
  }

  addChip(event: MatChipInputEvent): void {
    if (!this.allowNew) return;
    const input = event.input;
    const value = (event.value || '').trim();
    if (value && !this.isChipSelected(value)) {
      this.add.next({ id: null, name: value });
    }
    // Reset the input value
    if (input) {
      input.value = '';
    }
    this.chipCtrl.setValue(null);
  }

  removeChip(chip: any): void {
    this.remove.next(chip);
  }

  onSelect(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    if (!this.isChipSelected(value.name)) {
      this.add.next(value);
    }
    this.chipInput.nativeElement.value = '';
  }

  isChipSelected(chipName: string) {
    return this.selectedChips.find(t => t.name === chipName);
  }

}
