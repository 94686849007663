import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { CommonService, UserService } from "../_services";
import { CriteriaService } from "../_services/criteria.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiResponse } from "../_models/response";
import { McqService } from "../_services/mcq.service";

@Component({
  selector: "app-mcq-add-edit",
  templateUrl: "./mcq-add-edit.component.html",
  styleUrls: ["./mcq-add-edit.component.scss"],
})
export class McqAddEditComponent implements OnInit {
  mcqId = 0;
  IsEdit = false;
  options = [];
  EvalCrOptions: Array<any>;
  constructor(
    public commonservice: CommonService,
    private userService: UserService,
    private activatedRoute: ActivatedRoute,
    private mcqService: McqService,
    private router: Router,
    private criteriaservice: CriteriaService
  ) {}

  AddLists: FormGroup;
  mcqFormError = "";

  ngOnInit() {
    this.AddLists = new FormGroup({
      Name: new FormControl("", [Validators.required]),
      evalCriteria: new FormControl("", [Validators.required]),
      // Descrption: new FormControl('', [Validators.required]),
    });
    this.loadCriterias();
    this.activatedRoute.params.subscribe((param: any) => {
      if (param.Id) {
        this.mcqId = param.Id;

        this.mcqFormError = "";
        this.mcqService.getMcq(this.mcqId).subscribe(
          (r: ApiResponse) => {
            if (r.code == 200) {
              let d = r.data[0];
              if (d) {
                this.IsEdit = true;

                this.commonservice.SetFormValue(this.AddLists, "Name", d.name);
                this.commonservice.SetFormValue(
                  this.AddLists,
                  "evalCriteria",
                  parseInt(d.evaluation_criteria_id)
                );
                // this.commonservice.SetFormValue(this.AddLists, "Descrption", d.description);

                d.mcq_options.forEach((v, i) => {
                  if (v.score != null) {
                    this.options.push({ key: v.mcqOption, score: v.score });
                  } else {
                    this.options.push({ key: v.mcqOption, score: 0 });
                  }
                });
              } else {
                this.IsEdit = false;
                this.mcqId = 0;
                this.mcqFormError = r.message;
              }
            } else {
              this.mcqFormError = r.message;
            }
          },
          (err) => {
            this.mcqFormError = err;
          }
        );
      } else {
      }
    });
  }
  addEmptyOption() {
    this.options.push({ key: "", score: 0 });
  }
  deleteOption(i) {
    this.options.splice(i, 1);
  }

  savelist() {
    // let desc = this.commonservice.GetFormValue(this.AddLists, "Descrption");
    let name = this.commonservice.GetFormValue(this.AddLists, "Name");
    let evalcr = this.commonservice.GetFormValue(this.AddLists, "evalCriteria");
    if (this.AddLists.valid) {
      this.options = this.options || [];

      let apiOptoons = [];
      let apiScores = [];

      if (this.options.length < 2) {
        this.mcqFormError = "At least 2 options required";
        return;
      }

      this.options.forEach((v, i) => {
        if (v.key) {
          apiOptoons.push(v.key);
          apiScores.push(v.score);
        }
      });
      this.mcqFormError = "";
      if (this.IsEdit) {
        //Update
        this.mcqService
          .updateMcq(name, apiOptoons, apiScores, evalcr, this.mcqId)
          .subscribe(
            (u: ApiResponse) => {
              if (u.code == 200) {
                this.router.navigate(["/all-mcq"]);
              } else {
                this.mcqFormError = u.message;
              }
            },
            (err) => {
              this.mcqFormError = err;
            }
          );
      } else {
        //Save
        this.mcqService.postMcq(name, apiScores, apiOptoons, evalcr).subscribe(
          (u: ApiResponse) => {
            if (u.code == 200) {
              this.router.navigate(["/all-mcq"]);
            } else {
              this.mcqFormError = u.message;
            }
          },
          (err) => {
            this.mcqFormError = err;
          }
        );
      }
    } else {
      this.commonservice.MarkAllAllCtrlDirty(this.AddLists);
    }
  }

  loadCriterias() {
    // this.AllCriteriaByCompany[index].checked = true;
    this.criteriaservice.getCreteriaByUser().subscribe((ctr: any) => {
      let cr = [];
      let addon = "";
      ctr.data[0].forEach((obj) => {
        addon = "";

        if (parseInt(obj.is_admin) == 1) {
          addon = " (PRE)";
        }

        cr.push({
          value: obj.id,
          label: obj.title.toUpperCase() + addon,
          weight: "",
          is_admin: obj.is_admin,
        });
      });
      this.EvalCrOptions = cr;
    });
  }
}
