import { Component, Input, ElementRef, OnChanges} from '@angular/core';
@Component({
    selector: 'read-more',
    template: `

        <span class="read-des" [innerHTML]="currentText">
        </span>

            <span style="display:inline-block;"><a [class.hidden]="hideToggle" (click)="toggleView()" class="readmore-link">&nbsp;Read {{isCollapsed? 'more':'less'}}</a></span>
    `
})
export class ReadMoreComponent implements OnChanges {
    @Input() text: string;
    @Input() maxLength: number = 300;
    currentText: string;
    hideToggle: boolean = true;

    public isCollapsed: boolean = true;

    constructor(private elementRef: ElementRef) {

    }
    toggleView() {
        this.isCollapsed = !this.isCollapsed;
        this.determineView();
    }
    determineView() {
        if (!this.text || this.text.length <= this.maxLength) {
            this.currentText = this.text;
            this.isCollapsed = false;
            this.hideToggle = true;
            return;
        }

        this.hideToggle = false;
        if (this.isCollapsed == true) {
            this.currentText = this.text.substring(0, this.maxLength) + "...";
        } else if(this.isCollapsed == false)  {
            this.currentText = this.text;
        }

    }
    ngOnChanges() {
        this.determineView();
    }
}
