import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpEvent
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { ScriptInjectorService } from './ScriptInjector';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private disabledButtons: HTMLButtonElement[] = [];

  constructor(private scriptInjectorService: ScriptInjectorService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.disableButtons(); // Disable buttons when request starts

    return next.handle(req).pipe(
      finalize(() => {
        this.enableButtons(); // Enable buttons when request completes
      })
    );
  }

  private disableButtons(): void {
    const buttons = document.querySelectorAll('button');
    buttons.forEach(button => {
      if (!button.disabled) {
        button.disabled = true;
        this.disabledButtons.push(button as HTMLButtonElement);
      }
    });
  }

  private enableButtons(): void {
    this.disabledButtons.forEach(button => {
      button.disabled = false;
    });
    this.disabledButtons = [];
  }
}