import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthenticationService } from '../../_services';
import { FormBuilder, Validators } from '@angular/forms';
import { RoleService } from '../../_services/role.service';
import { ApiResponse } from '../../_models/response';
import { debug } from 'util';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  registerForm: FormGroup;
  submitted = false;
  loading = false;
  returnUrl: string;
  isAccepted=true;
  error = '';
  isSuccess = false;
  successMsg = "";
  serverError = "";
  RoleOptions: Array<any>;
  paymentTermsLink="";
  privacyLink="";
  constructor(
    private formBuilder: FormBuilder,
    private userService: AuthenticationService,
    private roleService: RoleService
  ) {
    this.userService.getPaymentTerms().subscribe((res:any)=>{
      if(res.code==200){
      this.paymentTermsLink=res.data[0].terms;
      this.privacyLink=res.data[0].privacy;
      }
    });
   }

  ngOnInit() {
    //this.roleService.getAllRoles().subscribe((r: ApiResponse) => {
    //  if (r.code == 200) {
    //    let p = [];
    //    r.data[0].forEach((obj) => {
    //      p.push({ value: obj.id, label: (obj.name).toUpperCase() });
    //    });
    //    this.RoleOptions = p;
    //    this.registerForm.controls["RoleId"].setValue(this.RoleOptions[0].value);
    //  } else {
    //    this.serverError = r.message;
    //  }
    //}, err => {
    //  this.serverError = err;
    //})
    this.registerForm = this.formBuilder.group({
      Firstname: new FormControl('', Validators.required),
      Lastname: new FormControl(''),
      Email: new FormControl('', Validators.required),
      Password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      Companyname: new FormControl('',Validators.required),
      //RoleId: new FormControl('', Validators.required)
    }, {
      validator: this.mustMatch('Password', 'confirmPassword')
    });
  }

  mustMatch(controlName: string, matchingControlName: string) {

    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
            this.error = "Passwords Does not Match";
        } else {
          this.error = "";
            matchingControl.setErrors(null);
        }
    }
}
  get r() { return this.registerForm.controls; }
  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    let u1 = {
      fname: this.r.Firstname.value,
      lname: this.r.Lastname.value,
      email: this.r.Email.value,
      password: this.r.Password.value,
      company_name: this.r.Companyname.value,
      type: "is",
      // role_id: this.r.RoleId.value,
    };
    //if (u1.role_id == 4) {
    //  //vendor
    //  u1.type = "vn";
    //}

    this.userService.register(u1).subscribe((res: any) => {
      if (res.code == 200) {

        this.successMsg = res.message;
        this.isSuccess = true;
      }
    }, (err) => {
      this.loading = false;
      this.error = err;
    });
  }

  checkIFAccepted(event){
    if (event.checked) {
      this.isAccepted=false;
    }
    else{
      this.isAccepted=true;
    }
  }

}
