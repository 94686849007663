import {FormGroup, Validators, FormBuilder, FormArray} from '@angular/forms';
import { CommonService, AuthenticationService } from '../../_services';
import { VenderService } from '../../_services/vender.service';
import { ApiResponse } from '../../_models/response';
import { Router, ActivatedRoute } from '@angular/router';
import {Component, OnInit, ChangeDetectorRef, ViewChild} from '@angular/core';

@Component({
  selector: 'app-add-edit-vendorgroup',
  templateUrl: './add-edit-vendorgroup.component.html',
  styleUrls: ['./add-edit-vendorgroup.component.scss']
})
export class AddEditVendorgroupComponent implements OnInit {

  @ViewChild('contactModal', {static: true}) contactModal: any;

  IsEdit = false;

  groupId = 0;

  addVendorGroup: FormGroup;

  newContact: FormGroup;

  vendorFormError = '';

  states = [];

  tags = [];

  categories = [];

  tagGetterFn: Function;
  categoryGetterFn: Function;

  constructor(
    public commonservice: CommonService,
    private venderService: VenderService,
    private cdRef: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() {
    this.tagGetterFn = this.venderService.getVendorTags.bind(this.commonservice);
    this.categoryGetterFn = this.venderService.getVendorCategories.bind(this.commonservice);
    this.initForm();
    this.newContact = this.createNewContact();
    this.activatedRoute.params.subscribe((param: any) => {
      if (param.Id) {
        this.groupId = param.Id;

        this.vendorFormError = '';
        this.venderService.getVendorGroup(this.groupId).subscribe((r: ApiResponse) => {
          if (r.code === 200) {
            const d = r.data[0];
            if (d) {
              console.log(d);
              this.IsEdit = true;

              this.initForm(d);

            } else {
              this.IsEdit = false;
              this.groupId = 0;
              this.vendorFormError = r.message;
            }
          } else {
            this.vendorFormError = r.message;
          }
        }, err => {
          this.vendorFormError = err;
        });
      }
    });

    this.commonservice.getStates()
      .subscribe((res: any) => {
        this.states = res.states.map(state => ({value: state.id, label: state.state}));
      }, (err) => {
        console.log(err);
      });
  }

  initForm(vendor?: any) {
    this.addVendorGroup = this.fb.group({
      group_name: [vendor && vendor.group_name || '', Validators.required],
      description: [vendor && vendor.description || '', Validators.required],
      state_id: [vendor && vendor.state_id || '', Validators.required],
      city: [vendor && vendor.city || '', Validators.required],
      zip: [vendor && vendor.zip || '', Validators.required],
      contacts: this.fb.array([]),
    });
    if (vendor && vendor.contacts && vendor.contacts.length) {
      const contacts = this.addVendorGroup.get('contacts') as FormArray;
      vendor.contacts.forEach(c => {
        contacts.push(this.createNewContact(c));
      });
    }
    if (vendor && vendor.tags) {
      this.tags = vendor.tags;
    }
    if (vendor && vendor.categories) {
      this.categories = vendor.categories;
    }
  }

  addContact() {
    const contacts = this.addVendorGroup.get('contacts') as FormArray;
    contacts.push(this.newContact);
    this.newContact = this.createNewContact();
    this.contactModal.hide();
  }

  deleteContact(i: number) {
    const contacts = this.addVendorGroup.get('contacts') as FormArray;
    contacts.removeAt(i);
  }

  createNewContact(contact?: any) {
    return this.fb.group({
      id: [contact && contact.id || null],
      fName: [contact && contact.fname || '', Validators.required],
      lName: [contact && contact.lastname || '', Validators.required],
      // phone: [contact && contact.phone || '', Validators.required],
      phone: [contact && contact.phone],
      email: [contact && contact.email || '', Validators.compose([Validators.required, Validators.email])]
    });
  }

  savevendorgroup() {
    if (this.isValid()) {
      this.vendorFormError = '';
      if (this.IsEdit) {
        //Update
        const data = Object.assign({}, this.addVendorGroup.value, { tags: this.tags, categories: this.categories });
        this.venderService.updatevendorgroup(data, this.groupId).subscribe((u: ApiResponse) => {
          if (u.code === 200) {
            this.router.navigate(['/all-supplier-company']);
          } else {
            this.vendorFormError = u.message;
          }
        }, err => {
          this.vendorFormError = err;
        });
      } else {
        //Save
        const data = Object.assign({}, this.addVendorGroup.value, { tags: this.tags, categories: this.categories });
        this.venderService.postvendorgroup(data).subscribe((u: ApiResponse) => {
          if (u.code === 200) {
            this.router.navigate(['/all-supplier-company']);
          } else {
            this.vendorFormError = u.message;
          }
        }, err => {
          this.vendorFormError = err;
        });
      }

    } else {
      this.commonservice.MarkAllAllCtrlDirty(this.addVendorGroup);
    }
  }

  onAddTag(tag: any) {
    this.tags = [tag, ...this.tags];
  }

  onRemoveTag(tag: any) {
    this.tags = this.tags.filter(t => !(t.id === tag.id && t.name === tag.name));
  }

  onAddCategory(category: any) {
    this.categories = [category, ...this.categories];
  }

  onRemoveCategory(category: any) {
    this.categories = this.categories.filter(c => !(c.id === category.id && c.name === category.name));
  }

  isValid() {
    return this.addVendorGroup.valid;
  }

}
