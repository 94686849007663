import {AfterViewInit, Component, HostListener} from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { environment } from '../environments/environment';
import { AuthenticationService, CommonService } from './_services';
import { User } from './_models';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements AfterViewInit {
    year = (new Date()).getFullYear();
    currentUser: User;
    showHead: boolean = false;
    logoImage: string;
    innerWidth: number;
    breakpoint = 1400;
    imageUrl = environment.serverUrl;

  notifications = [];

    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public commonSer: CommonService
    ) {
        this.authenticationService.currentUser.subscribe(x => {
            this.currentUser = x;

            if(this.currentUser){
                if (this.currentUser.hasOwnProperty('image')) {
                    if(this.currentUser.image != ''){
                        this.logoImage = environment.serverUrl+ this.currentUser.image + '?' + Math.random();
                    }

                }
            }

        });
        this.commonSer.changeLogo.subscribe((value) => {
            console.log(value);
            this.logoImage = this.imageUrl+''+value;
        });

      this.commonSer.newQuestionSubject.subscribe((res: any) => {
        this.notifications.push({
          id: res.id,
          text: `${res.asked_by.fName} ${res.asked_by.lName} asked you a question about ${res.rfp.name}. Click to view questions`,
          link: `/rfp/${res.rfp.id}/questions`,
        });
      });

      this.commonSer.questionsViewed.subscribe((questionsIds: string[]) => {
        this.notifications = this.notifications.filter(n => questionsIds.indexOf(n.id) === -1);
      });
    }

    ngAfterViewInit() {
      this.innerWidth = window.innerWidth;
    }

    @HostListener('window:resize', ['$event'])

    onResize() {
        this.innerWidth = window.innerWidth;
    }

    logout() {
        this.authenticationService.logout();
        this.router.navigate(['/login']);
    }
    showHideSideNav(sidenav) {
        if (sidenav.shown && this.innerWidth < this.breakpoint) {
          sidenav.hide();
        }
    }

  hasNotifications() {
    return this.notifications && this.notifications.length;
  }

}
