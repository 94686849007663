import * as jexcel from "jexcel-pro";
import { operation, operationFlags, ExcelSheet } from "./vendorExcelSheet";
import {environment} from '../../environments/environment';
export class VendorExcelDocHandler {
    private sheets:ExcelSheet[] = [];
    doc: any;
    // if true readonly fields will be shows editable, but its readonly state will still be preserved in obj.getColumnOptions(x).options.readOnly = true;
    isEditable:boolean;
    element:any;
    notifierService: any;
    rfpService: any;
    public selectedTabIndx = 0;
    public selectedRow = 0;
    public selectedColumn = 0;
    public selectedCellName = '';

    public updateArr = [];
    public tabMapping = <any>{};
    public excel:any;

    constructor(isEditable){
      this.isEditable = isEditable;
    }
    getSheetCount(){
      return this.sheets.length;
    }

    addNotifierService(notifierService){
      this.notifierService = notifierService;
    }

    getAllSheets():ExcelSheet[]{
      return this.sheets;
    }
    getDocInstance(){
      return this.sheets;
    }

    putChanges(element: any){
      this.element = element;
      this.doc = element.jexcel;
    }

    syncHandler(element: any){
      this.element = element;
      this.doc = element.jexcel;
      setTimeout(()=>{
        this.setupSheet();
      }, 500);
    }

    setupSheet(){

      this.sheets = [];
      let worksheetState:any = this.doc;
      if(worksheetState instanceof Array){

        for(let x1 of worksheetState){

          let v = new ExcelSheet(this.rfpService, this.notifierService);
          v.data = x1.getData();
          v.worksheetName = x1.getConfig().worksheetName;
          v.sheetId = x1.getConfig().worksheetId;
          // loop over columns and get options and make headers
          for(let i = 0; i< x1.colgroup.length; i++){
            let header = x1.getColumnOptions(i);
            header = new ExcelSheetHeader(header.title, header.options.readOnly, header.options.hidden);
            v.columns.push(header);
          }
          this.sheets.push(v);
        }
      }else{
        let v = new ExcelSheet(this.rfpService, this.notifierService);
        v.data = worksheetState.getData();
        v.sheetId = worksheetState.getConfig().worksheetId;
        // loop over columns and get options and make headers
        for(let i = 0; i< worksheetState.colgroup.length; i++){
          let header= worksheetState.getColumnOptions(i)
          header = new ExcelSheetHeader(header.title, header.options.readOnly, header.options.hidden);
          v.columns.push(header);
        }
        this.sheets.push(v);
      }
    }

    // gets Notifier Service which notify when tab changes
    addCommonServices(notifierService, rfpSetupService){
      this.notifierService = notifierService;
      this.rfpService = rfpSetupService;
    }

    genSheetOptions(sheetName, obj, isEditable, questionarieDisplayStatus, sheetIndex= null, ){

      let rows = [[]];
      let shName = "";
      let cols = [];
      let notifier: any
      if(this.notifierService){
        notifier = this.notifierService;
      }else{
        console.log("NotifierService was not Assigned");
      }

      // when is already existing sheet
      if(obj!=null){
        cols=obj;
      }

      shName = sheetName ? sheetName : 'Section 1';
      var _this = this;
      const columns = cols.map(c => {
        return c.mask ? Object.assign(c, { disabledMaskOnEdition: true }) : c;
      });
      return {
        worksheetName: shName,
        license: environment.jexcelKey,
        data: rows,
        columns,
        toolbar: false,
        updateTable: function(el, cell, x, y, source, value, label) {
            cell.classList.add('readonly');
        },
        tabs: false,
        minDimensions: [cols.length, 10],
        onopenworksheet:function(element, instance, worksheetNumber) {
          notifier.setWorksheetSelectedTabIndex(worksheetNumber);
        },
        contextMenu: function(obj, x, y, e) {
          return [];
        }
      }
    }


  }
  export class ExcelSheetHeader{
    name: string;
    readOnly: boolean = false;
    hidden: boolean = false;
    constructor (name, readOnly, hidden){
      this.name = name;
      this.readOnly = readOnly;
      this.hidden = hidden;
    }
  }
