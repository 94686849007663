import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { CommonService, AuthenticationService } from '../_services';
import { VenderService } from '../_services/vender.service';
import { ApiResponse } from '../_models/response';
import { Router, ActivatedRoute } from '@angular/router';
import { MdbTableDirective, MDBModalService,MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { DatePicker } from '../const';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';
import { Subscription } from 'rxjs';

import Swal from 'sweetalert2';
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})
export class VendorListComponent implements OnInit {
@ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
@ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  dataSource: any = [];
  sourceEmpty = false;
  companyData: any;
  serverError = "";
  searchText: string = '';
  previous: string;
  DatePicker = DatePicker;
  deleteErrorMsg = "";
  groupId = 0;
  subscription: Subscription;
  constructor(
  	public commonservice: CommonService,
    private venderService: VenderService,
   	private modalService: MDBModalService,
    private activatedRoute: ActivatedRoute,
    private auth: AuthenticationService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  ngOnInit() {
  	this.activatedRoute.params.subscribe((param: any) => {
  		if (param.Id) {
        this.groupId = param.Id;
		  	this.venderService.getCompanyVendors(this.groupId).subscribe((u: ApiResponse) => {
			      if (u.code == 200) {
					  this.companyData = u.data.company;
						let groupname = this.companyData.group_name;
						localStorage.setItem('selectedSupplier' , groupname);

			        let allGroups = u.data.vendors.forEach((d, i) => {
			            this.dataSource.push(d);

			        })
			 		 if(Object.keys(this.dataSource).length == 0)
				    {
				    	this.sourceEmpty = true;
				    }
			        this.mdbTable.setDataSource(this.dataSource);
			        this.dataSource = this.mdbTable.getDataSource();
			        this.previous = this.mdbTable.getDataSource();
			      } else {
			        this.serverError = u.message;
			      }
		    }, err => {
		      this.serverError = err;
		    })
	     }
    });

  }

  deletevendorforgroup(du, i) {
    	this.deleteErrorMsg = "";
	    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
	      if (result) {
	        //Yes
	        this.venderService.deletevendorforgroup(du.id).subscribe((b: ApiResponse) => {
	          if (b.code == 200) {

	              this.dataSource.splice(i, 1);
	              this.mdbTable.setDataSource(this.mdbTable.getDataSource());

                Swal.fire('', 'Contact Deleted Successfully.', 'success');

	          } else {
	            this.deleteErrorMsg = b.message;
	          }
	        }, err => {
	          this.deleteErrorMsg = err;
	           var t = setTimeout(function (){
	              this.deleteErrorMsg = undefined;

	              document.getElementById("deleteErrorMsg").style.display = "none";
	            }, 4000);
	        })
	      }
	    });
  	}
    searchItems() {
      const prev = this.mdbTable.getDataSource();

      if (!this.searchText) {
        this.mdbTable.setDataSource(this.previous);
        this.dataSource = this.mdbTable.getDataSource();
      }

      if (this.searchText) {
        this.dataSource = this.mdbTable.searchLocalDataBy(this.searchText);
        this.mdbTable.setDataSource(prev);
      }
	}

}
