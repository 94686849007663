import { FormGroup, FormControl, Validators } from '@angular/forms';
import { CommonService, AuthenticationService } from '../_services';
import { VenderService } from '../_services/vender.service';
import { ApiResponse } from '../_models/response';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit,Input, Output, EventEmitter,ViewChild } from '@angular/core';
import { MdbTableDirective, MDBModalService } from 'ng-uikit-pro-standard';
import { DatePicker } from '../const';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.scss']
})
export class AddVendorComponent implements OnInit {
	@ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  dataSource: any = [];
  serverError = "";
  searchText: string = '';
  previous: string;
  DatePicker = DatePicker;
  deleteErrorMsg = "";
  groupName : string = "";
  public show:boolean = false;
  public buttonName:any = 'Show';

	private _isModal = false;

    @Input()
    set isModal(val: boolean) {
        this._isModal = val;
    }

    get isModal(): boolean { return this._isModal; }

    @Output() onSuccess = new EventEmitter<string>();
	IsEdit = false;
	groupId = 0;
	rowId = 0;
  	constructor(
  		public commonservice: CommonService,
        private venderService: VenderService,
       private modalService: MDBModalService,
        private activatedRoute: ActivatedRoute,
        private auth: AuthenticationService,
        private router: Router
  	) { }

  	AddVendor: FormGroup;
   	Company: Array<any>;
    vendorFormError = "";
    selectedSupplier:any;

  	ngOnInit() {
        this.selectedSupplier = localStorage.getItem('selectedSupplier');

  		this.AddVendor = new FormGroup({
            firstname: new FormControl('', [Validators.required]),
            lastname: new FormControl('', [Validators.required]),
            email: new FormControl('', [Validators.required]),
            // company: new FormControl('',[Validators.required]),
            phoneno: new FormControl(''),
        });

        this.venderService.getAllVendorGroupCompany().subscribe((r: any) => {
            let p = [];
            r.data[0].forEach((obj) => {
                p.push({ value: obj.id, label: (obj.group_name).toUpperCase() });
            });
            this.Company = p;
        });

        this.activatedRoute.params.subscribe((param: any) => {
            if (param.Id) {
                this.groupId = param.Id;
               
                this.IsEdit = false;
                this.rowId = 0;
                this.vendorFormError = "";
                
            }else if(param.rowId)
            {
            	this.rowId = param.rowId;
            	this.vendorFormError = "";
                this.venderService.getSingleVendorForGroup(this.rowId).subscribe((r: ApiResponse) => {
                    if (r.code == 200) {
                        let d = r.data[0];
                        if (d) {
                         
                            this.IsEdit = true;
                            this.commonservice.SetFormValue(this.AddVendor, "firstname", d.vendor_fname);
                            this.commonservice.SetFormValue(this.AddVendor, "lastname", d.vendor_lname);
                            this.commonservice.SetFormValue(this.AddVendor, "email", d.email);
                            this.commonservice.SetFormValue(this.AddVendor, "phoneno", d.phone);
                        	this.groupId = d.issuer_vendor_group_id;

                            // this.commonservice.SetFormValue(this.AddVendor, "company", d.issuer_vendor_group_id);
                            // this.commonservice.SetFormValue(this.AddVendor, "password", d.password);
                     

                        } else {
                            this.IsEdit = false;
                            this.rowId = 0;
                            this.vendorFormError = r.message;
                        }
                    }
                    else {
                        this.vendorFormError = r.message;
                    }
                }, err => {

                    this.vendorFormError = err;
                })
            
            }
            else {

        
            }
        });
        
  	}

  	savevendor()
  	{  
      // this.groupId  = this.commonservice.GetFormValue(this.AddVendor, 'company');
        if (this.AddVendor.valid) {
            this.vendorFormError = "";
             if (this.IsEdit) {
                //Update
                console.log('savevendor update');
                this.venderService.updateSingleVendorForgroup(this.AddVendor.value, this.rowId).subscribe((u: ApiResponse) => {
                    if (u.code == 200) {
                        console.log('inside 200');
                        this.router.navigate(['/all-suppliers/'+this.groupId]);
                        this.vendorFormError = u.message;
                    } else {
                        this.vendorFormError = u.message;
                    }
                }, err => {
                    this.vendorFormError = err;
                });
            } else { 
                //Save
                console.log('savevendor insert');
                this.venderService.postvendorforgroup(this.AddVendor.value,this.groupId).subscribe((u: ApiResponse) => {
                    if (u.code == 200) {
                        if (this.isModal) {
                            console.log('inside isModel if');
                            this.onSuccess.emit(u.message);
                            this.vendorFormError = u.message;
                        } else {
                            console.log('inside isModel else');
                            if( u.message == 'Vendor created successfully' ) {
                                
                                this.router.navigate(['/all-suppliers/'+this.groupId]);
                            } else {

                                this.onSuccess.emit(u.message);
                                this.vendorFormError = u.message;
                            }
                        }
                    } else {
                        console.log('inside 200 else');
                        this.vendorFormError = u.message;
                        this.onSuccess.emit(u.message);
                    }
                }, err => {
                    console.log('inside err');
                    this.vendorFormError = err;
                });
           	}
           	
        } else {
            this.commonservice.MarkAllAllCtrlDirty(this.AddVendor);
        }
  	}
  	deletevendorforgroup(du, i) {
    	this.deleteErrorMsg = "";
	    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
	      if (result) {
	        //Yes
	        this.venderService.deletevendorforgroup(du.id).subscribe((b: ApiResponse) => {
	          if (b.code == 200) {
	            
	              this.dataSource.splice(i, 1);
	              this.mdbTable.setDataSource(this.mdbTable.getDataSource());
	              alert("Vendor Deleted Successfully");
	          } else {
	            this.deleteErrorMsg = b.message;
	          }
	        }, err => {
	          this.deleteErrorMsg = err;
	           var t = setTimeout(function (){
	              this.deleteErrorMsg = undefined;
	              
	              document.getElementById("deleteErrorMsg").style.display = "none";
	            }, 4000);
	        })
	      
	      }
	    });

  	}
      ngOnDestroy(){
       
      }
}
