import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule, Router } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './public/login/login.component';
import { ElementRefDirectiveDirective } from './_shared/element-ref-directive.directive';
import { JwtInterceptor, ErrorInterceptor } from './_helpers';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ScrollToModule } from 'ng2-scroll-to-el';
import { RegisterComponent } from './public/register/register.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ReportsComponent } from './reports/reports.component';
import { RfpListComponent } from './rfp-list/rfp-list.component';
import { RfpDetailComponent } from './rfp-detail/rfp-detail.component';
import { ForgotPasswordComponent } from './public/forgot-password/forgot-password.component';
import { DragulaModule } from 'ng2-dragula';
import { LoaderComponent } from './_shared/loader/loader.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { UserListComponent } from './user-list/user-list.component';
import { UserAddEditComponent } from './user-add-edit/user-add-edit.component';
import { McqListComponent } from './mcq-list/mcq-list.component';
import { McqAddEditComponent } from './mcq-add-edit/mcq-add-edit.component';
import { ConfirmComponent } from './_shared/confirm/confirm.component';
import { MyTasksComponent } from './my-tasks/my-tasks.component';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyOverviewComponent } from './company-overview/company-overview.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { MyProfileEditComponent } from './my-profile-edit/my-profile-edit.component';
import { MyCompanyEditComponent } from './my-company-edit/my-company-edit.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { VerifiedComponent } from './public/verified/verified.component';
import { VerifyComponent } from './public/verify/verify.component';
import { RfpResultComponent } from './rfp-result/rfp-result.component';
import { AddUserModalComponent } from './_shared/add-user-modal/add-user-modal.component';
import { ReassignTaskComponent } from './reassign-task/reassign-task.component';
import { QuillModule, QuillEditorComponent  } from 'ngx-quill';
import { RfpInviteComponent } from './rfp-invite/rfp-invite.component'
import { VendorGroupComponent } from './vendor-group/vendor-group.component';
import { AddEditVendorgroupComponent } from './vendor-group/add-edit-vendorgroup/add-edit-vendorgroup.component';
import { AddVendorComponent } from './add-vendor/add-vendor.component';
import { VendorListComponent } from './vendor-list/vendor-list.component';
import { VendorCompanyOverviewComponent } from './vendor-company-overview/vendor-company-overview.component';
import { ReadMoreModule } from './_shared/read-more/read-more.module';
import { EvaluationCreteriaComponent } from './evaluation-creteria/evaluation-creteria.component';
import { MatIconModule } from '@angular/material/icon';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule, MatInputModule } from '@angular/material';
import { CriteriaAddEditComponent } from './criteria-add-edit/criteria-add-edit.component';
import { PhonePipe } from './phone.pipe';
import {CommonModule, CurrencyPipe} from '@angular/common';
import { WorksheetPreviewComponent } from './worksheet-preview/worksheet-preview.component';
import { McqConfirmComponent } from './_shared/mcq-confirm/mcq-confirm.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AngularCropperjsModule, CropperComponent } from 'angular-cropperjs';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import {MatTreeModule} from '@angular/material/tree';
import { StripePaymentModule } from './_shared/stripe-payment/stripe-payment.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { ImpersonateComponent } from './public/impersonate/impersonate.component';
import { ChipsInputComponent } from './chips-input/chips-input.component';
import { RfpQuestionsComponent } from './rfp-questions/rfp-questions.component';
import { ToastModule } from 'ng-uikit-pro-standard';
import {PricePipe} from './price.pipe';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { AuthClientConfig, AuthModule, AuthHttpInterceptor, AuthService } from '@auth0/auth0-angular';
import { HttpClientModule } from "@angular/common/http";
import { LoggerModule, NgxLoggerLevel, NGXLogger, NGXMapperService, NGXLoggerHttpService } from "ngx-logger";
import { LoaderInterceptor } from './_services/LoaderInterceptor ';

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
// const maskConfigFunction: () => Partial<IConfig> = () => {
//     return {
//       validation: false,
//     };
//   };
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ImpersonateComponent,
        ElementRefDirectiveDirective,
        RegisterComponent,
        DashboardComponent,
        ReportsComponent,
        RfpListComponent,
        RfpDetailComponent,
        ForgotPasswordComponent,
        LoaderComponent,
        ChangePasswordComponent, UserListComponent, UserAddEditComponent, McqListComponent,
        McqAddEditComponent, ConfirmComponent, MyTasksComponent,ImageCropperComponent,
        CompaniesComponent, CompanyOverviewComponent, MyProfileComponent, MyProfileEditComponent,
        MyCompanyEditComponent,
        ResetPasswordComponent,
        VerifiedComponent,
        VerifyComponent,
        RfpResultComponent,
        AddUserModalComponent,
        ReassignTaskComponent,
        RfpInviteComponent,
        VendorGroupComponent,
        AddEditVendorgroupComponent,
        AddVendorComponent,
        VendorListComponent,
        VendorCompanyOverviewComponent,

        EvaluationCreteriaComponent,
        CriteriaAddEditComponent,
        PhonePipe,
      PricePipe,
        WorksheetPreviewComponent,
        McqConfirmComponent,
        NotFoundComponent,
        ChipsInputComponent,
        RfpQuestionsComponent
    ],
    imports: [
      AuthModule.forRoot({
        domain: 'dev-5pnikuu41bak4kmc.us.auth0.com',
        clientId: 'CBTmAYN6k7XsCGCPIXVzldh4W5i9IkLc',
        authorizationParams: {
          redirect_uri: window.location.origin,
        },
      }),
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        ReadMoreModule,
        AppRoutingModule,
        RouterModule,
        FormsModule,
        AngularCropperjsModule,
        ReactiveFormsModule,
        MDBBootstrapModulesPro.forRoot(),
        DragulaModule.forRoot(),
        ScrollToModule.forRoot(),
        AngularMultiSelectModule,
        QuillModule.forRoot(),
        MatIconModule,
        MatChipsModule,
        MatInputModule,
        MatAutocompleteModule,
        MatFormFieldModule,
        ImageCropperModule,
        NgxMaskModule.forRoot(),
        ToastModule.forRoot(),
        MatTreeModule,
        StripePaymentModule,
        RecaptchaModule,
        RecaptchaFormsModule,
        HttpClientModule,
        LoggerModule.forRoot({
          serverLoggingUrl: `${environment.serverUrl}`+'api/logs/save',
          level: NgxLoggerLevel.DEBUG,
          serverLogLevel: NgxLoggerLevel.ERROR
        }),
        
    ],
    providers: [
        MDBSpinningPreloader,
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        CurrencyPipe,
        CropperComponent,
        {
          provide: RECAPTCHA_SETTINGS,
          useValue: {
            siteKey: environment.recaptcha.siteKey,
          } as RecaptchaSettings,
        },
        NGXLogger,
        NGXMapperService,
        NGXLoggerHttpService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi: true
        }
    ],
    exports: [
      MatInputModule, ReadMoreModule
    ],
    bootstrap: [AppComponent],
    entryComponents: [ConfirmComponent, AddUserModalComponent, McqConfirmComponent, ImageCropperComponent, CropperComponent]
})
export class AppModule { }
