import {Component, ElementRef, AfterViewInit, ViewChild, ChangeDetectorRef, OnInit} from '@angular/core';
import { MdbTableDirective, MDBModalService,} from 'ng-uikit-pro-standard';
import { FormControl } from '@angular/forms';
import {RfpSetupService, AuthenticationService, CommonService} from '../_services';
import { DatePicker } from '../const';
import { ApiResponse } from '../_models/response';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import {debounceTime, map, startWith} from 'rxjs/operators';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';
import {Router} from '@angular/router';
import * as _ from 'lodash';
import { NGXLogger } from "ngx-logger";

@Component({
    selector: 'app-rfp-list',
    templateUrl: './rfp-list.component.html',
    styleUrls: ['./rfp-list.component.scss']
})
export class RfpListComponent implements OnInit, AfterViewInit {
    @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
    @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;
    @ViewChild('tagInput', { static: true }) tagInput: ElementRef<HTMLInputElement>;

    dataSource: any = [];
    serverError = '';

  DatePicker = DatePicker;
  IsDataExist = true;

    // search by text
  searchCtrl = new FormControl('');

    // search by author
    authorFilterOptions = [
      {value: 'all', label: 'All RFx'},
      {value: 'me', label: 'My RFx'},
      {value: 'other', label: 'Other users RFx' }
    ];
    author: string;

    // search by tags
    selectable = true;
    removable = true;
    separatorKeysCodes: number[] = [ENTER, COMMA];
    tagCtrl = new FormControl();
    filteredTags: Observable<string[]>;
    tags: any = [];
    public AllTag: Array<any>;
    showAll: Array<any>;
    selectedTagValues: any = [];

    // table
    totalRows = 0;
    currentPage = 1;
    lastPage = 0;
    from = 0;
    to = 0;

    constructor(private cdRef: ChangeDetectorRef,
                private rfpSetupService: RfpSetupService,
                private commonService: CommonService,
                private modalService: MDBModalService,
                private authenticationService: AuthenticationService,
                private router: Router,
                private logger: NGXLogger
    ) {}

    ngOnInit() {
      this.author = this.authorFilterOptions[0].value;
      this.getRfps();
      this.getTags();
      this.logger.error('This is error')
    }

    ngAfterViewInit() {
        this.cdRef.detectChanges();
        this.searchCtrl.valueChanges
          .pipe(debounceTime(1000))
          .subscribe(() => {
            this.currentPage = 0;
            this.getRfps();
          });
    }

    getTags() {
      this.commonService.GetAllTags().subscribe((t: any) => {
        const tag = t.data.map(obj => ({ id: obj.id, name: obj.name }));
        this.AllTag = this.showAll = tag;
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
          startWith(null),
          map((value: string | null) => value ? this._filter(value) : this.showAll.slice())
        );
      });
    }

  getRfps() {
    const currentPage = this.currentPage;
    const createdBy = this.author || this.authorFilterOptions[0].value;
    const searchText = this.searchCtrl.value;
    const searchTags = _.uniq(this.selectedTagValues.map(tag => tag.id));
    this.rfpSetupService.getAllRpf(currentPage, createdBy, searchText, searchTags).subscribe((r: ApiResponse) => {
      if (r.code === 200) {
        if (!r.data || !r.data.rfps) {
          if (!this.hasFilters()) {
            this.IsDataExist = false;
          } else {
            this.from = 0;
            this.to = 0;
            this.totalRows = 0;
            this.dataSource = [];
            this.mdbTable.setDataSource(this.dataSource);
          }
        } else {
          if (!this.hasFilters()) {
            this.IsDataExist = true;
          }
          this.currentPage = r.data.rfps.current_page;
          this.totalRows = r.data.rfps.total;
          this.lastPage = r.data.rfps.last_page;
          this.from = r.data.rfps.from;
          this.to = r.data.rfps.to;

          this.dataSource = r.data.rfps.data.map((d, i) => {
            d.AllowEdit = true;
            if (d.deadlineForQuestions) {
              d.AllowEdit = !DatePicker.DeadlineOver(d.deadlineForQuestions);
            }
            return d;
          });

          this.mdbTable.setDataSource(this.dataSource);
        }
      }
    }, (err) => {
      this.serverError = err;
    });
  }

    addOnBlur(event: FocusEvent) {
        // console.log(event);
        const target: HTMLElement = (!event.relatedTarget ? event.target : event.relatedTarget) as HTMLElement;
        if (!target || target.tagName !== 'MAT-OPTION') {
            const matChipEvent: MatChipInputEvent = { input: this.tagInput.nativeElement, value: this.tagInput.nativeElement.value };
            this.add(matChipEvent);
        }
    }

    //autocomplete
    add(event: MatChipInputEvent): void {
        const input = event.input;
        const value = event.value;

        // Add our tag
        if ((value || '').trim()) {
            const isOptionSelected = this.matAutocomplete.options.some(option => option.selected);
            if (!isOptionSelected) {
                this.tags.push({
                    id: 0,
                    name: value.trim()
                });
            }
        }
        // Reset the input value
        if (input) {
            input.value = '';
        }
        let MatchOption = {
            id: 0,
            name: value.trim()
        }
        this.tagCtrl.setValue(null);
        if ((value || '').trim()) {
            this.onEnterBlur(MatchOption);
        }
    }

    remove(tag: any): void {
        const index = this.tags.indexOf(tag);

        if (index >= 0) {
            this.tags.splice(index, 1);
        }

        for (let i = 0; i < this.selectedTagValues.length; i++) {
            if (this.selectedTagValues[i].name === tag.name) {
                this.selectedTagValues.splice(i, 1);
            }
        }

        this.currentPage = 0;
        if (this.tags.length === 0) {
            this.getTags();
            this.getRfps();
        } else {
           this.getRfps();
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        let searchedTag = [];
        let serval = event.option.value;
        if (!this.selectedTagValues.includes(serval)) {
            this.selectedTagValues.push(serval);
        }
        this.currentPage = 0;
        this.getRfps();

        this.tags.push(event.option.value);
        this.tagInput.nativeElement.value = '';
    }

    onEnterBlur(value: any): void {
        let serval = value;
        if (!this.selectedTagValues.includes(serval)) {
            this.selectedTagValues.push(serval);
        }
        this.currentPage = 0;
        this.getRfps();
    }

    private _filter(value: any): any {
      if (this.AllTag.length > 0) {

        let tag = [];
        this.commonService.GetAllTags(value).subscribe((t: any) => {
          if (t.data.length > 0) {
            t.data.forEach((obj) => {
              tag.push({ id: obj.id, name: obj.name });
            });
            this.AllTag = tag;
            this.filteredTags = this.tagCtrl.valueChanges.pipe(
              startWith(null),
              map((tag: string | null) =>
                tag ? this._filter(tag) : this.AllTag.slice()
              ));
          }
        });
      } else {
        let topTags = this.showAll;
        this.filteredTags = this.tagCtrl.valueChanges.pipe(
          startWith(null),
          map((tag: string | null) =>
            tag ? this._filter(tag) : topTags.slice()
          )
        );
      }
    }

    deleterfx(id, index) {
        this.serverError = '';
        this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
            if (result) {
                this.rfpSetupService.deleteRfx(id).subscribe((r: ApiResponse) => {
                    if (r.code === 200) {
                        this.dataSource.splice(index, 1);
                        this.mdbTable.setDataSource(this.mdbTable.getDataSource());
                    } else {
                        this.serverError = r.message;
                    }
                }, err => {
                    this.serverError = err;
                });
            }
        });
    }

    onAuthorChange(value: string) {
      this.author = value;
      this.currentPage = 0;
      this.getRfps();
    }

    isMineRf(el: any) {
      const currentUser = this.authenticationService.currentUserValue;
      return el.rfpCreator_user_id === currentUser.userid;
    }

    clone(el: any) {
      this.router.navigate(['/create-rfp'], { queryParams: { clone: el.id }});
    }

    hasFilters() {
      return this.author !== 'all' || this.searchCtrl.value || this.selectedTagValues;
    }

  goToFirstPage() {
    this.currentPage = 1;
    this.getRfps();
  }

  goToLastPage() {
      this.currentPage = this.lastPage;
      this.getRfps();
  }

  goToNextPage() {
    this.currentPage++;
    this.getRfps();
  }

  goToPreviousPage() {
    this.currentPage--;
    this.getRfps();
  }

}
