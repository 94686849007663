import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, CommonService, UserService } from '../../_services';
import { ApiResponse } from '../../_models/response';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-verified',
  templateUrl: './verified.component.html',
  styleUrls: ['./verified.component.scss']
})
export class VerifiedComponent implements OnInit {

  MainForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  Rperror = '';
  rplinkSent = false;
  isAccepted=true;
  rplinkSentMsg = '';
  paymentTermsLink="";
  privacyLink="";
  id = '';
  token = '';
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService
  ) {
    this.auth.getPaymentTerms().subscribe((res:any)=>{
      if(res.code==200){
      this.paymentTermsLink=res.data[0].terms;
      this.privacyLink=res.data[0].privacy;
      }
    });
  }

  ngOnInit() {

    this.MainForm = this.formBuilder.group({
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
      firstName: [''],
      lastName: [''],
      company: [''],
    });
    //http://localhost:4200/verified?id=MTA1&token=9ac88f2fe84d44ffcc8da418e2785768
    //amitaspdev@gmail.com
    this.id = this.commonService.GetQueryStringValue("id")
    this.token = this.commonService.GetQueryStringValue('token');
    this.userService.getPublicUser(atob(this.id)).subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        let u = x.data[0];
        this.commonService.SetFormValue(this.MainForm, "firstName", u.fname)
        this.commonService.SetFormValue(this.MainForm, "lastName", u.lname)
        this.commonService.SetFormValue(this.MainForm, "company", u.company.name)

      } else {
        this.Rperror = x.message;
      }
    }, err => {
      this.Rperror = err;
    });
  }
  onSubmit() {
    this.Rperror = "";
    if (this.MainForm.valid) {
      this.loading = true;
      let password = this.commonService.GetFormValue(this.MainForm, 'password');
      let confirmPassword = this.commonService.GetFormValue(this.MainForm, 'confirmPassword');

      this.auth.resetPassword(password, confirmPassword, this.id, this.token).subscribe((x: ApiResponse) => {

        if (x.code == 200) {
          this.rplinkSent = true;
          this.rplinkSentMsg = x.message;
        } else {
          this.Rperror = x.message;
        }
        this.loading = false;
      }, err => {
        this.Rperror = err;
        this.loading = false;
      })
    }
    else {
      this.commonService.MarkAllAllCtrlDirty(this.MainForm);
    }
  }
  checkIFAccepted(event){
    if (event.checked) {
      this.isAccepted=false;
    }
    else{
      this.isAccepted=true;
    }
  }
}
