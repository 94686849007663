import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';
import {CustomHttpParams} from '../_helpers/httpparams';

@Injectable({ providedIn: 'root' })
export class VenderService {

    constructor(private http: HttpClient) { }

    getAllInvitedVendors(rfp_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/invitedvendors`);
    }
    searchVendors(search_email) {
        return this.http.get(`${environment.apiUrl}/searchvendor?email=${search_email}`);
    }
    sendVendorsInvite(rfp_id, emails) {
        return this.http.post(`${environment.apiUrl}/invitevendors`, { rfp_id: rfp_id, email: emails })
    }
    deleteInvitedVendor(rfp_id, vendor_id) {
        return this.http.delete(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/invitedvendor/${btoa(vendor_id)}/delete`)
    }
    restoreDeletedInvitedVendor(vendor_id) {
        return this.http.put(`${environment.apiUrl}/rfp/invitedvendor/${btoa(vendor_id)}/restore`, {})
    }
    getQuestionsAnswers(rfp_id, section_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/section/${btoa(section_id)}/answers`);
    }

    getAllQuestions(rfp_id, section_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/section/${btoa(section_id)}/question`);
    }

    giveMarks(answer_id, points) {
        // return this.http.post(`${environment.apiUrl}/award/pointsto/answer/${btoa(answer_id)}`, { points });
        return this.http.put(`${environment.apiUrl}/rfp/award/pointsto/answer/${btoa(answer_id)}`, { points });
    }
    //{rfp_id}/review/answers/byissuer

    getResultsByRFPId(rfp_id) {
        return this.http.get(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/review/answers/byissuer`);
    }
    // rfp/{rfp_id}/awarded/company/{company_id}
    awardRFP(rfp_id, compIds) {
        return this.http.put(`${environment.apiUrl}/rfp/${btoa(rfp_id)}/awarded/company`, { comp_ids: compIds });
    }

    updatevendorgroup(data, groupId) {
        return this.http.put(`${environment.apiUrl}/issuer/update/vendorgroup/${btoa(groupId)}`, data);
    }
    postvendorgroup(data: any) {
        return this.http.post(`${environment.apiUrl}/issuer/add/vendorgroup`, data);
    }
    getAllVendorGroups(rfpId = null) {
        if (rfpId != null)
            return this.http.get(`${environment.apiUrl}/issuer/vendor/group/${btoa(rfpId)}`);
        else
            return this.http.get(`${environment.apiUrl}/issuer/vendor/group`);
    }
    getVendors(stateId?: string, city?: string, zip?: string, tags?: any[], categories?: any[]) {
      const data = {};
      if (stateId) {
        Object.assign(data, { state_id: stateId });
      }
      if (city) {
        Object.assign(data, { city });
      }
      if (zip) {
        Object.assign(data, { zip });
      }
      if (tags && tags.length) {
        Object.assign(data, { tags });
      }
      if (categories && categories.length) {
        Object.assign(data, { categories });
      }
      return this.http.post(`${environment.apiUrl}/issuer/vendor/group`, data);
    }
    getVendorGroup(groupId) {
        return this.http.get(`${environment.apiUrl}/issuer/group/edit/get/${btoa(groupId)}`);
    }
    deletegroup(groupid) {
        return this.http.delete(`${environment.apiUrl}/issuer/remove/group/${btoa(groupid)}`);
    }
    getAllVendorGroupCompany() {
        return this.http.get(`${environment.apiUrl}/issuer/vendor/group/all`);
    }

    updateSingleVendorForgroup(frm, rowId) {
        let setupObj = {
            fName: frm.firstname,
            lName: frm.lastname,
            phone: frm.phoneno,
            email: frm.email
        }
        return this.http.put(`${environment.apiUrl}/issuer/update/vendor/${btoa(rowId)}`, setupObj);
    }
    postvendorforgroup(frm, groupId) {
        let setupObj = {
            fName: frm.firstname,
            lName: frm.lastname,
            email: frm.email,
            phone: frm.phoneno
        }
        return this.http.post(`${environment.apiUrl}/issuer/add/vendor/${btoa(groupId)}`, setupObj);
    }
    getSingleVendorForGroup(rowId) {
        return this.http.get(`${environment.apiUrl}/issuer/vendor/edit/get/${btoa(rowId)}`);
    }
    deletevendorforgroup(rowId) {
        return this.http.delete(`${environment.apiUrl}/issuer/remove/vendor/${btoa(rowId)}`);
    }
    getCompanyVendors(group_id) {
        return this.http.get(`${environment.apiUrl}/issuer/group/${btoa(group_id)}/vendor`);
    }


    getInvitedVendorByStatus(row_id, status) {
        return this.http.get(`${environment.apiUrl}/companies/rfp/status/vendor/${btoa(row_id)}?status=${status}`)
    }

    getGroupsVendorDetails(row_id) {
        return this.http.get(`${environment.apiUrl}/companies/vendordetails/${btoa(row_id)}`);
    }
    //supplier post upload data
    postSupplierUpload(file) {
        let frm = new FormData();
        frm.append("file", file);
        return this.http.post(`${environment.apiUrl}/validatexls/supplier`, frm);
    }
    saveSupplierXLS(filename) {
        let frm = new FormData();
        frm.append("file_name", filename);
        return this.http.post(`${environment.apiUrl}/savexls/supplier`, frm);
    }
  getVendorTags(value) {
    let url;
    if (value) {
      url = `${environment.apiUrl}/issuer/vendor/tags/${value}`;
    } else {
      url = `${environment.apiUrl}/issuer/vendor/tags`;
    }
    return this.http.get(url, {
      params: new CustomHttpParams(true)
    });
  }
  getVendorCategories(value) {
    let url;
    if (value) {
      url = `${environment.apiUrl}/issuer/vendor/categories/${value}`;
    } else {
      url = `${environment.apiUrl}/issuer/vendor/categories`;
    }
    return this.http.get(url,{
      params: new CustomHttpParams(true)
    });
  }

}
