import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, UserService, CommonService } from '../_services';
import { ApiResponse } from '../_models/response';
import { CompaniesService } from '../_services/companies.service';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { MDBModalService,  } from 'ng-uikit-pro-standard';
import { ImageCropperComponent } from '../image-cropper/image-cropper.component';
import { BrowserDynamicTestingModule } from '@angular/platform-browser-dynamic/testing';
import { environment } from '../../environments/environment';
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @ViewChild("inputFileDP", {static: false})
  inputFileDP:ElementRef;

  @ViewChild("inputFileLogo", {static: false})
  inputFileLogo:ElementRef;
  
  userId = 0;
  user: any = {};
  company: any = {};
  company_id = 0;
  serverError = "";
  selectedUserImage: File = null;
  userImageSrc:string;
  logoImage: string;
  timeStamp : any;
  imageUrl = environment.serverUrl;
  constructor(private auth: AuthenticationService,
    private userService: UserService,
    private companiesService: CompaniesService,
    public commonservice: CommonService,
    private modalService: MDBModalService
  ) { }
  ngOnInit() {
    this.userId = this.auth.currentUserValue.userid;
    this.userService.getUser(this.userId).subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        this.user = u.data[0];
        if (this.user.hasOwnProperty('image')) { 
            this.userImageSrc = environment.serverUrl + this.user.image;
            
        }
        console.log(this.user.role.name);
        this.companiesService.getCompany(this.user.company_id).subscribe((c: ApiResponse) => {
          if (c.code == 200) {
            this.company = c.data[0];
            if (this.company .hasOwnProperty('image')) { 

              this.logoImage = environment.serverUrl + this.company.image + '?' + Math.random();  

            }
          } else {
            this.serverError = c.message;
          }
        }, err => {
          this.serverError = err;
        })
      } else {
        this.serverError = u.message;
      }
    }, err => {
      this.serverError = err;
    });
  }
  logoChange(event){
    const modalOptions = {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: 'modal-lg',
      containerClass: '',
      animated: true,
      data: {
          heading: 'Crop Logo',
          content: { fileEvent: event}
      }
    }
    this.modalService.show(ImageCropperComponent, modalOptions).content.action.subscribe(res=>{
      if(res!=null){
        this.logoImage = res;
        this.inputFileLogo.nativeElement.value = "";
        // Enable Below when api is ready and change API name and data accordingly
        this.userService.uploadLogoImage(res).pipe(
          catchError((err:any)=>{
            this.serverError = err;
            return throwError(err)
          })
        ).subscribe(res=>{
          if(res.code==200){
          
            this.logoImage = environment.serverUrl + res.data[0];
            // this.logoImage = 'http://192.168.18.19:8000/'+ res.data[0];
            this.setLinkPicture(this.logoImage);
            // location.reload(true);
            this.commonservice.changeLogo.next(res.data[0]+ '?' + Math.random());
            console.log("Image uploaded Successfully");
          }else{
            console.log("Error Occured while uploading Image");
          }
        });
      }
    })
  }
  public getLogoPicture() {
    if(this.timeStamp) {
       return this.logoImage + '?' + this.timeStamp;
    }
    return this.logoImage;
  }
  public setLinkPicture(url: string) {
    this.logoImage = url;
    this.timeStamp = Math.random();
}
  dpChange(event){
    this.selectedUserImage = event.target.files.item(0);
    const fd = new FormData();
    fd.append("image", this.selectedUserImage, this.selectedUserImage.name);
    this.userService.uploadDpImage(fd).pipe(
      catchError((err:any)=>{
        this.serverError = err;
        return throwError(err)
      })
    ).subscribe(res=>{
      this.inputFileDP.nativeElement.value = "";
      if(res.code==200){
        
        this.userImageSrc = environment.serverUrl + res.data[0];
        // location.reload(true);
        console.log("Image uploaded Successfully");
      }else{
        console.log("Error Occured while uploading Image");
      }
    });
  }

  getFirstLast(f, l) {
    if (f && l) {
      return (f.substring(0, 1) + l.substring(0, 1)).toUpperCase();
    }
    if (f) {
      return (f.substring(0, 1)).toUpperCase();
    }
    if (l) {
      return (l.substring(0, 1)).toUpperCase();
    }
  }
  getCompanySortName(n: string) {

    if (n) {
      if (n.length > 1) {
        return (n.substring(0, 1) + (n.substring(1, 2))).toUpperCase();
      }
      return (n.substring(0, 1)).toUpperCase();

    }
    return "";
  }

}
