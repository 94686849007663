import { RfpSetupService } from '../_services/rfpSetup.service';
import { WorksheetService } from './worksheet.service';
import { switchMap } from 'rxjs/operators';
export type operation = {
  // will store info about where to make the change and other related values
  opPayload: any;
  opFlag: operationFlags;
}

export  enum operationFlags  {
  SectionTitleRename = 1,
  ColRename   = 2,
  RowAdd      = 3,
  AddNewSheet = 4,
  RowUpdate   = 5,
  RowDelete   = 6,
  Level_2     = 7,
  ColAdd      = 8,
  ColDelete   = 9,
  ColUpdateOperation = 10
}

export namespace operationFlags {
  export function getStrKey(opFlag: operationFlags) {
      switch (opFlag) {
          case operationFlags.AddNewSheet:
            return "new_sheet_title";
          case operationFlags.SectionTitleRename:
            return "sheet_title";
          case operationFlags.ColRename:
            return "col_rename";
          default:
              return "";
      }
  }
}

export class ExcelSheetHeader{
  name: string;
  readOnly: boolean = false;
  hidden: boolean = false;
  constructor (name, readOnly, hidden){
    this.name = name;
    this.readOnly = readOnly;
    this.hidden = hidden;
  }
}

export class ExcelSheet {
  WorksheetService:WorksheetService;
  RfpSetupService: RfpSetupService;
  constructor(WorksheetService: WorksheetService, RfpSetupService: RfpSetupService){
    this.RfpSetupService = RfpSetupService
    this.WorksheetService = WorksheetService;
    // start Interval to push operations to DB after a few seconds

  }

  intervalhook: any;
  worksheetName:string;
  data: [[]];
  columns:ExcelSheetHeader[] = [];
  sheetId: number;//sectionId in our case

  private maxOperationsInBuffer = 4;
  private operationBuffer: operation[] = [] ;
  private static fakeRowId = 0;

  public static newFakeRowId(){
    this.fakeRowId-=1;
    return this.fakeRowId;
  }

  public getOperationByKeyAndOperation(key: any, value:any, op:operationFlags){
    console.log(this.operationBuffer);
    console.log(key, value)
    for(let ope of this.operationBuffer){
      console.log(ope.opPayload[key])
      if(ope.opFlag == op){
        if(ope.opPayload[key] == value){
          return ope;
        }
      }
    }
  }
}
