import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { User } from '../_models';
@Injectable({
  providedIn: 'root'
})
export class CriteriaService {

  constructor(private http: HttpClient) { }

  getCreteriaByUser(flag?) {
    if (!flag) {
      return this.http.get(`${environment.apiUrl}/criterias`);
    } else {
      return this.http.get(`${environment.apiUrl}/criterias?flag=${flag}`);
    }
  }

  allRfpSelectedCriterias(rfp_id) {
    return this.http.get(`${environment.apiUrl}/rfp/selectedcriteria/${btoa(rfp_id)}`);
  }

  postCriteria(title, description) {
    return this.http.post(`${environment.apiUrl}/create/criteria`, {
      title: title,
      description: description,
    });
  }

  deletecriteria(criteriaId, newcriteria = null) {
    if (newcriteria != null) {
      return this.http.delete(`${environment.apiUrl}/criteria/${btoa(criteriaId)}?newcriteria=${btoa(newcriteria)}`);
    } else {
      return this.http.delete(`${environment.apiUrl}/criteria/${btoa(criteriaId)}`);
    }
  }

  updateCriteria(title, description, Id) {
    return this.http.put(`${environment.apiUrl}/update/criteria/${btoa(Id)}`, {
      title: title,
      description: description,
    });
  }

  getAllCriteriaWithoutCriteriaId($criteriaId) {
    return this.http.get(`${environment.apiUrl}/getcriteria/${btoa($criteriaId)}`);
  }

  changeQuestionCriteria(criteriaId, newcriteria, rfpId) {
    return this.http.post(`${environment.apiUrl}/rfp/${btoa(rfpId)}/change/questioncriteria`, {
      oldcriteriaId: criteriaId,
      newcriteriaId: newcriteria,
    });
  }

}
