import { Component, OnInit, ViewChild, ElementRef ,ChangeDetectorRef } from '@angular/core';
import { McqService } from '../_services/mcq.service';
import { MdbTableDirective, MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { DatePicker } from '../const';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiResponse } from '../_models/response';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';

import { MDBModalService } from 'ng-uikit-pro-standard';
import { CommonService ,AuthenticationService} from '../_services';

import * as jexcel from "jexcel-pro";
import {CriteriaService} from '../_services/criteria.service';
import {forkJoin} from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-mcq-list',
  templateUrl: './mcq-list.component.html',
  styleUrls: ['./mcq-list.component.scss']
})
export class McqListComponent implements OnInit {

  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;
  @ViewChild("mcqSpreadsheet", {static: true}) mcqSpreadsheet: any;
  @ViewChild("BrowseXlsFileMCQ", {static: true}) browseMCQXlsFileModel: any;
  @ViewChild("mcqXlsBrowse", {static: true}) mcqXlsBrowse: any;
  dataSource = [];
  serverError = "";
  searchText = '';
  previous: any;
  DatePicker = DatePicker;
  deleteErrorMsg = "";
  detachRFP = [];
  detachMsg = "";
  ismcquploadError =  true;
  mcqUploadFormGroup: FormGroup;
  saveUploadMCQFormGroup: FormGroup;
  public currentUserId: number;
  constructor(
    private mcqService: McqService,
    private criteriaService: CriteriaService,
    private modalService: MDBModalService,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef,
    private authenticationService: AuthenticationService,
  ) { }

  ngAfterViewInit() {
    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    this.showAllMCQ();

    this.mcqUploadFormGroup = new FormGroup({
      mcq_file: new FormControl('', [Validators.required]),
    });
    this.saveUploadMCQFormGroup = new FormGroup({
      mcq_filename: new FormControl('', [Validators.required]),
    });
    let user = this.authenticationService.currentUserValue;
    this.currentUserId = user.userid;
  }

  showAllMCQ() {
    forkJoin(this.mcqService.getAllMcq(), this.criteriaService.getCreteriaByUser()).subscribe((val: [ApiResponse, ApiResponse]) => {
      const m = val[0];
      const u = val[1];
      if (m.code === 200 && u.code === 200) {
        const criterias = _.flatten(u.data);
        m.data[0].forEach((d, i) => {
          d.evaluationCriteria = criterias.find((c: any) => c.id === d.evaluation_criteria_id);
          this.dataSource.push(d);
        });
        this.mdbTable.setDataSource(this.dataSource);
        this.dataSource = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      } else {
        this.serverError = m.message;
      }
    });
  }



  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.dataSource = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.dataSource = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  deletemcqlist(mq, i, detachRFPModal) {
    this.deleteErrorMsg = "";
    this.detachMsg = "";
    this.detachRFP = [];
    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
      if (result) {
        //Yes
        this.mcqService.deleteMcq(mq.id).subscribe((b: ApiResponse) => {
          if (b.code == 200) {
            if (b.data.length > 0) {
              if (b.data[0].length > 0) {
                //Unable to delete
                this.detachRFP = b.data[0];
                this.detachMsg = b.message;
                detachRFPModal.show();
                return;
              }
            }
            this.dataSource.splice(i, 1);
            this.mdbTable.setDataSource(this.mdbTable.getDataSource());

          } else {
            this.deleteErrorMsg = b.message;
          }
        }, err => {
          this.deleteErrorMsg = err;
        })
      }
    });

  }
  ShowBrowseXlsModal(m){
    this.mcqXlsBrowse.nativeElement.innerText = "";
    this.mcqUploadFormGroup.get('mcq_file').setValue(null);
    m.show();
  }
  hideBrowseXlsFile(m){
    m.hide();
  }
  previewXlsMCQ(m){

    // this.validateuploadBackgroundError = "";
    if (this.mcqUploadFormGroup.valid) {

      this.mcqService.postMCQUpload(
          this.commonService.GetFormValue(this.mcqUploadFormGroup, "mcq_file"),

      ).subscribe((s: ApiResponse) => {
          if(s.code == 200){
            console.log(s);
            this.ismcquploadError = false;
              let file_name = s.data.file_name;
              let comments = s.data[0].comments;
              if(Object.keys(comments).length > 0){
                this.ismcquploadError = true;
              }
              // this.isbackuploadError = false;
              console.log(file_name);
              let newdata = [];
                  newdata.push(s.data);
                  console.log(s.data);
              this.saveUploadMCQFormGroup.get('mcq_filename').setValue(file_name);

              let secNo = 2;
              let totalSheets = this.mcqSpreadsheet.nativeElement.length

              if(totalSheets != undefined){
                  secNo = totalSheets+1
              }
              let sheetName = "Section "+secNo;
              jexcel.destroy(this.mcqSpreadsheet.nativeElement);
              this.makeJExcelTabs(sheetName,this.mcqSpreadsheet,newdata);
              m.show();

          }else{
              // this.sharedMethodService.setError(s.message);
              // this.validateuploadBackgroundError = s.message;
          }
      }, err => {
          // this.sharedMethodService.setError(err);
          // this.validateuploadBackgroundError = err;
      });
    }
  }

  makeJExcelTabs(sheets, spreadSheet:ElementRef,newdata){
    let sheetName = sheets;
    let sheet = [];
    if(newdata.length > 0){
        for(let i = 0; i < newdata.length; i++ )
        {
            for(let key of Object.keys(newdata[i]))
            {
              if(newdata[i][key].hasOwnProperty('worksheetName')){
                  sheet.push({
                      worksheetName:newdata[i][key].worksheetName,
                      data: newdata[i][key].data,
                      columns: newdata[i][key].columns,
                      minDimensions: [6, 10],
                      style: newdata[i][key].errors,
                      comments:newdata[i][key].comments,
                  });
              }
            }
        }
    }else{
      sheet = [{
          worksheetName:sheetName,
          data: [[]],
          columns: [[]],
          minDimensions: [6, 10],
      }];
    }

    jexcel(spreadSheet.nativeElement, sheet);
  }
  onMCQFileSelect(event,id:string){
    document.getElementById(id).innerText = "";
    if (event.target.files.length > 0) {
        const suppfile = event.target.files[0];
        this.mcqUploadFormGroup.get('mcq_file').setValue(suppfile);
        document.getElementById(id).innerText = event.target.files[0].name;
        event.target.value ='';
    }
  }
  hidePreviewMCQXlsFile(m){
    jexcel.destroy(this.mcqSpreadsheet.nativeElement);
    this.browseMCQXlsFileModel.hide();
    m.hide();
  }
  mcqSaveXLS(m){

    // this.saveUploadBackgroundError = "";
    if (this.saveUploadMCQFormGroup.valid) {
        this.mcqService.saveMCQXLS(
            this.commonService.GetFormValue(this.saveUploadMCQFormGroup, "mcq_filename")

        ).subscribe((k: ApiResponse) => {
            if(k.code == 200){
               this.showAllMCQ();
                this.hidePreviewMCQXlsFile(m);
            }else{
                // this.sharedMethodService.setError(k.message);
                // this.saveUploadBackgroundError = k.message;
            }

        }, err => {
            // this.sharedMethodService.setError(err);
            // this.saveUploadBackgroundError = err;
        });
    }
  }
}
