// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const serverUrl = "https://staging.sourcecnx.com/";
// const socketUrl = "http://staging.sourcecnx.com:6001";
// const serverUrl = "http://rfx-backend.local/";
// const socketUrl = "http://rfx-backend.local:6001";

const serverUrl = "https://api.sourcecnx.com/";
const socketUrl = "https://api.sourcecnx.com/";

export const environment = {
  production: false,
  serverUrl,
  apiUrl: serverUrl + "api/v1",                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
  recaptcha: {
    siteKey: "6Le3lVwkAAAAAAnKOCLUDTYCKUBBeyybzgTHcXkw", // local
    // siteKey: "6Le8tW0kAAAAACftQ8SRFP0_SZ20_UGpaY4ioP9P", // live
  },
  jexcelKey:
    "ZGE3ZjIzYWI2YjFmYmZiNjAxMjQyY2RmZmE3MmVjMzc0MDcwYjU4MjY2ZTgxYWQ2NzFkNWMyMDBlM2NlMTUzMjJkNjU3ZDA0NjNlODYwODNkYzljNzk0NThiMzdkNjI4MGE2N2IxYWVjNjdmYTU1NGJlYjMzYTExNGU3OTU2NGUsZXlKdVlXMWxJam9pVFdsamFHRmxiQ0JMWlc4aUxDSmtZWFJsSWpveE5qYzFNemd5TkRBd0xDSmtiMjFoYVc0aU9sc2ljMjkxY21ObFkyNTRMbU52YlNJc0lteHZZMkZzYUc5emRDSmRMQ0p3YkdGdUlqb2lOQ0o5",
  socketUrl,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
