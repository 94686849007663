import { Component, OnInit, ViewChild } from '@angular/core';
import { MdbTableDirective } from 'ng-uikit-pro-standard';
import { DatePicker } from '../const';
import { CompaniesService } from '../_services/companies.service';
import { ActivatedRoute } from '@angular/router';
import { ApiResponse } from '../_models/response';
import { debug } from 'util';

@Component({
  selector: 'app-company-overview',
  templateUrl: './company-overview.component.html',
  styleUrls: ['./company-overview.component.scss']
})
export class CompanyOverviewComponent implements OnInit {
  @ViewChild("tableContact", { static: true }) mdbTableContact: MdbTableDirective;
  @ViewChild("tablePending", { static: true }) mdbTablePending: MdbTableDirective;
  @ViewChild("tableRejected", { static: true }) mdbTableRejected: MdbTableDirective;
  @ViewChild("tableInProgress", { static: true }) mdbTableInProgress: MdbTableDirective;
  CompanyId = 0
  company: any = {};
  companyServerError = "";
  contactDataSource: any = [];
  contactServerError = "";
  contactSearchText: string = '';
  contactPrevious: any;

  pendingDataSource: any = [];
  pendingServerError = "";
  pendingSearchText: string = '';
  pendingPrevious: any;

  rejectedDataSource: any = [];
  rejectedServerError = "";
  rejectedSearchText: string = '';
  rejectedPrevious: any;


  inProgressDataSource: any = [];
  inProgressServerError = "";
  inProgressSearchText: string = '';
  inProgressPrevious: any;

  DatePicker = DatePicker;
  constructor(private companiesService: CompaniesService,
    private activatedRoute: ActivatedRoute) {
  }

  ngOnInit() {

    this.activatedRoute.params.subscribe((param: any) => {
      if (param.Id) {
        this.CompanyId = param.Id;
        this.companiesService.getCompany(this.CompanyId).subscribe((c: ApiResponse) => {
          if (c.code == 200) {
            this.company = c.data[0];
          } else {
            this.companyServerError = c.message;
          }
        }, err => {
          this.companyServerError = err;
        })
        this.bindContactList();
        this.bindPendingList();
        this.bindRejectedList();
        this.bindInProgressList();
      }
    });
  }
  
  bindContactList() {
    this.companiesService.getAllContactList(this.CompanyId).subscribe((c: ApiResponse) => {
      if (c.code == 200) {       
        c.data.forEach((d, i) => {
          this.contactDataSource.push(d);
        })
        this.mdbTableContact.setDataSource(this.contactDataSource);
        this.contactDataSource = this.mdbTableContact.getDataSource();
        this.contactPrevious = this.mdbTableContact.getDataSource();
      } else {
        this.contactServerError = c.message;
      }
    }, err => {
      this.contactServerError = err;
    })
  }
  contactSearchItems() {
    const prev = this.mdbTableContact.getDataSource();

    if (!this.contactSearchText) {
      this.mdbTableContact.setDataSource(this.contactPrevious);
      this.contactDataSource = this.mdbTableContact.getDataSource();
    }

    if (this.contactSearchText) {
      this.contactDataSource = this.mdbTableContact.searchLocalDataBy(this.contactSearchText);
      this.mdbTableContact.setDataSource(prev);
    }
  }
  

  bindPendingList() {
    this.companiesService.getInvitedVendorByStatus(this.CompanyId, 'pending').subscribe((c: ApiResponse) => {
      if (c.code == 200) {
        c.data[0].forEach((d, i) => {
          this.pendingDataSource.push(d);
        })
        this.mdbTablePending.setDataSource(this.pendingDataSource);
        this.pendingDataSource = this.mdbTablePending.getDataSource();
        this.pendingPrevious = this.mdbTablePending.getDataSource();
      } else {
        this.pendingServerError = c.message;
      }
    }, err => {
      this.pendingServerError = err;
    })
  }
  pendingSearchItems() {
    const prev = this.mdbTablePending.getDataSource();

    if (!this.pendingSearchText) {
      this.mdbTablePending.setDataSource(this.pendingPrevious);
      this.pendingDataSource = this.mdbTablePending.getDataSource();
    }

    if (this.pendingSearchText) {
      this.pendingDataSource = this.mdbTablePending.searchLocalDataBy(this.pendingSearchText);
      this.mdbTablePending.setDataSource(prev);
    }
  }

  bindRejectedList() {
    this.companiesService.getInvitedVendorByStatus(this.CompanyId, 'rejected').subscribe((c: ApiResponse) => {
      if (c.code == 200) {
        c.data[0].forEach((d, i) => {
          this.rejectedDataSource.push(d);
        })
        this.mdbTableRejected.setDataSource(this.rejectedDataSource);
        this.rejectedDataSource = this.mdbTableRejected.getDataSource();
        this.rejectedPrevious = this.mdbTableRejected.getDataSource();
      } else {
        this.rejectedServerError = c.message;
      }
    }, err => {
      this.rejectedServerError = err;
    })
  }
  rejectedSearchItems() {
    const prev = this.mdbTableRejected.getDataSource();

    if (!this.rejectedSearchText) {
      this.mdbTableRejected.setDataSource(this.rejectedPrevious);
      this.rejectedDataSource = this.mdbTableRejected.getDataSource();
    }

    if (this.rejectedSearchText) {
      this.rejectedDataSource = this.mdbTableRejected.searchLocalDataBy(this.rejectedSearchText);
      this.mdbTableRejected.setDataSource(prev);
    }
  }



  bindInProgressList() {
    this.companiesService.getInvitedVendorByStatus(this.CompanyId, 'accepted').subscribe((c: ApiResponse) => {
      if (c.code == 200) {
        c.data[0].forEach((d, i) => {
          this.inProgressDataSource.push(d);
        })
        this.mdbTableInProgress.setDataSource(this.inProgressDataSource);
        this.inProgressDataSource = this.mdbTableInProgress.getDataSource();
        this.inProgressPrevious = this.mdbTableInProgress.getDataSource();
      } else {
        this.inProgressServerError = c.message;
      }
    }, err => {
      this.inProgressServerError = err;
    })
  }
  inProgressSearchItems() {
    const prev = this.mdbTableInProgress.getDataSource();

    if (!this.inProgressSearchText) {
      this.mdbTableInProgress.setDataSource(this.inProgressPrevious);
      this.inProgressDataSource = this.mdbTableInProgress.getDataSource();
    }

    if (this.inProgressSearchText) {
      this.inProgressDataSource = this.mdbTableInProgress.searchLocalDataBy(this.inProgressSearchText);
      this.mdbTableInProgress.setDataSource(prev);
    }
  }
}
