import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, CommonService } from 'src/app/_services';

@Component({
  selector: 'app-impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService) { }

  ngOnInit() {
    // reset login status
    this.authenticationService.logout();

    //fetch token from query string
    if (this.route.snapshot.queryParams['token']) {
      var token = this.commonService.GetQueryStringValue('token');
      var id = this.commonService.GetQueryStringValue('id');
      this.authenticationService.impersonatelogin('impersonate', token,id).then((res: any) => {
        if (res.code == 200) {
          this.router.navigate(['/home']);
        }else{
          this.router.navigate(['/login']);
        }
       });
    }
  }
}
