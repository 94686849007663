import { Directive, ElementRef, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appElementRefDirective]'
})
export class ElementRefDirectiveDirective {

  constructor(private el: ElementRef) {}

  @Output() instance = new EventEmitter<ElementRef>();

  /**emit a reference to the host element*/
  ngOnInit(){this.instance.emit(this.el);}
}
