
import { Component } from '@angular/core';
import { MDBModalRef } from 'ng-uikit-pro-standard';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  action: Subject<any> = new Subject();
  heading: string;
  description: string;
  constructor(public modalRef: MDBModalRef) {
    this.heading = this.heading || "Delete";
    this.description = this.description || "Are you sure?";
  }

  onYesClick() {
    this.modalRef.hide();
    this.action.next(true);
  }

  onNoClick() {
    this.modalRef.hide();
    this.action.next(false);
  }

} 
