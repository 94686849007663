import { Component, OnInit } from '@angular/core';
import { TaskService } from '../_services/task.service';
import { ApiResponse } from '../_models/response';

@Component({
  selector: 'app-my-tasks',
  templateUrl: './my-tasks.component.html',
  styleUrls: ['./my-tasks.component.scss']
})
export class MyTasksComponent implements OnInit {

  writing = [];
  scroing = [];
  approving = [];
  pending = [];
  completed = []
  writingError = '';
  scroingError = '';
  approvingErrp = '';
  constructor(private taskService: TaskService) { }

  ngOnInit() {
    this.taskService.getAllTasks('writing').subscribe((x: ApiResponse) => {
      if (x.code == 200) {
        x.data[0].forEach(element => {
          if(element.status=='done'){
            this.completed.push(element);
          }else{
            this.pending.push(element);
          }
        });
      } else {
        this.writingError = x.message;
      }
    }, err => {
      this.writingError = err;
    });
    // this.taskService.getAllTasks('approving').subscribe((x: ApiResponse) => {
    //   if (x.code == 200) {
    //     this.approving = x.data[0];
    //   } else {
    //     this.approvingErrp = x.message;
    //   }
    // }, err => {
    //   this.approvingErrp = err;
    // });
  }

}
