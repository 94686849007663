import { Pipe, PipeTransform } from '@angular/core';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(str: any) {
    if (!str) return;
    return formatter.format(str);
  }

}
