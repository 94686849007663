import { Component, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { UserService, AuthenticationService } from '../_services';
import { ApiResponse } from '../_models/response';
import { DatePicker } from '../const';
import {Router} from '@angular/router';
import { MdbTableDirective, MDBModalService,MdbTablePaginationComponent } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../_shared/confirm/confirm.component';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild(MdbTablePaginationComponent, { static: true }) mdbTablePagination: MdbTablePaginationComponent;

  dataSource: any = [];
  serverError = "";
  searchText: string = '';
  previous: string;
  DatePicker = DatePicker;
  deleteErrorMsg = "";

  constructor(
    private userservice: UserService,
    private modalService: MDBModalService,
    private auth: AuthenticationService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) { }
  ngAfterViewInit() {

    this.mdbTablePagination.setMaxVisibleItemsNumberTo(10);

    this.mdbTablePagination.calculateFirstItemIndex();
    this.mdbTablePagination.calculateLastItemIndex();
    this.cdRef.detectChanges();
  }
  ngOnInit() {
    this.userservice.getAllUsers().subscribe((u: ApiResponse) => {
      if (u.code == 200) {
        u.data[0].forEach((d, i) => {
          if (d.id != this.auth.currentUserValue.userid) {
            this.dataSource.push(d);
          }
        })
        this.mdbTable.setDataSource(this.dataSource);
        this.dataSource = this.mdbTable.getDataSource();
        this.previous = this.mdbTable.getDataSource();
      } else {
        this.serverError = u.message;
      }
    }, err => {
      this.serverError = err;
    })
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();

    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.dataSource = this.mdbTable.getDataSource();
    }

    if (this.searchText) {
      this.dataSource = this.mdbTable.searchLocalDataBy(this.searchText);
      this.mdbTable.setDataSource(prev);
    }
  }
  deleteuser(du, i) {
    this.deleteErrorMsg = "";
    this.modalService.show(ConfirmComponent).content.action.subscribe((result: boolean) => {
      if (result) {
        //Yes
        this.userservice.deleteuser(du.id).subscribe((b: ApiResponse) => {
          if (b.code == 200) {
            let status = -1;
            status = b.data.is_deleted;
            if(status==1){
              this.dataSource.splice(i, 1);
              this.mdbTable.setDataSource(this.mdbTable.getDataSource());

              Swal.fire('', 'User Deleted Successfully.', 'success');
            }else{
              this.router.navigate(['/reassigntasks', du.id]);
              // this.router.navigate(['/reassigntasks']);
            }
          } else {
            this.deleteErrorMsg = b.message;
          }
        }, err => {
          this.deleteErrorMsg = err;
           var t = setTimeout(function (){
              this.deleteErrorMsg = undefined;

              document.getElementById("deleteErrorMsg").style.display = "none";
            }, 4000);
        })


      }
    });

  }

}





