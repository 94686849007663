import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService, CommonService } from '../../_services';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { Auth0Service } from 'src/app/_services/Auth0Service';
import { RecaptchaComponent } from 'ng-recaptcha';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild(RecaptchaComponent,{static: true}) captchaComponent: RecaptchaComponent;
  isCaptchaResolved: any = false;
  captchaHasError: any = false;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  viewPasswordIcon: any = 'eye-slash';
  passwordType: any = 'password';
  error = '';
  isForgotPasswordSent = false;
  characterLimit: number = 30;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private commonService: CommonService,
    private authService: Auth0Service
  ) { }
  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      rememberMe: [false]
    });

    if (localStorage.getItem("issuerRememberMe") == 'true') {
      this.commonService.SetFormValue(this.loginForm, "email", localStorage.getItem("issuerUserName"));
      this.commonService.SetFormValue(this.loginForm, "password", localStorage.getItem("issuerPassword"));
      this.commonService.SetFormValue(this.loginForm, "rememberMe", true);
    }

    if (this.commonService.forgotPassSent) {
      this.isForgotPasswordSent = true;
      this.commonService.forgotPassSent = false;
    }
    let currentUser = localStorage.getItem('currentUser');
    if(currentUser !== undefined && currentUser !== null){
      this.router.navigate(['/']);
      return;
    }
    // reset login status
    this.authenticationService.logout();

    // this functionality has been moved to impersonate component
    // if (this.route.snapshot.queryParams['token']) {
    //   var token = this.commonService.GetQueryStringValue('token');
    //   var id = this.commonService.GetQueryStringValue('id');
    //   this.authenticationService.impersonatelogin('impersonate', token,id).then((res: any) => {
    //     if (res.code == 200) {

    //       this.router.navigate(['/home']);
    //     }
    //    });

    // }
    // get return url from route parameters or default to '/'
    this.returnUrl = '/';
    this.authService.handleAuthentication();
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    if(this.error != ''){
      return false;
    }
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      this.commonService.MarkAllAllCtrlDirty(this.loginForm);
      return;
    }
    this.captchaHasError = false;
    if (this.isCaptchaResolved == false || !this.isCaptchaResolved) {
      this.captchaHasError = true;
      return false;
    }
    localStorage.removeItem("issuerRememberMe");
    localStorage.removeItem("issuerUserName");
    localStorage.removeItem("issuerPassword");
    localStorage.removeItem("setupObject");
    this.loading = true;
    this.authenticationService.login(this.f.email.value, this.f.password.value).then((res: any) => {
      this.loading = false;
      if (res.code == 200) {
        if (this.f.rememberMe.value) {
          localStorage.setItem("issuerRememberMe", 'true');
          localStorage.setItem("issuerUserName", this.f.email.value);
          localStorage.setItem("issuerPassword", this.f.password.value);
        }
        //window.location.href = "/home";
        //Complete load require to manage the Local storage
        this.router.navigate(['/home']);
      }
    }, (err) => {
      this.loading = false;
      // console.log('No');

      if (err.status == 500) {
        localStorage.clear();
        this.router.navigate(['/login']);
      } else {
        this.error = err;
        this.resetCaptcha();
        setTimeout(()=> {
          this.error = '';
        },3000);
      }
      // this.errors = error.error.message;
    });
  }
  resetCaptcha(): void {
    if (this.captchaComponent) {
      this.captchaComponent.reset();
    }
  }
  closeAlert() {
    this.isForgotPasswordSent = false;
  }

  viewPassword() {
    this.viewPasswordIcon = (this.viewPasswordIcon != 'eye') ? 'eye' : 'eye-slash';
    this.passwordType = (this.viewPasswordIcon == 'eye') ? 'text' : 'password';
  }

  resolved(e) {
    this.isCaptchaResolved = e;
  }

  loginWithAuth() {
    this.authService.login();
  }

  onKeyUp(event: any) {
    const input = event.target as HTMLInputElement;
    if (input.value.length > this.characterLimit) {
      input.value = input.value.slice(0, this.characterLimit);
    }
  }

  emailValidate(event: any) {
    const input = event.target as HTMLInputElement;
    const email = input.value.trim();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const isValidEmail = emailRegex.test(email);

    if (!isValidEmail) {
      this.error = 'Please enter a valid email address';
    }else{
      this.error = '';
    }
    if (email.length > 60) {
      input.value = email.slice(0, 60);
    }
  }
}
