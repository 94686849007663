import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthenticationService, CommonService } from '../../_services';
import { ApiResponse } from '../../_models/response';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: '',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  MainForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  Rperror = '';
  rplinkSent = false;
  hasNumber:any = false;
  hasUpper:any = false;
  hasLower:any = false;
  hasNumeric:any = false;
  hasSymbol:any = false;
  hasMinChars:any = false;
  hasMaxChars:any = false;
  passwordType: any = 'password';
  confPasswordType: any = 'password';

  viewPasswordIcon:any = 'eye-slash';
  viewConfPasswordIcon:any = 'eye-slash';
  hasError: any = true;
  passwordText:any = '';
  rplinkSentMsg = '';
  showHideValidatorPopup: any = false;
  id = '';
  token = '';
  constructor(
    private formBuilder: FormBuilder,
    private auth: AuthenticationService,
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit() {
    this.id = this.commonService.GetQueryStringValue("id")
    this.token = this.commonService.GetQueryStringValue('token');
    this.MainForm = this.formBuilder.group({
      newPasswrd: ['', [Validators.required]],
      confrmPasswrd: ['', [Validators.required]]
    });
    this.auth.getresetPassword(this.id, this.token).subscribe((res: ApiResponse)=> {

      if(res.code == 200 )
      {
      }else {
        if(res.message == 'Expired'){
          this.Rperror = 'Reset Link Has been Expired!';
          this.rplinkSent = false;
          // this.rplinkSentMsg = 'Reset Link Has been Expired! ';
        }else{
           this.Rperror = 'Reset Link Has been Expired!';
         
        }
          this.loading = false;
      }
    }, err => {
      if(err == 'Expired'){
        this.Rperror = 'Reset Link Has been Expired!';
        this.loading = false;
        this.rplinkSent = true;
        // this.rplinkSentMsg = 'Reset Link Has been Expired! ';
      }
    });
  }

  onSubmit() {
    this.Rperror = "";
    if (this.MainForm.valid) {
      this.loading = true;
      let nwPsswrd = this.commonService.GetFormValue(this.MainForm, 'newPasswrd');
      let cnfrmPsswrd = this.commonService.GetFormValue(this.MainForm, 'confrmPasswrd');
      this.auth.resetPassword(nwPsswrd, cnfrmPsswrd, this.id, this.token).subscribe((x: ApiResponse) => {

        if (x.code == 200) {

          this.rplinkSent = true;
          this.rplinkSentMsg = x.message;
          let email = x.data.email;
          // login code
            localStorage.removeItem("issuerRememberMe");
            localStorage.removeItem("issuerUserName");
            localStorage.removeItem("issuerPassword");
            localStorage.removeItem("setupObject");

            this.loading = true;
            if(email){

              this.auth.login(email, nwPsswrd).then((res: any) => {
                this.loading = false;
                if (res.code == 200) {
                 
                    localStorage.setItem("issuerRememberMe", 'true');
                    localStorage.setItem("issuerUserName",email);
                    localStorage.setItem("issuerPassword", nwPsswrd);
        
                  //Complete load require to manage the Local storage
                  this.router.navigate(['/home']);
                }
              }, (err) => {
                this.loading = false;
                // console.log('No');

                if (err.status == 500) {
                  localStorage.clear();
                  this.router.navigate(['/login']);
                } else {
                  this.Rperror = err;
                }
                // this.errors = error.error.message;
              });
            }

          // end login code
        } else {
          this.Rperror = x.message;
        }
        this.loading = false;
      }, err => {
        this.Rperror = err;
        this.loading = false;
      })
    }
    else {
      this.commonService.MarkAllAllCtrlDirty(this.MainForm);
    }
  }

  validatePassword(e){
    let value = e.target.value;
    this.passwordText = value;
    console.log(value.length);
    (value.toString().length > 0 && value.toString().length <= 20)? this.hasMaxChars = true : this.hasMaxChars = false;
    (value.length >= 8)? this.hasMinChars = true : this.hasMinChars = false;
    this.hasNumeric = /\d/.test(value);
    this.hasUpper = /[A-Z]/.test(value);
    this.hasLower = /[a-z]/.test(value);
    let format = /[!@#^&*()+_,.{}?-]/;
    this.hasSymbol = format.test(value);
    if(!this.hasMaxChars || !this.hasNumeric || !this.hasUpper || !this.hasLower || !this.hasSymbol || !this.hasMinChars){
      this.hasError = true;
    }else{
      this.hasError = false;
    }
  }

  isConfirmedPassword(e){
    if(this.passwordText != e.target.value){
      this.hasError = true;
    }else{
      this.hasError = false;
    }
  }

  showHideValidator(showHide,e){
    if(showHide == 'show'){
      this.showHideValidatorPopup = true;
    }else{
      this.showHideValidatorPopup = false;
    }
    this.validatePassword(e);
  }

  viewPassword(){
    this.viewPasswordIcon = (this.viewPasswordIcon != 'eye' )?'eye': 'eye-slash';
    this.passwordType =  (this.viewPasswordIcon == 'eye' )?'text': 'password';
  }

  confViewPassword(){
    this.viewConfPasswordIcon = (this.viewConfPasswordIcon != 'eye' )?'eye': 'eye-slash';
    this.confPasswordType =  (this.viewConfPasswordIcon == 'eye' )?'text': 'password';
  }

}
