import { Injectable } from '@angular/core';
import auth0 from 'auth0-js';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service {
  private auth0 = new auth0.WebAuth({
    domain: 'dev-5pnikuu41bak4kmc.us.auth0.com',
    clientID: 'CBTmAYN6k7XsCGCPIXVzldh4W5i9IkLc',
    redirectUri: 'http://localhost:4200/login', // Your redirect URI
    responseType: 'token id_token',
    scope: 'openid profile email'
  });

  constructor() { }

  login(): void {
    // this.auth0.signup({
    //   connection: 'Username-Password-Authentication',
    //   email: 'testing12@gmail.com',
    //   password: 'Krish@123'
    // }, (err, result) => {
    //   if (err) {
    //     console.error('Error signing up:', err);
    //     // Handle error, e.g., display an error message to the user
    //   } else {
    //     console.log('User signed up successfully:', result);
    //     // Optionally, you can redirect the user to a login page or perform any other action
    //   }
    // });
    this.auth0.authorize();
  }

  handleAuthentication(): void {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        localStorage.setItem('access_token', authResult.accessToken);
        localStorage.setItem('id_token', authResult.idToken);
        localStorage.setItem('expires_at', JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime()));
        window.location.hash = '';
      } else if (err) {
        console.error('Authentication error:', err);
      }
    });
  }

  logout(): void {
    localStorage.removeItem('access_token');
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    window.location.href = 'https://YOUR_AUTH0_DOMAIN/v2/logout?returnTo=http://localhost:4200';
  }

  isAuthenticated(): boolean {
    const expiresAt = JSON.parse(localStorage.getItem('expires_at'));
    return new Date().getTime() < expiresAt;
  }
}